import styled from "styled-components";
import { motion } from "framer-motion";

// COLORS

export const yellow_1 = "#E7DB03";
const yellow_2 = "#E8C707";
const yellow_3 = "#D8B50B";

const blue_1_darker = "#3B96E5";
export const blue_1_lighter = "#5AB1F9";
export const blue_1 = "#489CEF";
export const blue_2 = "#1D87CE";
export const blue_3 = "#1277AF";

export const green_1 = "#39EA5F";
const green_2 = "#40DB5D";
const green_3 = "#38C64C";

//const light_grey_0 = '#FBFBFB';
export const light_grey_1 = "#F1F1F1";
export const light_grey_2 = "#D9D9D9";
export const light_grey_3 = "#ADADAD";

// LINES

export const ThinYellowLine = styled.hr`
  position: relative;
  top: -0.5em;
  color: rgba(255, 255, 255, 0.7);
  width: 100%;
  border-style: solid none none none;
  border-radius: 1rem;
`;

export const PracticeDetailLine = styled.hr`
  position: relative;
  top: -0.5em;
  color: ${blue_3};
  width: 100%;
  border-style: solid none none none;
  border-width: 0.15rem;
`;

// CONTAINERS, DIVS

export const SiteContainer = styled.div`
  position: relative;
  top: ${(props) => props.top || "0"};
  display: flex;
  justify-content: center;
  align-items: ${(props) => props.alignItems || "center"};
  align-content: ${(props) => props.alignContent || "center"};
  background: ${(props) => props.background || "none"};
  width: 100vw;
  height: 100vh;
  flex-wrap: wrap;
`;

export const PractiseSiteContainer = styled(SiteContainer)`
  align-content: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const SignInContainer = styled(motion.div)`
  position: absolute;
  background: none;
  width: 90vw;
  max-width: 30rem;
  height: auto;
  margin: 0;
`;

export const SignInSubContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: ${(props) => props.height || "3rem"};
  background: none;
`;

export const SignInButtonsContainer = styled.div`
  background: blue;
  width: 30rem;
  height: 20rem;
  margin: 2rem 0 0 0;
`;

export const PractiseContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  flex-wrap: wrap;
  width: 90vw;
  max-width: 40rem;
  height: auto;
  margin: 2rem 0 0 0;
  transform: translate(-50%, 0%);
  overflow-x: hidden;
`;

export const EvaluationContainer = styled(motion.div)`
  width: 90vw;
  max-width: 30rem;
  background: none;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 0 10px 0 10px;
`;

export const EvaluationCenterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content center;
  flex-wrap: wrap;
`;

export const PractiseTopContainer = styled(motion.div)`
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  height: ${(props) => props.height || "auto"};
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  margin: ${(props) => props.margin || "0"};
  padding: 0;
`;

export const PractiseGutterContainer = styled.div`
  display: flex;
  width: ${(props) => props.width || "3rem"};
  justify-content: center;
  align-items: ${(props) => props.alignItems || "center"};
  align-content: ${(props) => props.alignContent || "center"};
`;

export const PractiseReviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  justify-content: flex-start;
  align-content: flex-start;
`;

export const PractiseItemReviewContainer = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-wrap: none;
  margin-bottom: 1rem;
`;

export const PractiseItemReviewTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
  font-family: Helvetica;
  font-size: 1.1rem;
  color: ${(props) => props.color || light_grey_3};
  width: 88%;
  margin-left: 1.5rem;
`;

export const PractiseMainContainer = styled.div`
  display: flex;
  flex-wrap: none;
  flex-grow: 1;
  padding: 0 1rem 0 1rem;
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: ${(props) => props.alignItems || "center"};
  align-content: ${(props) => props.alignContent || "center"};
`;

export const DisplayEvaluationContainer = styled(motion.div)`
  position: absolute;
  margin: 0 auto 0 auto;
  top: 8.5rem;
  z-index: 1;
`;

export const fillContainer = styled.div`
  position: absolute;
  display: block;
  width: 100vw;
  height: 100vh;
`;

export const DashboardContainer = styled(SiteContainer)`
  width: auto;
  padding: 0 6rem 0 6rem;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: ${blue_2} ${blue_1};
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.075);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.15);
  }
`;

export const SigninContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  width: 30rem;
  padding: 1rem;
`;

export const LangSelectContainer = styled(SigninContainer)`
  width: 30rem;
`;

export const TopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 100vw;
  padding: 1.5rem 0 1rem 0;
  background: rgba(255, 255, 255, 0);
  margin: 0;
`;

export const TopBarDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const EmptyContentContainer = styled.div`
  width: 100vw;
  height: auto;
  padding: 6rem 1rem 0 1rem;
  display: flex;
  justify-content: center;
`;

export const ContentContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, [col-start] minmax(12rem, 1fr) [col-end]);
	column-gap: 2rem;
	row-gap: 2rem;
	justify-content: space-between;
	width: 100vw;
  margin: 0;
	background: rgba(255, 255, 255, 0);
	height: auto;
	padding: 0;
	list-style-type:none;
	}
`;

export const PackFrameContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${(props) => props.background || "white"};
  padding: 0;
  margin: 0;
`;

export const PackItemsReadyDisplay = styled(motion.div)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -1.2rem;
  left: -1.2rem;
  padding: 0;
  margin: 0;
  width: 2.5rem;
  height: 2.5rem;
  background: ${yellow_1};
  font-family: Helvetica;
  font-size: 1rem;
  color: black;
  border-radius: 1.5rem;
  z-index: 1;
`;

export const PackItemsReadyMotion = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 2.5rem;
  height: 2.5rem;
  background: ${(props) => props.background || yellow_1};
  font-family: Helvetica;
  font-size: 1rem;
  color: ${(props) => props.color || "black"};
  border-radius: 1.5rem;
`;

export const PackItemsReadyContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: left;
  top: -1.2rem;
  left: -1.2rem;
  padding: 0;
  margin: 0;
  width: 6rem;
  height: 2.5rem;
  background: none;
  z-index: 1;
`;

export const PackItemsReadyDetailDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  width: ${(props) => props.width || "2.5rem"};
  height: ${(props) => props.height || "2.5rem"};
  background: ${(props) => props.background || yellow_1};
  font-family: Helvetica;
  font-size: ${(props) => props.fontSize || "1rem"};
  color: ${(props) => props.color || "black"};
  border-radius: 5rem;
`;

export const LevelDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 0.5rem 0 0;
  width: ${(props) => props.width || "2.5rem"};
  height: ${(props) => props.height || "2.5rem"};
  background: ${(props) => props.background || blue_1};
  font-family: Helvetica;
  font-size: ${(props) => props.fontSize || ".8rem"};
  font-weight: bold;
  color: ${(props) => props.color || "white"};
  border-radius: 5rem;
  border-width: ${(props) => props.borderWidth || "0"};
  border-color: ${(props) => props.borderColor || yellow_1};
  border-style: solid;
`;

export const PackItemsReadyManagerDisplay = styled(PackItemsReadyDetailDisplay)`
  width: 2rem;
  height: 2rem;
  font-size: 0.8rem;
`;

export const CenterContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 100vw;
  height: ${(props) => props.height || "100%"};
`;

export const PhotoContainer = styled.div`
  margin: 1.2rem;
`;

export const CompletionBarContainer = styled.div`
  position: relative;
  height: ${(props) => props.height || "auto"};
  width: ${(props) => props.width || "auto"};
  margin: ${(props) => props.margin || 0};
  top: ${(props) => props.top || 0};
`;

export const PackManagerContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 77%;
  left: 24px;
  border-radius: 1rem;
  background: ${(props) => props.background || blue_1};
  margin-bottom: 0.5rem;
  padding: 0.7rem 1rem 0.7rem 1rem;
`;

export const PackManagerContentContainer = styled.div`
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  flex-grow: 1;
  height: auto;
  width: 12rem;
`;

export const PackManagerButtonContainer = styled.div`
  display: flex;
`;

export const EmptyContainer = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const StatsContainerMain = styled.div`
  position: relative;
  width: auto;
  height: auto;
  padding: 120% 0 0 0;
  border: none;
  list-style: none;
`;

export const StatsContainer = styled.div`
  position: absolute;
  display: flex;
  padding: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const StatsContainerText = styled.div`
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
`;

export const StatsContainerImage = styled(StatsContainerText)`
  height: 100%;
  flex-flow: wrap;
  align-items: flex-end;
`;

export const CustomContainer = styled.div`
  width: ${(props) => props.width || "1rem"};
  height: ${(props) => props.height || "1rem"};
  padding: 0;
  margin: 0;
`;

export const ThumbnailPlaceholder = styled(CustomContainer)`
  background: ${blue_1};
`;

export const WidthContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  padding: 0;
  margin: 0;
  width: ${(props) => props.width || "1rem"};
`;

export const GradientContainer = styled.div`
  position: relative;
  top: -7rem;
  background: linear-gradient(
    to top,
    rgba(29, 135, 206, 1),
    rgba(29, 135, 206, 0)
  );
  width: 100%;
  height: 7rem;
  padding: 0;
  margin: 0;
`;

export const PackDetailsContentContainer = styled.div`
  position: relative;
  top: -6rem;
  display: flex;
  flex-wrap: wrap;
  background: none;
  width: 100%;
  margin: 0rem 2rem 1rem 2rem;
`;

export const PackDetailsItemsReadyContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.color || yellow_1};
  font-family: Helvetica;
  font-size: ${(props) => props.fontSize || ".9rem"};
`;

export const PackDetailsStatsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const PackDetailsStatsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: ${(props) => props.justifyContent || "left"};
`;

export const PackDetailsStatsSplitContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 49%;
`;

export const PackDetailsStatsLevelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${(props) => props.background || blue_3};
  border-radius: 1.4rem;
  border: solid 0.2rem ${(props) => props.borderColor || yellow_1};
  padding: 1rem;
  width: 100%;
`;

export const PackManagerItemsReadyContainer = styled.div`
  position: absolute;
  top: -0.7rem;
  left: -0.7rem;
  display: flex;
  align-items: center;
  color: ${(props) => props.color || yellow_1};
  font-family: Helvetica;
  font-size: 0.9rem;
  z-index: 100;
`;

// FORM ELEMENTS

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100vw;
`;

export const Input = styled.input`
  background: white;
  border: 0;
  border-radius: 2rem;
  font-family: Helvetica;
  font-size: 1.5rem;
  font-weight: 100;

  auto-correct: off;
  spell-check: false;
  auto-capitalize = 'none';

  width: ${(props) => props.width || "100%"};
  text-align: center;
  height: 2rem;
  padding: 0.6rem 1.2rem 0.6rem 1.2rem;
  margin-bottom: ${(props) => props.marginBottom || ".5rem"};
  &:focus {
    outline: none;
  }
`;

export const Button = styled(motion.button)`
  background: yellow;
  border: 0;
  border-radius: 0.5rem;
  flex-basis: 100vw;
  height: 3rem;
  padding: 0.4rem;
  margin-bottom: ${(props) => props.marginBottom || "1rem"};
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const ButtonAuth = styled(Button)`
  border-radius: 2rem;
  width: auto;
  height: auto;
  flex-basis: auto;
  font-family: Helvetica;
  font-size: 1.1rem;
  padding: 0.6rem 1.5rem 0.6rem 1.5rem;
`;

export const ButtonAuthSignUp = styled(ButtonAuth)`
  background: ${green_1};
  color: white;
  &:hover {
    background: ${green_2};
  }
  &:active {
    background: ${green_1};
  }
`;

export const ButtonAuthLanguage = styled(ButtonAuth)`
  background: ${blue_2};
  color: white;
  font-size: 0.9rem;
  padding: 0.4rem 1rem 0.4rem 1rem;
  &:hover {
    background: ${blue_3};
  }
  &:active {
    background: ${green_1};
  }
`;

export const ButtonAuthInactive = styled.div`
  background: ${blue_2};
  color: rgba(255, 255, 255, 0.5);
  border-radius: 2rem;
  width: auto;
  height: auto;
  flex-basis: auto;
  font-family: Helvetica;
  font-size: 0.9rem;
  padding: 0.4rem 1rem 0.4rem 1rem;
  margin-bottom: ${(props) => props.marginBottom || "1rem"};
`;

export const ButtonAuthLanguageSelected = styled(ButtonAuthLanguage)`
  background: ${green_1};
  &:hover {
    background: ${green_2};
  }
  &:active {
    background: ${green_1};
  }
`;

export const ButtonAuthSignIn = styled(ButtonAuth)`
  background: ${yellow_1};
  color: black;
  &:hover {
    background: ${yellow_2};
  }
  &:active {
    background: ${yellow_1};
  }
`;

export const ButtonAuthBack = styled(ButtonAuth)`
  background: ${blue_1_lighter};
  color: white;
  padding: 0.6rem 0.7rem 0.6rem 0.7rem;
  &:hover {
    background: ${blue_2};
  }
  &:active {
    background: ${blue_1_lighter};
  }
`;

export const ButtonYellow = styled(Button)`
  background: ${yellow_1};
  &:hover {
    background: ${yellow_2};
  }
  &:active {
    background: ${yellow_3};
  }
`;

export const ButtonGoogle = styled(Button)`
  background: #4385f4;
  color: white;
  &:hover {
    background: #397be4;
  }
  &:active {
    background: #296bcc;
  }
`;

export const ButtonText = styled(Button)`
  background: ${blue_1};
  flex-basis: auto;
  height: auto;
  color: rgba(255, 255, 255, 1);
  padding: 0.6rem 1.2rem 0.6rem 1.2rem;
  border-radius: 1rem;
  &:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: underline;
  }
  &:active {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
  }
`;

// IMAGE ELEMENTS

export const Img = styled(motion.img)`
  position: relative;
  top: ${(props) => props.top || 0};
  left: ${(props) => props.left || 0};
  width: ${(props) => props.width || "3rem"};
  min-width: ${(props) => props.minWidth || "0"};
  max-width: ${(props) => props.maxWidth || "auto"};
  transform: ${(props) => props.transform || "none"};
  height: auto;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

export const ImgThumb = styled(Img)`
  position: relative;
  margin: 1.2rem;
  top: 0;
  left: 0;
`;

export const ImgThumbCover = styled(Img)`
  position: absolute;
  margin: 1.2rem;
  top: 0;
  left: 0;
  z-index: 0;
`;

export const ImgHeight = styled.img`
  position: relative;
  top: ${(props) => props.top || 0};
  left: ${(props) => props.left || 0};
  width: auto;
  min-width: ${(props) => props.minWidth || "0"};
  height: ${(props) => props.height || "3rem"};
  margin: 0;
  padding: 0;
`;

// TEXT

export const LangSelectHeader = styled.h3`
  display: flex;
  font-family: Helvetica;
  font-size: 1.6rem;
  color: white;
  font-weight: 100;
  flex-basis: 100vw;
  margin: 0;
  text-align: center;
`;

export const LangPacksHeader = styled(LangSelectHeader)`
  color: ${blue_1};
`;

export const LangPacksTitle = styled.p`
  font-family: Helvetica;
  width: auto;
  color: ${(props) => props.color || blue_1};
  font-size: 1rem;
  margin: 0;
`;

export const AuthText = styled.p`
  font-family: Helvetica;
  font-size: 1.2rem;
  font-weight: 100;
  color: white;
  height: auto;
  margin: 0;
`;

export const AuthTextError = styled.div`
  font-family: Helvetica;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${yellow_1};
  margin: 0;
  text-align: center;
`;

export const DashboardEmptyText = styled.p`
  font-family: Helvetica;
  color: ${blue_3};
  font-size: 1.4rem;
  padding: 0 0 0 0;
  line-height: 1.8rem;
  text-align: center;
`;

export const PackDetailTitle = styled.h3`
  display: flex;
  font-family: Helvetica;
  font-size: 1.8rem;
  color: white;
  font-weight: 100;
  margin: 0;
  flex-basis: 100vw;
`;

export const PackDetailBody = styled.p`
  font-family: Helvetica;
  color: white;
  font-size: 0.9rem;
  font-weight: 100;
  margin: 0;
`;

export const PackDetailPercentage = styled.p`
  font-family: Helvetica;
  font-size: 1.3rem;
  color: white;
  margin: 0.2rem;
`;

export const PackDetailStatsText = styled.p`
  font-family: Helvetica;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.5);
  margin: 0.1rem;
  width: 100%;
  text-align: center;
`;

export const PackDetailStatsTextCenter = styled(PackDetailStatsText)`
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  line-height: 1.5rem;
`;

export const PackDetailsStatsTextBold = styled.span`
  color: white;
`;

export const EvalStatsTextBold = styled.span`
  font-size: 1.3rem;
  color: white;
`;

export const PackDetailsStatsLevelText = styled.p`
  font-family: Helvetica;
  font-size: 0.9rem;
  font-weight: bold;
  color: ${(props) => props.color || yellow_1};
  margin: 0.1rem;
`;

export const EvalStatsLevelText = styled.p`
  font-family: Helvetica;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.color || yellow_1};
  margin: 0.1rem;
`;

export const LangPacksBody = styled.p`
  font-family: Helvetica;
  color: ${light_grey_3};
  font-size: 1.1rem;
  text-align: center;
  width: 80%;
  margin: 0;
  padding: 1rem 1rem 1rem 1rem;
`;

export const LangPacksBodyTitle = styled.p`
  font-family: Helvetica;
  color: ${blue_1};
  font-size: 1.3rem;
  text-align: center;
  width: 80%;
  margin: 0;
  padding: 1rem 1rem 1rem 1rem;
`;

export const ChangeLangBody = styled.p`
  font-family: Helvetica;
  color: ${light_grey_3};
  font-size: 1.1rem;
  text-align: center;
  margin: 0;
  padding: 0.5rem 1rem 0.5rem 1rem;
`;

export const ErrorSpan = styled.span`
  position: relative;
  width: 100%;
  top: ${(props) => props.topMove || "-.3rem"};
  font-family: Helvetica;
  text-align: center;
  font-size: 0.8rem;
  color: ${yellow_1};
  margin-left: 1rem;
`;

export const ExitSessionText = styled.p`
  font-family: Helvetica;
  width: 100%;
  padding: 4px;
  color: ${(props) => props.color || light_grey_3};
  text-align: center;
  font-size: 1.4rem;
  margin: 0;
`;

export const SettingsText = styled.p`
  font-family: Helvetica;
  color: ${light_grey_3};
  font-size: 0.9rem;
  margin: 0;
  padding: 0;
`;

export const SettingsTextBody = styled(SettingsText)`
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.4rem;
  padding: 0 1.5rem 0 1.5rem;
`;

export const EvaluationTextSmall = styled.span`
  font-family: Helvetica;
  color: ${(props) => props.color || light_grey_3};
  font-size: 0.8rem;
`;

export const EvaluationTextStats = styled.span`
  font-family: Helvetica;
  color: ${(props) => props.color || light_grey_3};
  font-size: 1.5rem;
`;

export const EvaluationText = styled.span`
  font-family: Helvetica;
  color: ${(props) => props.color || blue_1};
  font-size: 1.5rem;
  text-align: center;
  line-height: 2rem;
`;

export const BlueHighlight = styled.span`
  color: ${(props) => props.color || blue_1};
`;

export const EvaluationLine = styled.div`
  width: 100%;
  display: block;
  height: 2.5px;
  border: 0;
  border-top: 3px solid ${(props) => props.color || light_grey_2};
  margin: 0.8rem 0;
  padding: 0;
`;

export const StatsText = styled.p`
  position: relative;
  top: 1%;
  font-family: Helvetica;
  color: white;
  font-size: 0.8rem;
  text-align: center;
`;

export const StatsNumber = styled.span`
  font-size: 1.9rem;
`;

// BUTTONS

export const ButtonBase = styled(motion.button)`
  border: 0;
  background: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

export const ButtonEmpty = styled.button`
  border: 0;
  background: none;
  cursor: pointer;
  height: ${(props) => props.height || "auto"};
  width: ${(props) => props.width || "auto"};
  &:focus {
    outline: none;
  }
`;

export const ButtonEmptyPractice = styled(ButtonEmpty)`
  opacity: 50;
  &:hover {
    opacity: 75;
  }
  &:active {
    opacity: 100;
  }
`;

export const SettingsButton = styled(ButtonBase)`
  height: 3.8rem;
  width: 3.8rem;
  border-radius: 4rem;
  &:hover {
    background: ${blue_1_darker};
  }
  &:active {
    background: ${blue_2};
  }
`;

export const ManagePacksBackButton = styled(ButtonBase)`
  position: relative;
  left: -0.6rem;
  top: -0.2rem;
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 2.4rem;
  &:hover {
    left: 0;
  }
  &:active {
  }
`;

export const PacksDetailBackButton = styled(ButtonBase)`
  position: absolute;
  top: 1rem;
  left: 0.7rem;
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 2.4rem;
  &:hover {
    left: 0.2rem;
  }
  &:active {
  }
`;

export const LangSelectButton = styled(ButtonBase)`
  border-radius: 4rem;
  padding: 0.5rem 1.4rem 0.5rem 1.4rem;
  background: ${(props) => props.selectColor || blue_2};
  color: white;
  font-size: 1.2rem;
  font-weight: 100;
  margin-top: 0.5rem;
  &:hover {
    background: ${(props) => props.selectColor || blue_3};
  }
  &:active {
    background: ${(props) => props.selectColor || green_1};
  }
`;

export const LangSelectButtonSubmit = styled(LangSelectButton)`
  color: black;
  background: ${yellow_1};
  &:hover {
    background: ${yellow_2};
  }
  &:active {
    background: ${yellow_3};
  }
`;

export const LangPacksRemove = styled(ButtonBase)`
  position: relative;
  left: 0.4rem;
  background: none;
  padding: 0 0.5rem 0 0.5rem;
  margin: 0;
  border-radius: 4rem;
  &:hover {
    background: ${blue_2};
  }
  &:active {
    background: ${blue_3};
  }
`;

export const LangPacksAdd = styled(LangPacksRemove)`
  &:hover {
    background: ${light_grey_1};
  }
  &:active {
    background: ${light_grey_2};
  }
`;

export const PackDetailPractiseButton = styled(ButtonBase)`
  background: ${yellow_1};
  border-radius: 2rem;
  color: black;
  padding: 0.7rem;
  width: 100%;
  font-family: Helvetica;
  font-size: 1.3rem;
  &:hover {
    background: ${yellow_2};
  }
  &:active {
    background: ${yellow_3};
  }
`;

export const PackDetailPractiseListeningButton = styled(
  PackDetailPractiseButton
)`
  background: ${blue_1};
  color: white;
  &:hover {
    background: ${blue_1_darker};
  }
  &:active {
    background: ${blue_3};
  }
`;

export const SpeechButtonMain = styled(motion.div)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: ${blue_1};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "0"};
  padding-bottom: 100%;
  white-space: nowrap;
  overflow: hidden;
  transform: none;
  cursor: pointer;
`;

export const SpeechButtonPlayHead = styled(motion.div)`
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: ${(props) => props.background || blue_2};
`;

export const SpeechButtonPlayHeadMask = styled.div`
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: ${(props) => props.zIndex || "1"};
`;

export const SpeechButtonSecondaryPlayHead = styled(SpeechButtonPlayHead)`
  background: ${(props) => props.background || light_grey_2};
`;

export const SpeechButtonIcon = styled(motion.img)`
  position: relative;
  top: ${(props) => props.top || "100%"};
  left: ${(props) => props.left || 0};
  width: ${(props) => props.width || "4rem"};
  max-width: ${(props) => props.maxWidth || "8rem"};
  height: auto;
  margin: 0;
  z-index: 1;
`;

export const SpeechButtonSecondary = styled(SpeechButtonMain)`
	background: white;
	position: relative;
	margin-top: 120%;
	width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "0"};
  padding-bottom: 100%;
  z-index: 100;
	}
`;

export const LearningButton = styled(ButtonBase)`
  position: relative;
  border-radius: 4rem;
  background: ${(props) => props.background || green_1};
  width: ${(props) => props.width || "1rem"};
  height: ${(props) => props.height || "1rem"};
  margin: 1rem;
`;

export const ConsolidationButton = styled(LearningButton)`
  background: ${(props) => props.background || yellow_1};
`;

export const FinishSessionButton = styled(ButtonBase)`
  background: ${blue_1};
  color: white;
  font-family: Helvetica;
  font-size: 1.5rem;
  width: 100%;
  height: 3.6rem;
  border-radius: 1.8rem;
  &:hover {
    background: ${(props) => props.bgHover || blue_2};
  }
  &:active {
    background: ${blue_3};
  }
`;

export const PractiseAgainButton = styled(FinishSessionButton)`
  background: ${yellow_1};
  color: black;
  &:hover {
    background: ${(props) => props.bgHover || yellow_2};
  }
  &:active {
    background: ${yellow_3};
  }
`;

export const ExitSessionButton = styled(ButtonBase)`
  background: ${blue_1};
  color: white;
  font-family: Helvetica;
  font-size: 1.4rem;
  width: 100%;
  height: 3.2rem;
  border-radius: 1.6rem;
  &:hover {
    background: ${blue_2};
  }
  &:active {
    background: ${blue_3};
  }
`;

export const ReportSessionButton = styled.button`
  width: 100%;
  height: auto;
  background: none;
  border-radius: 1.6rem;
  border-style: solid;
  border-weight: 10px;
  border-color: ${light_grey_2};
  padding: 10px;
  color: ${light_grey_3};
  font-size: 1.1rem;
`;

export const TextButton = styled(ButtonBase)`
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.9rem;
  &:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: underline;
  }
  &:active {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
  }
`;

export const SignOutButton = styled(ButtonBase)`
  background: ${blue_2};
  color: white;
  font-family: Helvetica;
  font-size: 1.3rem;
  width: 17rem;
  height: 2.9rem;
  border-radius: 1.6rem;
  &:hover {
    background: ${blue_3};
  }
  &:active {
    background: ${blue_1};
  }
`;

export const PackContainer = styled(ButtonBase)`
  position: relative;
  width: auto;
  height: auto;
  padding: 120% 0 0 0;
  border: none;
  list-style: none;
  &:hover {
    top: -0.6rem;
    left: -0.6rem;
    box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
  }
  &:active {
    top: 0rem;
    left: 0rem;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
`;

export const DummyContainer = styled.div`
  position: relative;
  width: auto;
  height: auto;
  padding: 120% 0 0 0;
  border: none;
  list-style: none;
`;

export const PackManagerButtonPackContainer = styled(PackContainer)`
  background: ${blue_1};
  &:hover {
    top: 0;
    left: 0;
    box-shadow: none;
    background: ${blue_2};
  }
  &:active {
    top: 0rem;
    left: 0rem;
    box-shadow: none;
    background: ${blue_3};
  }
`;

export const ChangeLangButton = styled(ButtonBase)`
  background: ${green_1};
  color: white;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border-radius: 2rem;
  font-family: Helvetica;
  font-size: 1.1rem;
  margin: 0.25rem 0.3rem 0.25rem 0.3rem;
  &:hover {
    background: ${green_2};
  }
  &:active {
    background: ${green_3};
  }
`;

export const ChangeLangBase = styled(ButtonBase)`
  cursor: default;
  background: ${blue_1};
  color: white;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border-radius: 2rem;
  font-family: Helvetica;
  font-size: 1.1rem;
  margin: 0.25rem 0.3rem 0.25rem 0.3rem;
`;

export const ChangeLangNew = styled(ChangeLangBase)`
  background: white;
  color: ${green_1};
`;

export const ChangeLangTarget = styled(ChangeLangBase)`
  background: white;
  color: ${blue_1};
`;

export const ChangeLangButtonSave = styled(ButtonBase)`
  background: ${yellow_1};
  color: black;
  font-family: Helvetica;
  font-size: 1.1rem;
  width: 17rem;
  height: 2.9rem;
  border-radius: 1.6rem;
  &:hover {
    background: ${yellow_2};
  }
  &:active {
    background: ${yellow_3};
  }
`;

// SPACERS

export const Spacer = styled.div`
  flex-basis: 100vw;
  height: ${(props) => props.height || "1rem"};
`;

export const HorzSpacer = styled.div`
  width: ${(props) => props.width || "1rem"};
`;

// DIM SCREEN

export const DisplayDim = styled(ButtonBase)`
  position: fixed;
  top: 0%;
  left: 0%;
  background: rgba(0, 0, 0, 0.35);
  width: 100vw;
  height: 100vh;
  z-index: 5;
`;

export const ScreenLock = styled(ButtonBase)`
  position: fixed;
  top: 0%;
  left: 0%;
  background: rgba(0, 0, 0, 0);
  width: 100vw;
  height: 100vh;
  z-index: 15;
`;

// PROMPTS

export const ManagePacksPrompt = styled(motion.div)`
  position: absolute;
  left: -11.5rem;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  width: 90%;
  height: 90%;
  max-height: 700px;
  max-width: 400px;
  padding: 0rem 0rem 0rem 0rem;
  background: ${(props) => props.background || light_grey_1};
  z-index: 10;
  border-radius: 20px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: ${light_grey_2} ${light_grey_1};
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.075);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.15);
  }
`;

export const PackDetailPrompt = styled(ManagePacksPrompt)`
  background: ${blue_2};
  padding: 0;
  justify-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  z-index: 10;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: ${blue_1_darker} ${blue_2};
`;

export const SettingsPrompt = styled(ManagePacksPrompt)`
  padding: 0;
  justify-items: flex-start;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
  overflow-y: scroll;
`;

export const ManagePacksPromptTitleInset = styled.div`
  position: relative;
  height: 30%;
  margin: 0;
  padding: 1rem 1rem 0 1rem;
`;

export const ExitPractisePrompt = styled(motion.div)`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  width: 18rem;
  height: auto;
  padding: 2rem;
  background: ${(props) => props.background || light_grey_1};
  transform: translate(-50%, -50%);
  z-index: 10;
  border-radius: 20px;
`;
