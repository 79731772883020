import { combineReducers } from 'redux';
import userReducer from './userReducer';
import contentReducer from './contentReducer';
import displayReducer from './displayReducer';
import practiseReducer from './practiseReducer';

export default combineReducers({
	user: userReducer,
	content: contentReducer,
	display: displayReducer,
	practise: practiseReducer
});
