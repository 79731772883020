import axios from "axios";
import {
  SIGNIN_USER,
  SIGNUP_USER,
  FETCH_USER,
  SIGNOUT_USER,
  GOOGLE_SIGNIN,
  GET_PACKS,
  SET_LANGUAGE_PAIR,
  DISPLAY_PACK_MANAGER,
  DISPLAY_SETTINGS,
  DISPLAY_PACK_DETAILS,
  DISPLAY_CHANGE_LANGUAGE,
  UPDATE_PACKS,
  GET_ITEMS,
  CLEAR_ITEMS,
  GET_PACK_PROGRESS,
  CREATE_PRACTISE_SESSION,
  UPDATE_USER_STATS,
  LEVEL_UP,
  PACK_COMPLETED,
} from "./types";

export const signinUser =
  ({ email, password }, history) =>
  async (dispatch) => {
    try {
      const res = await axios.post("/user/signin", {
        email,
        password,
        isMobile: false,
      });

      console.log("res.data");
      dispatch({ type: SIGNIN_USER, payload: res.data });

      !res.data.currentLanguagePair
        ? history.push("/languageselect")
        : history.push("/dashboard");
    } catch (e) {
      return false;
    }
  };

export const signupUser =
  ({ name, email, password, currentLanguagePair }, history) =>
  async (dispatch) => {
    try {
      const res = await axios.post("/user/signup", {
        name,
        email,
        password,
        currentLanguagePair,
      });

      console.log(res.data);
      dispatch({ type: SIGNUP_USER, payload: res.data });
      // need to push to dashboard when set up correctly...
      history.push("/dashboard");
    } catch (e) {
      return false;
    }
  };

export const fetchUser = (history) => async (dispatch) => {
  try {
    const res = await axios.get("/user/read-cookie");
    dispatch({ type: FETCH_USER, payload: res.data });

    res.data.signedin === true
      ? !res.data.currentLanguagePair
        ? history.push("/languageselect")
        : history.push("/dashboard")
      : history.push("/signin");
  } catch (e) {
    console.log(e);
    history.push("/signin");
  }
};

export const signoutUser = (history) => async (dispatch) => {
  try {
    const res = await axios.get("/user/clear-cookie");
    dispatch({ type: SIGNOUT_USER, payload: res.data });
    // hide settings window
    dispatch({ type: DISPLAY_SETTINGS, payload: false });
    history.push("/");
  } catch (e) {
    console.log(e);
  }
};

export const googleSignin = () => async (dispatch) => {
  try {
    const res = await axios.get("/user/google");

    console.log(res.data);
    // history.push('/dashboard');
    dispatch({ type: GOOGLE_SIGNIN, payload: res.data });
  } catch (e) {
    console.log(e);
  }
};

export const getPacks = (currentLanguagePair) => async (dispatch) => {
  try {
    const res = await axios.post("/api/get-packs", { currentLanguagePair });
    dispatch({ type: GET_PACKS, payload: res.data });
  } catch (e) {
    console.log(e);
  }
};

export const setLanguagePair =
  (userid, languagePair, history) => async (dispatch) => {
    try {
      const res = await axios.post("/user/set-language", {
        _id: userid,
        currentLanguagePair: languagePair,
      });
      // need to specify currentLanguagePair in dispatch because otherwise it dispatched languagePair
      dispatch({
        type: SET_LANGUAGE_PAIR,
        payload: { ...res.data, currentLanguagePair: languagePair },
      });

      // setting a timeout because I think the dispatch wasn't happening fast enough
      setTimeout(() => {
        history.push("/dashboard");
      }, 500);
    } catch (e) {
      console.log(e);
    }
  };

export const changeLanguagePair =
  (_id, currentLanguagePair) => async (dispatch) => {
    console.log("change language pair");
    console.log("_id is: " + _id);
    console.log("languagePair is: " + currentLanguagePair);

    try {
      const res = await axios.post("/user/change-language", {
        _id,
        currentLanguagePair,
      });
      // need to specify currentLanguagePair in dispatch because otherwise it dispatched languagePair
      dispatch({ type: SET_LANGUAGE_PAIR, payload: { ...res.data } });
      dispatch({ type: GET_PACK_PROGRESS, payload: null });
      dispatch({ type: CLEAR_ITEMS });
      //history.push('/dashboard');
    } catch (e) {
      console.log(e);
    }

    // if I change the languagePair then I want to clear currentPackProgress and any loaded content packs
  };

export const displayPackManager = (boolean) => (dispatch) => {
  dispatch({ type: DISPLAY_PACK_MANAGER, payload: boolean });
};

export const displayChangeLanguage = (boolean) => (dispatch) => {
  dispatch({ type: DISPLAY_CHANGE_LANGUAGE, payload: boolean });
};

export const displaySettings = (boolean) => (dispatch) => {
  dispatch({ type: DISPLAY_SETTINGS, payload: boolean });
};

export const displayPackDetails =
  (id, name, mainImage, description) => (dispatch) => {
    if (id) {
      dispatch({
        type: DISPLAY_PACK_DETAILS,
        payload: {
          id,
          name,
          mainImage,
          description,
          display: true,
          loaded: true,
        },
      });
    } else {
      dispatch({ type: DISPLAY_PACK_DETAILS, payload: { display: false } });
    }
  };

//

//

//

//

// END OF FUNCTION

export const addActivePack =
  (_id, packId, activePacks, inactivePacks, currentLanguagePair, levels) =>
  async (dispatch) => {
    // check to see if it is inactive pack
    let inactiveId = null;
    for (let i = 0; i < inactivePacks.length; i++) {
      // remove quotation marks from the string
      const pack = inactivePacks[i]._id.split('"').join("");

      if (pack === packId) {
        inactiveId = pack;
        break;
      }
    }

    if (inactiveId) {
      // create a new copy of activePacks without the packId
      // and create a copy of inactivePacks with the packId
      let inactivePacksCopy = [];
      for (let i = 0; i < inactivePacks.length; i++) {
        // remove quotation marks from the string
        const pack = inactivePacks[i]._id.split('"').join("");
        if (pack !== packId) {
          inactivePacksCopy.push(inactivePacks[i]);
        }
        if (pack === packId) {
          activePacks.push(inactivePacks[i]);
        }
      }

      // send activePacks and inactivePacks to the server
      const res = await axios.post("/user/add-inactive-pack", {
        _id,
        activePacks,
        inactivePacks: inactivePacksCopy,
        currentLanguagePair,
      });

      // dispatch update
      dispatch({ type: UPDATE_PACKS, payload: res.data });

      // exit function - get me out of here!
      return;
    }

    // If the pack is uninitiated create a new pack object
    const packObject = {
      _id: packId,
    };

    // add packObject to activePacks and send it to the server
    const res = await axios.post("/user/add-active-pack", {
      _id,
      activePacks: [...activePacks, packObject],
      currentLanguagePair,
    });

    // dispatch update
    dispatch({ type: UPDATE_PACKS, payload: res.data });
  };

//

//

//

//

// END OF FUNCTION

export const addInactivePack =
  (_id, packId, activePacks, inactivePacks, currentLanguagePair) =>
  async (dispatch) => {
    // inactivePacks must come from activePacks!

    // construct a new activePacks without the packId
    let activePacksCopy = [];
    for (let i = 0; i < activePacks.length; i++) {
      // remove quotation marks from the string
      const pack = activePacks[i]._id.split('"').join("");
      if (pack !== packId) {
        activePacksCopy.push(activePacks[i]);
      }
      if (pack === packId) {
        inactivePacks.unshift(activePacks[i]);
      }
    }

    // send active and inactive packs to the server
    const res = await axios.post("/user/add-inactive-pack", {
      _id,
      activePacks: activePacksCopy,
      inactivePacks,
      currentLanguagePair,
    });

    // dispatch update
    dispatch({ type: UPDATE_PACKS, payload: res.data });
  };

//

//

//

//

// END OF FUNCTION

export const displayPackItems =
  (
    packId,
    loadedPacks,
    currentPackProgress,
    progressId,
    packs,
    activePacks,
    currentLanguagePair
  ) =>
  async (dispatch) => {
    // define variables
    let packLoaded = false;
    let loadedProgress;

    // check if pack is already in the reducer
    if (currentPackProgress) {
      for (let i = 0; i < currentPackProgress.length; i++) {
        if (currentPackProgress[i]._id === packId) {
          packLoaded = true;
          loadedProgress = currentPackProgress;
          break;
        }
      }
    }

    // if the pack isn't loaded, load it
    if (!packLoaded) {
      // get content items of pack
      const itemsRes = await axios.post("/api/get-items", {
        packId,
        currentLanguagePair,
      });

      // get progress from the server
      // !!! potentially a waste sending items to backend everytime
      const progressRes = await axios.post("/user/get-progress", {
        progressId,
        packId,
        items: itemsRes.data,
        currentLanguagePair,
      });

      // if currentPackProgress defined add pack progress to existing array
      // if not dispatch pack progress
      // assign pack progress to loadedProgress variable
      if (currentPackProgress) {
        dispatch({
          type: GET_PACK_PROGRESS,
          payload: [...currentPackProgress, progressRes.data],
        });
        loadedProgress = [...currentPackProgress, progressRes.data];
      } else {
        dispatch({ type: GET_PACK_PROGRESS, payload: [progressRes.data] });
        loadedProgress = [progressRes.data];
      }
    }

    // progress
    let progressPack = null;
    for (let i = 0; i < loadedProgress.length; i++) {
      if (loadedProgress[i]._id === packId) {
        progressPack = loadedProgress[i];
        break;
      }
    }

    console.log(JSON.stringify(progressPack, null, 2));
  };

export const createPracticeSession =
  (
    packId,
    loadedPacks,
    currentPackProgress,
    history,
    type,
    progressId,
    currentLanguagePair,
    packs,
    activePacks
  ) =>
  async (dispatch) => {
    console.log("creating practice session...");

    // define variables
    let packLoaded = false;
    let loadedProgress;
    let loadedContent;
    let levels;
    let currentLevel;
    let levelType;
    let buType;
    let tsType;
    const currentTime = Date.now();

    // speaking
    if (type === "speaking") {
      buType = "bu_S";
      tsType = "ts_S";
      levelType = "currentLevel_S";
    }

    // listening
    if (type === "listening") {
      buType = "bu_L";
      tsType = "ts_L";
      levelType = "currentLevel_L";
    }

    // get level data from pack
    for (let i = 0; i < packs.length; i++) {
      if (packs[i]._id === packId) {
        if (packs[i].levels === undefined) {
          break;
        } else {
          levels = packs[i].levels;
          break;
        }
      }
    }

    // check for currentLevel of given type
    // if none, set to 0 or 1 depending on whether pack has levels
    for (let i = 0; i < activePacks.length; i++) {
      if (activePacks[i]._id === packId) {
        if (activePacks[i][levelType] === undefined) {
          if (levels === 0) {
            currentLevel = 0;
          } else {
            currentLevel = 1;
            console.log("assigning default level 1");
          }
          break;
        } else {
          currentLevel = activePacks[i][levelType];
          console.log("current level is: " + currentLevel);
          break;
        }
      }
    }

    // check if pack is already in the reducer
    if (currentPackProgress) {
      for (let i = 0; i < currentPackProgress.length; i++) {
        if (currentPackProgress[i]._id === packId) {
          packLoaded = true;
          loadedProgress = currentPackProgress;
          loadedContent = loadedPacks;
          break;
        }
      }
    }

    // if the pack isn't loaded, load it
    if (!packLoaded) {
      // get content items of pack
      const itemsRes = await axios.post("/api/get-items", {
        packId,
        currentLanguagePair,
      });

      dispatch({
        type: GET_ITEMS,
        payload: { _id: packId, items: itemsRes.data },
      });
      loadedContent = [{ _id: packId, items: itemsRes.data }];

      // get progress from the server
      // !!! potentially a waste sending items to backend everytime
      const progressRes = await axios.post("/user/get-progress", {
        progressId,
        packId,
        items: itemsRes.data,
        currentLanguagePair,
      });

      // if currentPackProgress defined add pack progress to existing array
      // if not dispatch pack progress
      // assign pack progress to loadedProgress variable
      if (currentPackProgress) {
        dispatch({
          type: GET_PACK_PROGRESS,
          payload: [...currentPackProgress, progressRes.data],
        });
        loadedProgress = [...currentPackProgress, progressRes.data];
      } else {
        dispatch({ type: GET_PACK_PROGRESS, payload: [progressRes.data] });
        loadedProgress = [progressRes.data];
      }
    }

    // use packId to get pack info from loadedPacks and currentPack progress
    // content
    let contentPack = null;
    for (let i = 0; i < loadedContent.length; i++) {
      if (loadedContent[i]._id === packId) {
        contentPack = loadedContent[i];
        break;
      }
    }
    // progress
    let progressPack = null;
    for (let i = 0; i < loadedProgress.length; i++) {
      if (loadedProgress[i]._id === packId) {
        progressPack = loadedProgress[i];
        break;
      }
    }

    //

    //

    //

    //

    // CHECK FOR NEW ITEMS

    // check for descrepancies between contentPack and progressPack
    // may occur if items have been added or removed from the contentPack

    // filtered array is items that ARE in contentPack
    // filter omits any items deleted from the contentPack
    const progressPackChecked = progressPack.items.filter(({ _id: id1 }) =>
      contentPack.items.some(({ _id: id2 }) => id2 === id1)
    );

    // filtered array is items that AREN'T in progressPack
    // filter adds items that have been added to contentPack
    const newItems = contentPack.items.filter(
      ({ _id: id1 }) => !progressPack.items.some(({ _id: id2 }) => id2 === id1)
    );

    // if newItems, add them to progressPackChecked
    if (newItems.length > 0) {
      // create new progress items
      let newProgressItems = [];
      for (let i = 0; i < newItems.length; i++) {
        const progressItem = {
          _id: newItems[i]._id,
          order: progressPackChecked.length + i,
        };
        newProgressItems.push(progressItem);
      }

      // add newItems to progressPackChecked
      progressPackChecked.push(...newProgressItems);
    }

    // check ordering of pack
    if (
      newItems.length > 0 ||
      progressPackChecked.length !== contentPack.items.length
    ) {
      // for loops apply contentPack ordering to progressPack
      for (let i = 0; i < contentPack.items.length; i++) {
        for (let k = 0; k < progressPackChecked.length; k++) {
          if (progressPackChecked[k]._id === contentPack.items[i]._id) {
            progressPackChecked[k].order = contentPack.items[i].order;
            break;
          }
        }
      }

      // update progressPack items
      progressPack.items = [...progressPackChecked];
    }

    //

    //

    //

    //

    // SORT ITEMS BASED ON LEVELS

    let progressItems;

    if (levels > 0) {
      const contentItemsLevels = [];

      // get items in currentLevel or below
      for (let i = 0; i < contentPack.items.length; i++) {
        if (contentPack.items[i].level <= currentLevel) {
          contentItemsLevels.push(contentPack.items[i]);
        }
      }

      console.log("items in level are: " + contentItemsLevels.length);

      // filter progress pack based on content items
      progressItems = progressPack.items.filter(({ _id: id1 }) =>
        contentItemsLevels.some(({ _id: id2 }) => id2 === id1)
      );
    } else {
      // assign all items to progressItems
      progressItems = progressPack.items;
    }

    //

    //

    //

    //

    // CREATE PRACTICE SESSION

    // select items based on order, bucket number and timestamp.
    let bucket0Items = [];
    let bucketOtherItems = [];
    let unpracticedItems = [];

    // need to be careful here bucket 0 was being classed as undefined before
    for (let i = 0; i < progressItems.length; i++) {
      if (progressItems[i][buType] === undefined) {
        unpracticedItems.push(progressItems[i]);
      } else {
        if (progressItems[i][buType] === 0) {
          bucket0Items.push(progressItems[i]);
        }
        if (progressItems[i][buType] > 0) {
          bucketOtherItems.push(progressItems[i]);
        }
      }
    }

    // console.log('bucket 0 items: ' + bucket0Items);
    // console.log('bucketOtherItems: ' + bucketOtherItems);
    // console.log('unpracticedItems: ' + unpracticedItems);

    // CONSOLE LOG ITEM STATUS
    // for (let i = 0; i < bucket0Items.length; i++) {
    // 	const date = new Date(bucket0Items[i].ts);
    // 	const day = date.getDate();
    // 	const month = date.getMonth();
    // 	const hours = date.getHours();
    // 	const minutes = '0' + date.getMinutes();
    // 	const seconds = '0' + date.getSeconds();
    // 	const formattedTime = day + '/' + month + ' | ' + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

    // 	console.log('bucket0:   ' + bucket0Items[i]._id + '__' + bucket0Items[i].bucket + '__' + formattedTime);
    // }
    // for (let i = 0; i < bucketOtherItems.length; i++) {
    // 	const date = new Date(bucketOtherItems[i].ts);
    // 	const day = date.getDate();
    // 	const month = date.getMonth();
    // 	const hours = date.getHours();
    // 	const minutes = '0' + date.getMinutes();
    // 	const seconds = '0' + date.getSeconds();
    // 	const formattedTime = day + '/' + month + ' | ' + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

    // 	console.log(
    // 		'bucketOther:    ' + bucketOtherItems[i]._id + '__' + bucketOtherItems[i].bucket + '__' + formattedTime
    // 	);
    // }
    // for (let i = 0; i < unpracticedItems.length; i++) {
    // 	console.log(
    // 		'unpracticed:        ' +
    // 			unpracticedItems[i]._id +
    // 			'__' +
    // 			unpracticedItems[i].bucket +
    // 			'__' +
    // 			unpracticedItems[i].ts
    // 	);
    // }

    //

    //

    //

    //

    // ITEM SORTING

    let sortedBucket0Items;
    let sortedBucketOtherItems;
    let bucketOtherItemsReady = [];
    let bucketOtherItemsNotReady = [];

    // sort bucket0 based on timestamp
    sortedBucket0Items = bucket0Items.sort((a, b) =>
      a[tsType] > b[tsType] ? 1 : -1
    );

    // sort bucketOther based on timestamp
    sortedBucketOtherItems = bucketOtherItems.sort((a, b) =>
      a[tsType] > b[tsType] ? 1 : -1
    );

    // split bucketsOther based on timestamp
    for (let i = 0; i < sortedBucketOtherItems.length; i++) {
      if (sortedBucketOtherItems[i][tsType] > currentTime) {
        bucketOtherItemsNotReady.push(sortedBucketOtherItems[i]);
      }
      if (sortedBucketOtherItems[i][tsType] < currentTime) {
        bucketOtherItemsReady.push(sortedBucketOtherItems[i]);
      }
    }

    // combine all the sorted items
    let sortedProgressItems = [];
    sortedProgressItems.push(...sortedBucket0Items);
    sortedProgressItems.push(...bucketOtherItemsReady);
    sortedProgressItems.push(...unpracticedItems);

    // default practiseLength is 10
    let practiseLength = 10;

    if (sortedProgressItems.length < 10) {
      practiseLength = sortedProgressItems.length;
    }

    // get items for practise
    let practiseProgressItems = [];
    for (let i = 0; i < practiseLength; i++) {
      practiseProgressItems.push(sortedProgressItems[i]);
    }

    // shuffle practiseProgressItems
    const shuffle = (array) => {
      let m = array.length;
      let t;
      let i;
      let arr = [...array];

      // While there remain elements to shuffle…
      while (m) {
        // Pick a remaining element…
        i = Math.floor(Math.random() * m--);

        // And swap it with the current element.
        t = arr[m];
        arr[m] = arr[i];
        arr[i] = t;
      }

      return arr;
    };

    const arr = shuffle(practiseProgressItems);
    practiseProgressItems = [...arr];

    // get content data for practice items
    let practiseContentItems = [];
    for (let i = 0; i < practiseProgressItems.length; i++) {
      for (let k = 0; k < contentPack.items.length; k++) {
        if (contentPack.items[k]._id === practiseProgressItems[i]._id) {
          practiseContentItems.push(contentPack.items[k]);
          break;
        }
      }
    }

    // CONSOLE LOG SORTED ITEMS
    // console.log("                       ");
    // console.log("PRACTICE ITEMS:          ");

    // for (let i = 0; i < practiseLength; i++) {
    //   const date = new Date(practiseProgressItems[i].ts_S);
    //   const day = date.getDate();
    //   const month = date.getMonth();
    //   const hours = date.getHours();
    //   const minutes = "0" + date.getMinutes();
    //   const seconds = "0" + date.getSeconds();
    //   const formattedTime =
    //     day +
    //     "/" +
    //     month +
    //     " | " +
    //     hours +
    //     ":" +
    //     minutes.substr(-2) +
    //     ":" +
    //     seconds.substr(-2);

    //   console.log(
    //     "practise item:    " +
    //       practiseProgressItems[i]._id +
    //       "__" +
    //       practiseProgressItems[i].bu_S +
    //       "__" +
    //       formattedTime
    //   );
    // }

    // practise object holds all info for practise session
    const practiseObject = {
      practiseProgressItems,
      practiseContentItems,
      type,
      practiseLength,
      levels,
      currentLevel,
      levelType,
      levelUp: false,
    };

    // save practiseObject to the reducer for easy access
    dispatch({ type: CREATE_PRACTISE_SESSION, payload: practiseObject });

    // navigate to practise screen
    history.push("/practise");
  };

//

//

//

//

// END OF FUNCTION

export const evaluatePractiseSession =
  (
    practiseProgressItems,
    evaluation,
    packId,
    currentPackProgress,
    progressId,
    currentLanguagePair,
    activePacks,
    _id,
    startTime,
    timePracticed,
    lastPracticed,
    currentStreak,
    learningPoints,
    consolidationPoints,
    type,
    weeklyStats,
    levels,
    currentLevel,
    levelType,
    packs,
    loadedPacks,
    forceLevelUp
  ) =>
  async (dispatch) => {
    // console.log('start time is: ' + startTime);
    // console.log('time practiced is: ' + timePracticed);
    // console.log('last practiced is: ' + lastPracticed);
    // console.log('current streak is: ' + currentStreak);
    // console.log('learning points are: ' + learningPoints);
    // console.log('consolidation points are: ' + consolidationPoints);

    // console log evaluated item info

    // const date = new Date(arr[i][tsType]);
    // const day = date.getDate();
    // const month = date.getMonth();
    // const hours = date.getHours();
    // const minutes = "0" + date.getMinutes();
    // const seconds = "0" + date.getSeconds();
    // const formattedTime =
    //   day +
    //   "/" +
    //   month +
    //   " | " +
    //   hours +
    //   ":" +
    //   minutes.substr(-2) +
    //   ":" +
    //   seconds.substr(-2);

    // console.log(
    //   "evaluated item learning:    " +
    //     arr[i]._id +
    //     "__" +
    //     arr[i][buType] +
    //     "__" +
    //     formattedTime
    // );

    console.log(`------- force level up is set to ${forceLevelUp}.`);

    let sessionLearningPoints = 0;
    let sessionConsolidationPoints = 0;

    // set up time constants
    const currentTime = Date.now();
    // const fiveMinutes = 1000 * 60 * 5;
    const tenMinutes = 1000 * 60 * 10;
    // const oneHour = 1000 * 60 * 60;
    // const threeHours = 1000 * 60 * 60 * 3;
    const thirtyHours = 1000 * 60 * 60 * 30;

    const oneDay = 1000 * 60 * 60 * 24;
    const twoDays = 1000 * 60 * 60 * 24 * 2;
    const fourDays = 1000 * 60 * 60 * 24 * 4;
    // const sixDays = 1000 * 60 * 60 * 24 * 6;
    const eightDays = 1000 * 60 * 60 * 24 * 8;
    // const twelveDays = 1000 * 60 * 60 * 24 * 12;
    const eighteenDays = 1000 * 60 * 60 * 24 * 18;

    // define variables
    let buType;
    let tsType;
    let timestampsType;
    let completedType;
    let countType;
    let consolidation;
    let learning;
    let timePracticedType;
    let learningPointsType;
    let consolidationPointsType;
    let learningPointsLevels;
    let consolidationPointsLevels;
    let timePracticedLevels;
    let currentLevelType;

    // speaking
    if (type === "speaking") {
      buType = "bu_S";
      tsType = "ts_S";
      timestampsType = "timestamps_S";
      completedType = "completed_S";
      countType = "count_S";
      consolidation = "consolidation_S";
      learning = "learning_S";
      timePracticedType = "timePracticed_S";
      learningPointsType = "learningPoints_S";
      consolidationPointsType = "consolidationPoints_S";
      learningPointsLevels = "learningPointsLevels_S";
      consolidationPointsLevels = "consolidationPointsLevels_S";
      timePracticedLevels = "timePracticedLevels_S";
      currentLevelType = "currentLevel_S";
    }

    // listening
    if (type === "listening") {
      buType = "bu_L";
      tsType = "ts_L";
      timestampsType = "timestamps_L";
      completedType = "completed_L";
      countType = "count_L";
      consolidation = "consolidation_L";
      learning = "learning_L";
      timePracticedType = "timePracticed_L";
      learningPointsType = "learningPoints_L";
      consolidationPointsType = "consolidationPoints_L";
      learningPointsLevels = "learningPointsLevels_L";
      consolidationPointsLevels = "consolidationPointsLevels_L";
      timePracticedLevels = "timePracticedLevels_L";
      currentLevelType = "currentLevel_L";
    }

    console.log("                       ");
    console.log("EVALUATED ITEMS:          ");

    // update practiseProgressItems according to evaluation array
    let arr = practiseProgressItems.slice();

    for (let i = 0; i < arr.length; i++) {
      // if learning set bucket zero
      if (evaluation[i] === "learning") {
        sessionLearningPoints++;

        arr[i][buType] = 0;
        arr[i][tsType] = currentTime + i;
      }

      // if consolidation upgrade bucket and ts
      if (evaluation[i] === "consolidating") {
        sessionConsolidationPoints++;

        // if bucket doesn't exist create it and make it 0, set ts to currentTime
        if (arr[i][buType] === undefined) {
          arr[i][buType] = 0;
          arr[i][tsType] = currentTime + i;
        }

        // if bucket is less than 5 and FORCE LEVEL UP is FALSE
        if (arr[i][buType] < 5 && !forceLevelUp) {
          // increment bucket if ready
          arr[i][tsType] < currentTime
            ? arr[i][buType]++
            : console.log("bucket not ready to upgrade");

          // buckets 1 - 4
          switch (arr[i][buType]) {
            case 1:
              arr[i][tsType] < currentTime
                ? (arr[i][tsType] = currentTime + oneDay + i) // + one day
                : console.log("bucket 1 not ready");
              break;
            case 2:
              arr[i][tsType] < currentTime
                ? (arr[i][tsType] = currentTime + twoDays + i) // + two days
                : console.log("bucket 2 not ready");
              break;
            case 3:
              arr[i][tsType] < currentTime
                ? (arr[i][tsType] = currentTime + fourDays + i) // + four days
                : console.log("bucket 3 not ready");
              break;
            case 4:
              arr[i][tsType] < currentTime
                ? (arr[i][tsType] = currentTime + eightDays + i) // + eight days
                : console.log("bucket 4 not ready");
              break;
            default:
              console.log("no bucket found!");
          }
        }

        // *** FOR TESTING LEVEL FUNCTIONALITY ***

        // if bucket is less than 5 and FORCE LEVEL UP is TRUE
        if (arr[i][buType] < 5 && forceLevelUp) {
          console.log("FORCING LEVEL UP...");

          // increment bucket to at least 3 regardless of timestamp
          if (arr[i][buType] < 3) {
            arr[i][buType] = 3;
          } else {
            arr[i][buType]++;
          }

          // set timestamp to be four days from now
          arr[i][tsType] = currentTime + fourDays + i;
        }

        // bucket 5
        if (arr[i][buType] === 5) {
          arr[i][tsType] < currentTime
            ? (arr[i][tsType] = currentTime + eighteenDays + i) // + eighteen days
            : console.log("bucket 5 not ready");
        }
      }
    }

    // merge practiseProgressItems with the main array
    // create a copy of the progress pack
    let arrPackProgress = [];

    for (let i = 0; i < currentPackProgress.length; i++) {
      if (currentPackProgress[i]._id === packId) {
        arrPackProgress = currentPackProgress[i];
        break;
      }
    }

    // update the progress pack
    for (let i = 0; i < arr.length; i++) {
      for (let k = 0; k < arrPackProgress.items.length; k++) {
        if (arr[i]._id === arrPackProgress.items[k]._id) {
          arrPackProgress.items[k] = arr[i];
          break;
        }
      }
    }

    // get timestamps and save in active packs
    let timestampsArr = [];

    for (let i = 0; i < arrPackProgress.items.length; i++) {
      if (arrPackProgress.items[i][tsType]) {
        timestampsArr.push(arrPackProgress.items[i][tsType]);
      }
    }

    // update the database
    const resProgress = await axios.post("/user/update-progress", {
      progressId,
      packId,
      packProgress: arrPackProgress,
      currentLanguagePair,
    });
    console.log(resProgress);

    // update the reducer
    let updatedCurrentPackProgress = currentPackProgress.slice();
    for (let i = 0; i < updatedCurrentPackProgress.length; i++) {
      if (updatedCurrentPackProgress[i]._id === arrPackProgress._id) {
        updatedCurrentPackProgress[i] = arrPackProgress;
        break;
      }
    }
    dispatch({ type: GET_PACK_PROGRESS, payload: updatedCurrentPackProgress });

    //

    //

    //

    //

    // CALCULATE LEVEL INFORMATION

    // use the content pack to determine the levels, which items need to be checked
    // get pack info from loadedPacks and currentPack progress
    let contentPack = null;
    for (let i = 0; i < loadedPacks.length; i++) {
      if (loadedPacks[i]._id === packId) {
        contentPack = loadedPacks[i].items;
        break;
      }
    }

    // get pack to update
    let activePacksCopy = [];
    let packUpdate = null;

    for (let i = 0; i < activePacks.length; i++) {
      if (activePacks[i]._id === packId) {
        packUpdate = activePacks[i];
      } else {
        activePacksCopy.push(activePacks[i]);
      }
    }

    // if the pack has levels
    if (levels > 0) {
      const contentItemsLevels = [];
      const nextLevel = currentLevel + 1;
      let nextLevelItems = 0;

      for (let i = 0; i < contentPack.length; i++) {
        if (contentPack[i].level <= currentLevel) {
          contentItemsLevels.push(contentPack[i]);
        }

        if (Number(contentPack[i].level) === nextLevel) {
          nextLevelItems++;
        }
      }

      // get progress items based on content pack level info
      let progressLevelItems = [];

      for (let i = 0; i < contentItemsLevels.length; i++) {
        for (let k = 0; k < arrPackProgress.items.length; k++) {
          if (arrPackProgress.items[k]._id === contentItemsLevels[i]._id) {
            progressLevelItems.push(arrPackProgress.items[k]);
            break;
          }
        }
      }

      // NB: getting items to bucket 3 means user levels up
      // check buckets of progress items
      let itemsBucketOnePlus = 0;
      let itemsBucketThreePlus = 0;
      let itemsTimestamps = [];

      for (let i = 0; i < progressLevelItems.length; i++) {
        itemsTimestamps.push(progressLevelItems[i][tsType]);

        if (progressLevelItems[i][tsType]) {
          itemsBucketOnePlus++;
        }

        if (progressLevelItems[i][buType] > 2) {
          itemsBucketThreePlus++;
        }
      }

      // items bucket one or over
      let itemsLearnt = Math.round(
        (itemsBucketOnePlus / progressLevelItems.length) * 100
      );

      // items bucket three or over
      let itemsConsolidated = Math.round(
        (itemsBucketThreePlus / progressLevelItems.length) * 100
      );

      // if values are undefined set them to default
      if (packUpdate[currentLevelType] === undefined) {
        packUpdate[currentLevelType] = 1;
      }

      if (packUpdate[timePracticedType] === undefined) {
        packUpdate[timePracticedType] = 0;
      }

      if (packUpdate[learningPointsType] === undefined) {
        packUpdate[learningPointsType] = 0;
      }

      if (packUpdate[consolidationPointsType] === undefined) {
        packUpdate[consolidationPointsType] = 0;
      }

      if (packUpdate[learningPointsLevels] === undefined) {
        packUpdate[learningPointsLevels] = [];
      }

      if (packUpdate[consolidationPointsLevels] === undefined) {
        packUpdate[consolidationPointsLevels] = [];
      }

      if (packUpdate[timePracticedLevels] === undefined) {
        packUpdate[timePracticedLevels] = [];
      }

      // level up pack if itemsConsolidated are at 100% and currentLevel isn't the last level
      if (itemsConsolidated === 100 && !packUpdate[completedType]) {
        if (currentLevel !== levels) {
          // level up pack
          packUpdate[currentLevelType]++;
          console.log("pack leveled up...");

          // update the reducer to say that the user just leveled up!
          dispatch({ type: LEVEL_UP, payload: { levelUp: true } });

          // add next level items into itemsConsolidated equation
          itemsConsolidated = Math.round(
            (itemsBucketThreePlus /
              (progressLevelItems.length + nextLevelItems)) *
              100
          );

          itemsLearnt = Math.round(
            (itemsBucketOnePlus /
              (progressLevelItems.length + nextLevelItems)) *
              100
          );
        } else {
          // set completion to true
          packUpdate[completedType] = true;

          // update the reducer to say that the user just completed the pack!
          dispatch({ type: PACK_COMPLETED, payload: { packCompleted: true } });
        }

        // NEED TO SUBTRACT FROM THE PONTS/TIME OF THE PREVIOUS LEVELS
        // get the points/time of previous level and then subtract it from current level
        let previousLearningPoints = 0;
        let previousConsolidationPoints = 0;
        let previousTime = 0;

        if (currentLevel !== 1) {
          // previousLearningPoints =
          //   packUpdate[learningPointsLevels][currentLevel - 2];
          // previousConsolidationPoints =
          //   packUpdate[consolidationPointsLevels][currentLevel - 2];
          // previousTime = packUpdate[timePracticedLevels][currentLevel - 2];

          const calculatePreviousLevels = (array) => {
            const total = array.reduce(
              (total, currentValue) => (total = total + currentValue),
              0
            );
            return total;
          };

          previousLearningPoints = calculatePreviousLevels(
            packUpdate[learningPointsLevels]
          );
          previousConsolidationPoints = calculatePreviousLevels(
            packUpdate[consolidationPointsLevels]
          );
          previousTime = calculatePreviousLevels(
            packUpdate[timePracticedLevels]
          );
        }

        // save stats for level just completed
        // learning points levels
        packUpdate[learningPointsLevels][currentLevel - 1] =
          packUpdate[learningPointsType] +
          sessionLearningPoints -
          previousLearningPoints;
        // consolidation points levels
        packUpdate[consolidationPointsLevels][currentLevel - 1] =
          packUpdate[consolidationPointsType] +
          sessionConsolidationPoints -
          previousConsolidationPoints;
        // time practiced levels
        packUpdate[timePracticedLevels][currentLevel - 1] =
          packUpdate[timePracticedType] +
          currentTime -
          startTime -
          previousTime;

        // need to subtract the TOTAL of previous levels otherwise it's not going to be the right amount!

        // console.log(`session learning points....... ${sessionLearningPoints}`);

        // console.log(
        //   `previous learning points....... ${previousLearningPoints}`
        // );

        // console.log(
        //   `session consolidation points....... ${sessionConsolidationPoints}`
        // );

        // console.log(
        //   `previous consolidation points....... ${previousConsolidationPoints}`
        // );

        // console.log(
        //   `learning points for level:  ${
        //     packUpdate[learningPointsLevels][currentLevel - 1]
        //   }`
        // );
        // console.log(
        //   `consolidation points for level:  ${
        //     packUpdate[consolidationPointsLevels][currentLevel - 1]
        //   }`
        // );
        // console.log(
        //   `time for level:  ${
        //     packUpdate[timePracticedLevels][currentLevel - 1]
        //   }`
        // );
      }

      // update pack stats
      // last updated
      packUpdate.lastUpdated = currentTime;
      // timestamps
      packUpdate[timestampsType] = timestampsArr;
      // learning percentage
      packUpdate[learning] = itemsLearnt;
      // consolidation percentage
      packUpdate[consolidation] = itemsConsolidated;
      // time practiced
      packUpdate[timePracticedType] += currentTime - startTime;
      // learning points
      packUpdate[learningPointsType] += sessionLearningPoints;
      // consolidation points
      packUpdate[consolidationPointsType] += sessionConsolidationPoints;
    } else {
      // if the pack doesn't have levels

      let itemsBucketOnePlus = 0;
      let itemsBucketThreePlus = 0;
      let itemsTimestamps = [];

      for (let i = 0; i < arrPackProgress.items.length; i++) {
        itemsTimestamps.push(arrPackProgress.items[i][tsType]);

        if (arrPackProgress.items[i][tsType]) {
          itemsBucketOnePlus++;
        }
        if (arrPackProgress.items[i][buType] > 2) {
          itemsBucketThreePlus++;
        }
      }

      const itemsLearnt = Math.round(
        (itemsBucketOnePlus / arrPackProgress.items.length) * 100
      );

      const itemsConsolidated = Math.round(
        (itemsBucketThreePlus / arrPackProgress.items.length) * 100
      );

      // if values are undefined set them to default
      // NB: setting currentLevel to 0 indicates that the pack doesn't have levels
      if (packUpdate[currentLevelType] === undefined) {
        packUpdate[currentLevelType] = 0;
      }

      if (packUpdate[timePracticedType] === undefined) {
        packUpdate[timePracticedType] = 0;
      }

      if (packUpdate[learningPointsType] === undefined) {
        packUpdate[learningPointsType] = 0;
      }

      if (packUpdate[consolidationPointsType] === undefined) {
        packUpdate[consolidationPointsType] = 0;
      }

      // check for pack completion
      if (itemsConsolidated === 100 && packUpdate[completedType] === false) {
        // set completion to true
        packUpdate[completedType] = true;

        // learning points levels
        packUpdate[learningPointsLevels] =
          packUpdate[learningPointsType] + sessionLearningPoints;
        // consolidation points levels
        packUpdate[consolidationPointsLevels] =
          packUpdate[consolidationPointsType] + sessionConsolidationPoints;
        // time practiced levels
        packUpdate[timePracticedLevels] =
          packUpdate[timePracticedType] + currentTime - startTime;

        // update the reducer to say that the user just completed the pack!
        dispatch({ type: PACK_COMPLETED, payload: { packCompleted: true } });

        console.log("pack completed...");
      }

      // update pack stats
      // last updated
      packUpdate.lastUpdated = currentTime;
      // timestamps
      packUpdate[timestampsType] = timestampsArr;
      // learning percentage
      packUpdate[learning] = itemsLearnt;
      // consolidation percentage
      packUpdate[consolidation] = itemsConsolidated;
      // time practiced
      packUpdate[timePracticedType] += currentTime - startTime;
      // learning points
      packUpdate[learningPointsType] += sessionLearningPoints;
      // consolidation points
      packUpdate[consolidationPointsType] += sessionConsolidationPoints;

      //

      //

      //

      //

      // LEGACY CODE - DELETE LATER

      // if completedType doesn't exist make it 0
      if (!packUpdate[completedType]) {
        packUpdate[completedType] = 0;
      }

      // if pack completed completion is based on timestamps
      if (packUpdate[completedType] === 1) {
        let completionCount = 0;
        for (let i = 0; i < arrPackProgress.items.length; i++) {
          if (arrPackProgress.items[i][buType] > 2) {
            completionCount++;
          }
        }
        // figure out pack completion percentage
        packUpdate[countType] = Math.round(
          (completionCount / arrPackProgress.items.length) * 100
        );
      }

      // if pack completion is set to false
      if (packUpdate[completedType] === 0) {
        let completionCount = 0;
        // need to count how many packs have been initiated. This will check if bucket is 0 or undefined.
        for (let i = 0; i < arrPackProgress.items.length; i++) {
          if (arrPackProgress.items[i][buType] > 0) {
            completionCount++;
          }
        }
        // figure out pack completion percentage
        packUpdate[countType] = Math.round(
          (completionCount / arrPackProgress.items.length) * 100
        );

        // check to see if the pack needs to be set to completed
        if (packUpdate[countType] === 100) {
          packUpdate[completedType] = 1;
        }
      }
    }

    //

    //

    //

    //

    // UPDATE ACTIVE PACKS

    // levels
    if (packUpdate.levels === undefined || packUpdate.levels !== levels) {
      packUpdate.levels = levels;
    }

    // last practiced type
    packUpdate.lastType = type;

    // unshift updated pack to the beginning of activePacksCopy
    activePacksCopy.unshift(packUpdate);

    // update the database and reducer
    await axios.post("/user/add-active-pack", {
      _id,
      activePacks: activePacksCopy,
      currentLanguagePair,
    });

    dispatch({ type: UPDATE_PACKS, payload: { activePacks: activePacksCopy } });

    //

    //

    //

    //

    // UPDATE USER STATS

    const date = new Date();

    const getSessionTime = (currentTime, startTime, tenMinutes) => {
      let sessionTime = currentTime - startTime;

      // if it's more than 10 minutes just make it 10 minutes
      if (sessionTime > tenMinutes) {
        sessionTime = tenMinutes;
      }

      return sessionTime;
    };

    const checkStreak = (date, currentStreak, currentTime, lastPracticed) => {
      const startOfToday = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );

      // check the streak
      let updatedCurrentStreak = currentStreak;
      const streakCheck = currentTime - lastPracticed;

      // if more than 30 hours have passed sense last practise reset the streak
      if (streakCheck > thirtyHours) {
        updatedCurrentStreak = startOfToday.getTime();
      }

      return updatedCurrentStreak;
    };

    const getExpiry = (date) => {
      const startOfWeek = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - date.getDay()
      );
      const sevenDays = 1000 * 60 * 60 * 24 * 7;
      const expiry = startOfWeek.getTime() + sevenDays;

      return expiry;
    };

    // MAY CREATE AN ERROR IF weeklyStats.ts DOESN'T EXIST
    // !! weeklyStats might be stale
    const getWeeklyStats = (
      sessionLearningPoints,
      sessionConsolidationPoints,
      sessionTime,
      weeklyStats,
      date,
      currentTime
    ) => {
      // if weeklyStats undefined set it to an empty array
      if (!weeklyStats) {
        weeklyStats = [];
      }

      // update weeklyStats
      if (currentTime < weeklyStats.ts) {
        console.log("updating weekly stats...");
        const updatedWeeklyStats = { ...weeklyStats };

        !weeklyStats.lp[date.getDay()]
          ? (updatedWeeklyStats.lp[date.getDay()] = sessionLearningPoints)
          : (updatedWeeklyStats.lp[date.getDay()] += sessionLearningPoints);

        !weeklyStats.cp[date.getDay()]
          ? (updatedWeeklyStats.cp[date.getDay()] = sessionConsolidationPoints)
          : (updatedWeeklyStats.cp[date.getDay()] +=
              sessionConsolidationPoints);

        !weeklyStats.t[date.getDay()]
          ? (updatedWeeklyStats.t[date.getDay()] = sessionTime)
          : (updatedWeeklyStats.t[date.getDay()] += sessionTime);

        // console.log(JSON.stringify(updatedWeeklyStats, null, 2));
        return updatedWeeklyStats;
        // create new weeklyStats
      } else {
        console.log("reset weekly stats...");
        let lp = [];
        let cp = [];
        let t = [];
        lp[date.getDay()] = sessionLearningPoints;
        cp[date.getDay()] = sessionConsolidationPoints;
        t[date.getDay()] = sessionTime;

        return {
          ts: getExpiry(date),
          lp,
          cp,
          t,
        };
      }
    };

    const updatedUserStats = {
      timePracticed:
        timePracticed + getSessionTime(currentTime, startTime, tenMinutes),
      lastPracticed: currentTime,
      currentStreak: checkStreak(
        date,
        currentStreak,
        currentTime,
        lastPracticed
      ),
      learningPoints: learningPoints + sessionLearningPoints,
      consolidationPoints: consolidationPoints + sessionConsolidationPoints,
      weeklyStats: getWeeklyStats(
        sessionLearningPoints,
        sessionConsolidationPoints,
        getSessionTime(currentTime, startTime, tenMinutes),
        weeklyStats,
        date,
        currentTime
      ),
    };

    const resUpdateUserStats = await axios.post("/user/update-stats", {
      _id,
      currentLanguagePair,
      ...updatedUserStats,
    });

    console.log(resUpdateUserStats);
    // update the reducer
    dispatch({
      type: UPDATE_USER_STATS,
      payload: {
        ...updatedUserStats,
      },
    });
  };
