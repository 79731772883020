export const formatTime = (length, returnSeconds) => {
  const lengthToDate = new Date(length);
  const hours = lengthToDate.getUTCHours();
  let minutes = lengthToDate.getUTCMinutes();
  let seconds = lengthToDate.getUTCSeconds();

  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  if (hours > 0 && minutes < 10) {
    minutes = "0" + minutes;
  }

  // return with hours
  if (hours > 0) {
    return returnSeconds
      ? hours + ":" + minutes + ":" + seconds
      : hours + "h " + minutes + "m";
  }

  // return without hours
  if (hours === 0) {
    return returnSeconds ? minutes + ":" + seconds : minutes + " mins";
  }
};
