import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AnimatePresence } from "framer-motion";
// import Lottie from "lottie-react";

import {
  SiteContainer,
  DashboardContainer,
  TopBarContainer,
  ContentContainer,
  EmptyContentContainer,
  PackContainer,
  // PackManagerButtonPackContainer,
  PackFrameContainer,
  // PackItemsReadyDisplay,
  Img,
  TopBarDiv,
  SettingsButton,
  HorzSpacer,
  // CenterContentContainer,
  DisplayDim,
  StatsContainer,
  DashboardEmptyText,
  StatsContainerText,
  StatsContainerImage,
  StatsContainerMain,
  StatsText,
  StatsNumber,
  PackItemsReadyMotion,
  PackItemsReadyContainer,
  blue_2,
  Spacer,
} from "./styles";
import {
  signoutUser,
  getPacks,
  displayPackManager,
  displaySettings,
  displayPackDetails,
  displayChangeLanguage,
} from "../actions/index";
import { formatTime } from "./formatTime";

import ManagePacks from "../components/ManagePacks";
import PackDetails from "../components/PackDetails";
import Settings from "../components/Settings";
import ChangeLanguage from "../components/ChangeLanguage";
import Thumbnail from "../components/Thumbnail";
import CompletionBar from "../components/CompletionBar";

import speaksyLogo from "../images/speaksy_alpha_nospace-01.svg";
import settingsDots from "../images/icons/icon_settings_dots-01.svg";
import addIcon from "../images/icons/icon_add-01.svg";
import boyHoldingPhone from "../images/misc/boy_holding_phone-01.svg";
import manSitting from "../images/misc/man_sitting-01.svg";
import thumbBlank from "../images/misc/thumb_blank-01.svg";
import loopyArrow from "../images/icons/loopy_arrow-01.svg";
import emptyDashboardImage01 from "../images/icons/empty_dashboard_image-01.svg";
import emptyDashboardImage02 from "../images/icons/empty_dashboard_image-02.svg";

// other images
// manage packs
import manWithLetters from "../images/misc/man_with_letters-01.svg";
// change language
import girlHoldEarth from "../images/misc/girl_hold_earth-01.svg";
// settings
import elderlyMan from "../images/misc/elderly_man-01.svg";
// pack details
import iconTickBlack from "../images/icons/icon_tick_black-01.svg";
import iconTickWhite from "../images/icons/icon_tick-01.svg";
import iconTickYellowSmall from "../images/icons/icon_tick_yellow_small-01.svg";
import iconTickBlueSmall from "../images/icons/icon_tick_blue_small-01.svg";
import statsTeam from "../images/misc/stats_team-01.svg";
import imagePlaceholder from "../images/misc/main_placeholder-01.jpg";
import jaggedDivider from "../images/icons/jagged_divider-01.svg";

// lottie animations
// import lottieTest from "../images/animations/lottie_test-03.json";

// country flags
import frenchFlag from "../images/icons/icon_flag_french-01.svg";
import spanishFlag from "../images/icons/icon_flag_spanish-01.svg";
import italianFlag from "../images/icons/icon_flag_italian-01.svg";
import germanFlag from "../images/icons/icon_flag_german-01.svg";
import greekFlag from "../images/icons/icon_flag_greek-01.svg";

const Dashboard = ({
  displayPackManager,
  displaySettings,
  displayPackDetails,
  displayChangeLanguage,
  getPacks,
  content,
  user,
  display,
}) => {
  // more destructuring
  const {
    packManagerDisplay,
    packDetailsDisplay,
    settingsDisplay,
    changeLanguageDisplay,
  } = display;
  const {
    activePacks,
    progressId,
    currentPackProgress,
    currentLanguagePair,
    weeklyStats,
    lastPracticed,
    currentStreak,
    name,
  } = user;
  const { packs } = content;

  const screenDimVariants = {
    initial: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { type: "tween", duration: 0.2 },
    },
    exit: {
      opacity: 0,
      transition: { type: "tween", duration: 0.2 },
    },
  };

  const popUpVariants = {
    initial: {
      scale: 0,
    },
    visible: {
      scale: 1,
      transition: { type: "spring", stiffness: 150, mass: 0.5 },
    },
    exit: {
      scale: 0,
      transition: { type: "tween", duration: 0.3 },
    },
  };

  // this will get rid of 'english' at start of language pair
  const language = currentLanguagePair.match(/[A-Z][a-z]+/g);

  // state holds display info
  const [displayPacks, setDisplayPacks] = useState(null);
  const [packDetails, setPackDetails] = useState({ modified: false });
  const [pageLoaded, setPageLoaded] = useState(0);

  console.log(`loading.......${pageLoaded}`);

  const imagesObject = {
    speaksyLogo,
    settingsDots,
    addIcon,
    boyHoldingPhone,
    manSitting,
    manWithLetters,
    girlHoldEarth,
    elderlyMan,
    iconTickBlack,
    iconTickWhite,
    iconTickYellowSmall,
    iconTickBlueSmall,
    statsTeam,
    imagePlaceholder,
    frenchFlag,
    spanishFlag,
    italianFlag,
    germanFlag,
    greekFlag,
    thumbBlank,
    loopyArrow,
    emptyDashboardImage01,
    emptyDashboardImage02,
    jaggedDivider,
  };

  // preload images
  useEffect(() => {
    const imagesLoading = [];
    const imagesLoaded = [];

    for (const [key, value] of Object.entries(imagesObject)) {
      // add key into imagesLoading array
      imagesLoading.push(key);

      const img = new Image();
      img.src = value;
      window[key] = img;

      img.onload = () => {
        // push key into imagesLoaded array
        imagesLoaded.push(key);

        // set page loaded percentage
        setPageLoaded(
          Math.round((imagesLoaded.length / imagesLoading.length) * 100)
        );

        // if (imagesLoaded.length === imagesLoading.length) {
        //   console.log("PAGE LOADED.......................");
        // }
      };

      // console.log({
      //   [key]: img.src,
      // });
    }
  }, [setPageLoaded]);

  // lottie setup
  // const lottieRef = useRef();
  // lottieRef.current.useSubFrames();
  // const interactivity = {
  // 	useSubFrames: false,
  //   };

  // const playTestSound = () => {
  // 	const testAudioUrl = 'https://firebasestorage.googleapis.com/v0/b/react-file-upload-bf25c.appspot.com/o/englishFrench%2Faudio%2F5f4a0c09ad874a8523106a3f%2FRzJP1599205321863%2Fideally_they_would_be_open_to_getting_a_dog-pete2.mp3?alt=media&token=dd6134ac-e125-4a01-a8cf-72392f3dae2f';
  // 	const audioFile = new Audio(testAudioUrl);
  // 	audioFile.play();
  // }

  // const style = {
  // 	position: 'absolute',
  // 	height: '100vh',
  // 	width: '100vw',
  // 	zIndex: 20
  // };

  // function to return country flag
  const countryFlag = () => {
    // include if statement because currentLanguagePair might not be loaded?
    if (currentLanguagePair) {
      switch (currentLanguagePair) {
        case "englishFrench":
          return frenchFlag;
        case "englishSpanish":
          return spanishFlag;
        case "englishGerman":
          return germanFlag;
        case "englishItalian":
          return italianFlag;
        case "englishGreek":
          return greekFlag;
        default:
          return null;
      }
    } else {
      // Need to change this later
      return germanFlag;
    }
  };

  // useEffect to initiate dashboard by getting packs
  // this only needs to run once
  useEffect(() => {
    console.log("--get packs");
    if (currentLanguagePair) {
      getPacks(currentLanguagePair);
    }
  }, [getPacks, currentLanguagePair]);

  // useEffect to update packDetails
  // modified value ensures we only call it once when button clicked
  useEffect(() => {
    if (packDetails.modified) {
      console.log("--pack details");

      const { _id, name, mainImage, description } = packDetails;
      displayPackDetails(_id, name, mainImage, description);

      // set modified to false so that this doesn't run again
      setPackDetails({ ...packDetails, modified: false });
    }
  }, [
    packDetails,
    displayPackDetails,
    progressId,
    currentPackProgress,
    currentLanguagePair,
  ]);

  // ERROR ON LINE 100 --  if (Object.keys(weeklyStats).length !== 0) {

  // useEffect to test stuff for the weekly stats
  // useEffect(() => {
  //   if (Object.keys(weeklyStats).length !== 0) {
  //     const reducer = (accumulator, currentValue) => accumulator + currentValue;
  //     console.log(
  //       "___ learning points this week: " + weeklyStats.lp.reduce(reducer)
  //     );
  //     console.log(
  //       "___ consolidation points this week: " + weeklyStats.cp.reduce(reducer)
  //     );
  //     console.log("___ time this week: " + weeklyStats.t.reduce(reducer));
  //   }

  //   const oneDay = 1000 * 60 * 60 * 24;
  //   const streak = lastPracticed - currentStreak;

  //   // const streakDate = new Date(currentStreak);
  //   // console.log('___ streak date is: ' + streakDate)
  //   console.log("___ streak is: " + Math.floor(streak / oneDay));
  // }, [currentStreak, lastPracticed, weeklyStats]);

  // useEffect to update displayPacks state
  useEffect(() => {
    if (packs) {
      console.log("--dashboard");

      // !! if this isn't defined here and is instead added to dependencies it creates recursion for some reason
      const popUpVariants = {
        initial: {
          scale: 0,
        },
        visible: {
          scale: 1,
          transition: { type: "spring", stiffness: 40, mass: 0.5 },
        },
        exit: {
          scale: 0,
          transition: { type: "tween", duration: 0.3 },
        },
      };

      // create activePacksContent array
      const activePacksContent = [];
      for (let i = 0; i < activePacks.length; i++) {
        for (let k = 0; k < packs.length; k++) {
          if (packs[k]._id === activePacks[i]._id) {
            activePacksContent.push(packs[k]);
            break;
          }
        }
      }

      const date = new Date();
      const today = date.getDay();

      let timePracticedToday = 0;

      // this was causing an error so there are multiple if statements
      if (weeklyStats) {
        if (weeklyStats.t) {
          if (weeklyStats.t[today]) {
            timePracticedToday = weeklyStats.t[today];
          }
        }
      }

      // calculate streak
      const oneDay = 1000 * 60 * 60 * 24;
      const streakMillis = lastPracticed - currentStreak;
      const streak = Math.floor(streakMillis / oneDay);

      // this array will hold all of our formatted packs
      let packsFormat = [];

      for (let i = 0; i < activePacksContent.length; i++) {
        // check item timestamps
        const currentTime = Date.now();
        let itemsReady_S = 0;
        let itemsReady_L = 0;
        if (activePacks[i].timestamps_S) {
          for (let j = 0; j < activePacks[i].timestamps_S.length; j++) {
            if (activePacks[i].timestamps_S[j] < currentTime) {
              itemsReady_S++;
            }
          }
        }
        if (activePacks[i].timestamps_L) {
          for (let j = 0; j < activePacks[i].timestamps_L.length; j++) {
            if (activePacks[i].timestamps_L[j] < currentTime) {
              itemsReady_L++;
            }
          }
        }

        let learningAmount;
        let consolidationAmount;
        let type = "speaking";

        if (activePacks[i].lastType) {
          if (activePacks[i].lastType === "speaking") {
            learningAmount = activePacks[i].learning_S;
            consolidationAmount = activePacks[i].consolidation_S;
            type = "speaking";
          }
          if (activePacks[i].lastType === "listening") {
            learningAmount = activePacks[i].learning_L;
            consolidationAmount = activePacks[i].consolidation_L;
            type = "listening";
          }
        } else {
          // TEMP TO BE DELETED LATER
          learningAmount = activePacks[i].count_S;
          consolidationAmount = activePacks[i].count_S;
        }

        // TEMP FIX WHILE TRANSITIONING TO OBJECTS
        let thumbnail;

        if (typeof activePacksContent[i].thumbnail === "object") {
          // if there's no url - there SHOULD be an url though...
          if (!activePacksContent[i].thumbnail) {
            // then just set the thumb to thumbBlank
            thumbnail = thumbBlank;
          } else {
            thumbnail = activePacksContent[i].thumbnail.url;
          }
        } else {
          thumbnail = activePacksContent[i].thumbnail;
        }

        packsFormat.push(
          <PackContainer
            variants={popUpVariants}
            initial="initial"
            animate="visible"
            exit="exit"
            onClick={() => {
              setPackDetails({
                _id: activePacksContent[i]._id,
                name: activePacksContent[i].name,
                mainImage:
                  typeof activePacksContent[i].mainImage === "object"
                    ? activePacksContent[i].mainImage
                      ? activePacksContent[i].mainImage.url
                      : thumbBlank
                    : activePacksContent[i].mainImage,

                // ^^^ the mainImage has a messy little fix incase there is no mainImage supplied

                description: activePacksContent[i].description,
                modified: true,
              });
            }}
            key={"pack" + activePacksContent[i]._id}
          >
            <PackFrameContainer>
              <PackItemsReadyContainer>
                {itemsReady_S ? (
                  <PackItemsReadyMotion
                    initial={{
                      scale: 0,
                    }}
                    animate={{
                      scale: 1,
                    }}
                    transition={{
                      type: "spring",
                      stiffness: 150,
                      mass: 0.5,
                      delay: 1 + i * 0.1,
                    }}
                  >
                    {itemsReady_S}
                  </PackItemsReadyMotion>
                ) : null}

                {itemsReady_S && itemsReady_L ? (
                  <HorzSpacer width=".5rem" />
                ) : null}

                {itemsReady_L ? (
                  <PackItemsReadyMotion
                    background={blue_2}
                    color="white"
                    initial={{
                      scale: 0,
                    }}
                    animate={{
                      scale: 1,
                    }}
                    transition={{
                      type: "spring",
                      stiffness: 150,
                      mass: 0.5,
                      delay: 1 + i * 0.1,
                    }}
                  >
                    {itemsReady_L}
                  </PackItemsReadyMotion>
                ) : null}
              </PackItemsReadyContainer>
              <Thumbnail src={thumbnail} thumbBlank={thumbBlank} />

              <div
                style={{
                  margin: "1% 1.2rem 1% 1.2rem",
                  position: "relative",
                  top: "-1rem",
                }}
              >
                <CompletionBar
                  type={type}
                  strokeWidth=".25rem"
                  background="light"
                  learning={learningAmount}
                  consolidating={consolidationAmount}
                />
              </div>
            </PackFrameContainer>
          </PackContainer>
        );
      }

      // test lottie animation
      // const lottieAnim = (
      //   <React.Fragment key="test-stuff">
      //     <Lottie key="lottie-test" animationData={lottieTest} />
      //   </React.Fragment>
      // );
      // packsFormat.push(lottieAnim);

      // add in streak and time practiced
      if (streak > 0) {
        const streakDisplay = (
          <React.Fragment key="streak-display">
            <StatsContainerMain>
              <StatsContainer>
                <StatsContainerText>
                  <StatsText>
                    Current run streak <br />
                    <StatsNumber>{streak} days</StatsNumber>
                  </StatsText>
                </StatsContainerText>
              </StatsContainer>

              <StatsContainer>
                <StatsContainerImage>
                  <Img src={boyHoldingPhone} width="50%" top="-2%" />
                </StatsContainerImage>
              </StatsContainer>
            </StatsContainerMain>
          </React.Fragment>
        );
        packsFormat.unshift(streakDisplay);
      }

      // const timePracticedDisplay = (
      //   <React.Fragment key="time-practiced">
      //     <StatsContainer>
      //       <StatsText>
      //         Time practiced this week <br />
      //         <StatsNumber>{formatTime(timePracticed, false)}</StatsNumber>
      //       </StatsText>
      //       <Img src={manSitting} width="58%" />
      //     </StatsContainer>
      //   </React.Fragment>
      // );

      const timePracticedTodayDisplay = (
        <React.Fragment key="time-practiced">
          <StatsContainerMain>
            <StatsContainer>
              <StatsContainerText>
                <StatsText>
                  Time practiced today <br />
                  <StatsNumber>
                    {formatTime(timePracticedToday, false)}
                  </StatsNumber>
                </StatsText>
              </StatsContainerText>
            </StatsContainer>

            <StatsContainer>
              <StatsContainerImage>
                <Img src={manSitting} width="58%" />
              </StatsContainerImage>
            </StatsContainer>
          </StatsContainerMain>
        </React.Fragment>
      );

      if (timePracticedToday > 0) {
        packsFormat.push(timePracticedTodayDisplay);
      }

      setDisplayPacks(packsFormat);
    } else {
      setDisplayPacks("loading content");
    }
  }, [
    packs,
    activePacks,
    displayPackManager,
    currentStreak,
    lastPracticed,
    weeklyStats,
  ]);

  return (
    <React.Fragment>
      {/* <Lottie style={style} animationData={lottieTest} /> */}

      {pageLoaded === 100 ? (
        <React.Fragment>
          {/* Pack details window */}
          <AnimatePresence>
            {packDetailsDisplay.display ? (
              <DisplayDim
                variants={screenDimVariants}
                initial="initial"
                animate="visible"
                exit="exit"
                onClick={() => displayPackDetails(null)}
              />
            ) : null}
          </AnimatePresence>
          {packDetailsDisplay.loaded ? (
            <PackDetails images={imagesObject} />
          ) : null}

          {/* Pack manager window */}
          <AnimatePresence>
            {packManagerDisplay ? (
              <DisplayDim
                variants={screenDimVariants}
                initial="initial"
                animate="visible"
                exit="exit"
                onClick={() => displayPackManager(false)}
              />
            ) : null}
          </AnimatePresence>
          {packs && activePacks ? <ManagePacks images={imagesObject} /> : null}

          {/* Settings window */}
          <AnimatePresence>
            {settingsDisplay ? (
              <DisplayDim
                variants={screenDimVariants}
                initial="initial"
                animate="visible"
                exit="exit"
                onClick={() => displaySettings(false)}
              />
            ) : null}
          </AnimatePresence>
          <Settings images={imagesObject} />

          {/* Change language window */}
          <AnimatePresence>
            {changeLanguageDisplay ? (
              <DisplayDim
                variants={screenDimVariants}
                initial="initial"
                animate="visible"
                exit="exit"
                onClick={() => displayChangeLanguage(false)}
              />
            ) : null}
          </AnimatePresence>
          <ChangeLanguage images={imagesObject} />

          <DashboardContainer alignItems="flex-start" alignContent="flex-start">
            <TopBarContainer>
              <TopBarDiv>
                <Img src={speaksyLogo} width="10rem" />
              </TopBarDiv>
              <TopBarDiv>
                {/* add packs */}
                <SettingsButton onClick={() => displayPackManager(true)}>
                  <AnimatePresence exitBeforeEnter>
                    <Img
                      src={addIcon}
                      key={"add-packs"}
                      width="1.6rem"
                      variants={popUpVariants}
                      initial="initial"
                      animate="visible"
                      exit="exit"
                    />
                  </AnimatePresence>
                </SettingsButton>

                {/* loopy arrow when there are no packs */}
                {activePacks.length === 0 ? (
                  <div
                    style={{
                      width: "0px",
                      height: "0px",
                      position: "relative",
                      left: "-150px",
                      top: "25px",
                    }}
                  >
                    <Img src={loopyArrow} width="8rem" height="8rem" />
                  </div>
                ) : null}

                <HorzSpacer width=".7rem" />
                {/* country flag */}
                <SettingsButton onClick={() => displayChangeLanguage(true)}>
                  <AnimatePresence exitBeforeEnter>
                    <Img
                      src={countryFlag()}
                      key={countryFlag()}
                      width="3rem"
                      variants={popUpVariants}
                      initial="initial"
                      animate="visible"
                      exit="exit"
                    />
                  </AnimatePresence>
                </SettingsButton>
                <HorzSpacer />
                <SettingsButton onClick={() => displaySettings(true)}>
                  <Img src={settingsDots} width="2.6rem" />
                </SettingsButton>
              </TopBarDiv>
            </TopBarContainer>

            {/* this is where the packs are displayed */}
            {activePacks.length > 0 ? (
              <ContentContainer>
                <AnimatePresence>{displayPacks}</AnimatePresence>
              </ContentContainer>
            ) : (
              <EmptyContentContainer>
                <div
                  style={{
                    maxWidth: "550px",
                    textAlign: "center",
                  }}
                >
                  <Img src={emptyDashboardImage01} width="270px" />
                  <DashboardEmptyText>
                    Hi {name}, this is your home screen. To start speaking{" "}
                    {language} you first need to add some language packs.
                  </DashboardEmptyText>

                  <DashboardEmptyText>
                    Click on the plus icon above to add some now!
                  </DashboardEmptyText>

                  <Img src={emptyDashboardImage02} width="270px" />
                </div>
              </EmptyContentContainer>
            )}

            <Spacer height="10rem" />
          </DashboardContainer>
        </React.Fragment>
      ) : (
        <SiteContainer>
          <div
            style={{
              margin: "0 7% 0 7%",
              position: "relative",
              top: "-1rem",
              width: "100%",
              height: "2rem",
            }}
          >
            <CompletionBar
              type="loading"
              strokeWidth=".1rem"
              background="dashboard"
              learning={pageLoaded}
              consolidating="0"
            />
          </div>
        </SiteContainer>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ content, user, display }) => {
  return { content, user, display };
};

export default connect(mapStateToProps, {
  signoutUser,
  getPacks,
  displayPackManager,
  displaySettings,
  displayPackDetails,
  displayChangeLanguage,
})(withRouter(Dashboard));
