import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { AnimatePresence } from "framer-motion";

import {
  ManagePacksPrompt,
  LangPacksTitle,
  LangPacksBodyTitle,
  LangPacksRemove,
  LangPacksAdd,
  LangPacksBody,
  PackManagerContainer,
  PackManagerContentContainer,
  PackManagerButtonContainer,
  CompletionBarContainer,
  Img,
  Spacer,
  PackManagerItemsReadyContainer,
  PackItemsReadyManagerDisplay,
  HorzSpacer,
  CenterContentContainer,
} from "./styles";

import { addActivePack, addInactivePack } from "../actions";

import iconCross from "../images/icons/icon_cross_white-01.svg";
import iconAddThin from "../images/icons/icon_add_thin_blue-01.svg";
// import manWithLetters from "../images/misc/man_with_letters-01.svg";

const ManagePacks = ({
  content,
  user,
  display,
  addActivePack,
  addInactivePack,
  images,
}) => {
  const { packs } = content;
  const { activePacks, inactivePacks, userid, currentLanguagePair } = user;
  const { packManagerDisplay } = display;

  const displayPromptVariants = {
    initial: {
      y: "100vh",
      originX: "10.5rem",
      x: "50vw",
    },
    visible: {
      y: "10vh",
      transition: { type: "spring", stiffness: 80, mass: 0.5 },
    },
    exit: {
      y: "100vh",
      transition: { type: "spring", stiffness: 80, mass: 0.5 },
    },
  };

  const [displayPacks, setDisplayPacks] = useState(null);

  // use effect to display packs
  useEffect(() => {
    console.log("--manage packs");
    const activePacksContent = [];
    const inactivePacksContent = [];

    // MIGHT BE ABLE TO CLEAN UP THESE FOR LOOPS WITH FILTER

    // create activePacksContent array
    for (let i = 0; i < activePacks.length; i++) {
      for (let k = 0; k < packs.length; k++) {
        if (packs[k]._id === activePacks[i]._id) {
          activePacksContent.push(packs[k]);
          break;
        }
      }
    }

    // create inactivePacksContent array
    for (let i = 0; i < inactivePacks.length; i++) {
      for (let k = 0; k < packs.length; k++) {
        if (packs[k]._id === inactivePacks[i]._id) {
          inactivePacksContent.push(packs[k]);
          break;
        }
      }
    }

    // create uninitiatedPacksContent array
    const initiatedPacks = activePacks.concat(inactivePacks);

    const uninitiatedPacksContent = packs.filter(function (packs_el) {
      return (
        initiatedPacks.filter(function (initiated_el) {
          return initiated_el._id === packs_el._id;
        }).length === 0
      );
    });

    //
    const buttonConfig = (
      type,
      item,
      userid,
      activePacks,
      inactivePacks,
      currentLanguagePair
    ) => {
      if (type === "add") {
        // limit the amount of packs users can active at any one time to 5
        return activePacks.length < 5 ? (
          <LangPacksAdd
            onClick={() =>
              addActivePack(
                userid,
                item._id,
                activePacks,
                inactivePacks,
                currentLanguagePair,
                item.levels
              )
            }
          >
            <Img src={iconAddThin} width="1rem" top=".1rem" />
          </LangPacksAdd>
        ) : null;
      }
      if (type === "remove") {
        return (
          <LangPacksRemove
            onClick={() =>
              addInactivePack(
                userid,
                item._id,
                activePacks,
                inactivePacks,
                currentLanguagePair
              )
            }
          >
            <Img src={iconCross} width="1rem" top=".1rem" />
          </LangPacksRemove>
        );
      }
    };

    const packItem = (item, type, index) => {
      let background = null;
      let fontColor = null;
      let barBackgroundColor = null;
      let barColor;
      let barAmount;

      // check item timestamps
      const currentTime = Date.now();
      let itemsReady = 0;

      // inactive || uninitiated packs
      if (type === "add") {
        // check if there's any item timestamps!
        // check to make sure it's not an uninitiated pack
        // uninitiated packs don't have indexes

        // checks if there are items in inactivePacks
        if (inactivePacks[index]) {
          if (inactivePacks[index].timestamps_S && index !== null) {
            for (let j = 0; j < inactivePacks[index].timestamps_S.length; j++) {
              if (inactivePacks[index].timestamps_S[j] < currentTime) {
                itemsReady++;
              }
            }
          }

          background = "white";
          // background bar colour
          barBackgroundColor = inactivePacks[index].completed_S
            ? "#39EA5F"
            : "#F1F1F1";

          // completion bar colour
          barColor =
            // need to specify null here otherwise 0 returns falsey
            index !== null
              ? inactivePacks[index].count_S
                ? inactivePacks[index].completed_S
                  ? "#E7DB03"
                  : "#39EA5F"
                : "none"
              : "none";

          // completion bar amount
          barAmount = inactivePacks[index].count_S
            ? inactivePacks[index].count_S > 50
              ? inactivePacks[index].count_S - 3
              : inactivePacks[index].count_S + 3
            : 0;
        } else {
          background = "white";
          barBackgroundColor = "#F1F1F1";
          barColor = "none";
        }
      }

      // active packs
      if (type === "remove") {
        // check if there's any item timestamps!

        // checks if there are any items in activePacksRef
        if (activePacks[index]) {
          if (activePacks[index].timestamps_S) {
            for (let j = 0; j < activePacks[index].timestamps_S.length; j++) {
              if (activePacks[index].timestamps_S[j] < currentTime) {
                itemsReady++;
              }
            }
          }

          fontColor = "white";
          // background bar colour
          barBackgroundColor = activePacks[index].completed_S
            ? "#39EA5F"
            : "#1D87CE";

          // completion bar colour
          barColor =
            // need to specify null here otherwise 0 returns falsey
            index !== null
              ? activePacks[index].count_S
                ? activePacks[index].completed_S
                  ? "#E7DB03"
                  : "#39EA5F"
                : "none"
              : "none";

          // completion bar amount
          barAmount = activePacks[index].count_S
            ? activePacks[index].count_S > 50
              ? activePacks[index].count_S - 3
              : activePacks[index].count_S + 3
            : 0;
        } else {
          fontColor = "white";
          barBackgroundColor = "#1D87CE";
          barColor = "none";
        }
      }

      return (
        <PackManagerContainer
          background={background}
          key={type + "_" + item._id}
        >
          {itemsReady ? (
            <React.Fragment>
              <PackManagerItemsReadyContainer>
                <PackItemsReadyManagerDisplay>
                  {itemsReady}
                </PackItemsReadyManagerDisplay>
              </PackManagerItemsReadyContainer>
              <HorzSpacer width=".5rem" />
            </React.Fragment>
          ) : null}
          <PackManagerContentContainer>
            <LangPacksTitle color={fontColor}>{item.name}</LangPacksTitle>
            <div
              style={{
                height: "1rem",
                width: "100%",
                position: "relative",
                top: ".1rem",
              }}
            >
              <CompletionBarContainer>
                <svg viewBox="0 0 100 5" xmlns="http://www.w3.org/2000/svg">
                  <line
                    x1="2"
                    y1="2"
                    x2="97"
                    y2="2"
                    stroke={barBackgroundColor}
                    strokeWidth=".16rem"
                    strokeLinecap="round"
                  />
                  <line
                    x1="2"
                    y1="2"
                    x2={barAmount}
                    y2="2"
                    stroke={barColor}
                    strokeWidth=".16rem"
                    strokeLinecap="round"
                  />
                </svg>
              </CompletionBarContainer>
            </div>
          </PackManagerContentContainer>
          <PackManagerButtonContainer>
            {buttonConfig(
              type,
              item,
              userid,
              activePacks,
              inactivePacks,
              currentLanguagePair
            )}
          </PackManagerButtonContainer>
        </PackManagerContainer>
      );
    };

    let display = [];

    let activePacksFormat = [];
    for (let i = 0; i < activePacksContent.length; i++) {
      activePacksFormat.push(packItem(activePacksContent[i], "remove", i));
    }

    // if there are active packs then include the active packs title
    if (activePacksFormat.length > 0) {
      activePacksFormat.unshift(
        <CenterContentContainer height="auto" key="active-packs-title">
          <LangPacksBodyTitle>Active Packs</LangPacksBodyTitle>
        </CenterContentContainer>
      );
    }

    let inactivePacksFormat = [];
    for (let i = 0; i < inactivePacksContent.length; i++) {
      inactivePacksFormat.push(packItem(inactivePacksContent[i], "add", i));
    }

    const uninitiatedPacksFormat = uninitiatedPacksContent.map((item) => {
      return packItem(item, "add", null);
    });

    const activePacksDivider = (
      <React.Fragment key="text-prompt">
        <CenterContentContainer height="auto">
          {activePacks.length < 5 ? (
            activePacks.length === 0 ? (
              <LangPacksBody> Add your first language pack</LangPacksBody>
            ) : (
              <LangPacksBody> Add more language packs</LangPacksBody>
            )
          ) : (
            <LangPacksBody>
              You can only have 5 language packs active at a time
            </LangPacksBody>
          )}
        </CenterContentContainer>
        <CenterContentContainer height="auto">
          {/* if there aren't any active packs then don't show Inactive Packs title */}
          {activePacks.length > 0 ? (
            <LangPacksBodyTitle>Inactive Packs </LangPacksBodyTitle>
          ) : null}
        </CenterContentContainer>
      </React.Fragment>
    );

    const topSpacer = <Spacer key="spacer-top" height="1rem" />;

    const topContent = (
      <React.Fragment key="content-top">
        <Spacer height="2rem" />
        <CenterContentContainer height="auto">
          <Img src={images.manWithLetters} width="80%" />
        </CenterContentContainer>
      </React.Fragment>
    );

    display.push(topContent);
    display.push(topSpacer);
    display.push(activePacksFormat);
    display.push(activePacksDivider);
    display.push(inactivePacksFormat);
    display.push(uninitiatedPacksFormat);
    display.push(<Spacer height="10rem" key="end_spacer" />);

    setDisplayPacks(display);
  }, [
    activePacks,
    inactivePacks,
    packs,
    currentLanguagePair,
    addActivePack,
    addInactivePack,
    userid,
  ]);

  return (
    <AnimatePresence>
      {packManagerDisplay ? (
        <ManagePacksPrompt
          variants={displayPromptVariants}
          initial="initial"
          animate="visible"
          exit="exit"
        >
          {displayPacks}
        </ManagePacksPrompt>
      ) : null}
    </AnimatePresence>
  );
};

const mapStateToProps = ({ content, user, display }) => {
  return { content, user, display };
};

export default connect(mapStateToProps, { addActivePack, addInactivePack })(
  ManagePacks
);
