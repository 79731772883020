import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  SiteContainer,
  LangSelectContainer,
  Img,
  LangSelectHeader,
  Spacer,
  HorzSpacer,
  LangSelectButton,
  LangSelectButtonSubmit,
} from "./styles";
import { setLanguagePair } from "../actions";
import groupOfPeople from "../images/sessionFinish/group_of_people-01.svg";

const LanguageSelect = ({ user, setLanguagePair, history }) => {
  const { userid } = user;
  const [language, setLanguage] = useState(null);

  const selectButton = (name) => {
    if (name === language) {
      return (
        <LangSelectButton
          selectColor="#39EA5F"
          onClick={() => setLanguage(name)}
        >
          {name}
        </LangSelectButton>
      );
    } else {
      return (
        <LangSelectButton onClick={() => setLanguage(name)}>
          {name}
        </LangSelectButton>
      );
    }
  };

  const submitButton = () => {
    switch (language) {
      case "French":
        return (
          <LangSelectButtonSubmit
            onClick={() => setLanguagePair(userid, "englishFrench", history)}
          >
            C'est Parti! (Let's go!)
          </LangSelectButtonSubmit>
        );
      case "Italian":
        return (
          <LangSelectButtonSubmit
            onClick={() => setLanguagePair(userid, "englishItalian", history)}
          >
            Andiamo! (Let's go!)
          </LangSelectButtonSubmit>
        );
      case "Spanish":
        return (
          <LangSelectButtonSubmit
            onClick={() => setLanguagePair(userid, "englishSpanish", history)}
          >
            Vámonos! (Let's go!)
          </LangSelectButtonSubmit>
        );
      case "German":
        return (
          <LangSelectButtonSubmit
            onClick={() => setLanguagePair(userid, "englishGerman", history)}
          >
            Los geht's! (Let's go!)
          </LangSelectButtonSubmit>
        );
      case "Greek":
        return (
          <LangSelectButtonSubmit
            onClick={() => setLanguagePair(userid, "englishGreek", history)}
          >
            Πάμε! (Let's go!)
          </LangSelectButtonSubmit>
        );
      default:
        return null;
    }
  };

  return (
    <SiteContainer>
      <LangSelectContainer>
        <Img src={groupOfPeople} width="100%" />
        <Spacer />
        <LangSelectHeader>
          Welcome to Speaksy!
          <br />
          Which language would you like to start speaking first?
        </LangSelectHeader>
        <Spacer height="1rem" />

        {selectButton("French")}
        <HorzSpacer width=".6rem" />
        {selectButton("Italian")}
        <HorzSpacer width=".6rem" />
        {selectButton("Spanish")}
        <HorzSpacer width=".6rem" />
        {selectButton("German")}
        <HorzSpacer width=".6rem" />
        {selectButton("Greek")}
        <Spacer />
        {submitButton()}
        <Spacer height="1.6rem" />
      </LangSelectContainer>
    </SiteContainer>
  );
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, { setLanguagePair })(
  withRouter(LanguageSelect)
);
