import React from "react";
import { connect } from "react-redux";
import { AnimatePresence } from "framer-motion";

import {
  Img,
  SettingsPrompt,
  Spacer,
  SignOutButton,
  SettingsText,
  SettingsTextBody,
  green_1,
  blue_1,
  blue_2,
  blue_1_lighter,
} from "./styles";

import { formatTime } from "./formatTime";

import { signoutUser } from "../actions";

const Settings = ({ display, user, signoutUser, images }) => {
  const { settingsDisplay } = display;
  const {
    name,
    currentLanguagePair,
    lastPracticed,
    timePracticed,
    learningPoints,
    consolidationPoints,
  } = user;

  // this will get rid of 'english' at start of language pair
  const language = currentLanguagePair.match(/[A-Z][a-z]+/g);

  // function to return last practised time
  const lastPracticedFormat = () => {
    const currentTime = Date.now();
    const oneWeek = 1000 * 60 * 60 * 24 * 7;

    // if it's been less than a week since the last practise
    if (currentTime - lastPracticed < oneWeek) {
      const currentDay = new Date(currentTime).getDay();
      const lastPracticedDay = new Date(lastPracticed).getDay();

      // if it's the same day then it's today!
      if (currentDay === lastPracticedDay) {
        return "today";
      }

      // if the day number is -1 or +6 then it's yesterday!
      if (
        currentDay === lastPracticedDay - 1 ||
        currentDay === lastPracticedDay + 6
      ) {
        return "yesterday";
      } else {
        // otherwise just say what day of the week it was

        switch (lastPracticedDay) {
          case 0:
            return "on Sunday";
          case 1:
            return "on Monday";
          case 2:
            return "on Tuesday";
          case 3:
            return "on Wednesday";
          case 4:
            return "on Thursday";
          case 5:
            return "on Friday";
          case 6:
            return "on Saturday";
          default:
            return "sometime this week";
        }
      }
    } else {
      // otherwise if it's been longer than a week then return the date

      // so I need to return the day in number form
      const lastPracticedDate = new Date(lastPracticed).getDate();

      // and the month
      const lastPracticedMonth = new Date(lastPracticed).getMonth();
      let month = "";

      switch (lastPracticedMonth) {
        case 0:
          month = "January";
          break;
        case 1:
          month = "February";
          break;
        case 2:
          month = "March";
          break;
        case 3:
          month = "April";
          break;
        case 4:
          month = "May";
          break;
        case 5:
          month = "June";
          break;
        case 6:
          month = "July";
          break;
        case 7:
          month = "August";
          break;
        case 8:
          month = "September";
          break;
        case 9:
          month = "October";
          break;
        case 10:
          month = "November";
          break;
        case 11:
          month = "December";
          break;
        default:
          return "not sure which month";
      }

      return lastPracticedDate + " " + month;
    }
  };

  // format time practiced
  const totalTime = formatTime(timePracticed, false);

  // calculate total practise sessions
  const practiseSessions = Math.round(
    (learningPoints + consolidationPoints) / 10
  );

  const displayPromptVariants = {
    initial: {
      y: "100vh",
      originX: "10.5rem",
      x: "50vw",
    },
    visible: {
      y: "10vh",
      transition: { type: "spring", stiffness: 80, mass: 0.5 },
    },
    exit: {
      y: "100vh",
      transition: { type: "spring", stiffness: 80, mass: 0.5 },
    },
  };

  return (
    <AnimatePresence>
      {settingsDisplay ? (
        <SettingsPrompt
          variants={displayPromptVariants}
          initial="initial"
          animate="visible"
          exit="exit"
        >
          <Spacer height="3rem" />
          <Img src={images.elderlyMan} width="17rem" />
          <Spacer height="1rem" />
          <SettingsTextBody>
            {lastPracticed ? (
              <React.Fragment>
                Hi <span style={{ color: blue_1_lighter }}>{name}</span>, the
                last time you practiced{" "}
                <span style={{ color: blue_1_lighter }}>{language}</span> was{" "}
                <span style={{ color: blue_1_lighter }}>
                  {lastPracticedFormat()}
                </span>
                . You've practiced a total of{" "}
                <span style={{ color: blue_1_lighter }}>{totalTime}</span> and
                completed{" "}
                <span style={{ color: blue_1_lighter }}>
                  {practiseSessions}
                </span>{" "}
                practise sessions.
                <span style={{ color: blue_1_lighter }}> Keep it up!</span>
              </React.Fragment>
            ) : (
              <React.Fragment>
                Hi <span style={{ color: blue_1_lighter }}>{name}</span>, you've
                just started learning{" "}
                <span style={{ color: blue_1_lighter }}>{language}</span>. Here
                you can keep track of the last time you practiced, your total
                practise time and the number of practise sessions you've
                completed.
              </React.Fragment>
            )}
          </SettingsTextBody>
          <Spacer height="1.5rem" />
          <SignOutButton onClick={() => signoutUser()}>Sign out</SignOutButton>
          <Spacer height="1rem" />
          <SettingsText>Speaksy alpha (0.34)</SettingsText>
        </SettingsPrompt>
      ) : null}
    </AnimatePresence>
  );
};

const mapStateToProps = ({ display, user }) => {
  return { display, user };
};

export default connect(mapStateToProps, { signoutUser })(Settings);
