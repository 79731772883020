import {
	SIGNIN_USER,
	SIGNUP_USER,
	FETCH_USER,
	SIGNOUT_USER,
	GOOGLE_SIGNIN,
	SET_LANGUAGE_PAIR,
	UPDATE_PACKS,
	GET_PACK_PROGRESS,
	UPDATE_USER_STATS
} from '../actions/types';

export default function(
	state = { userid: null, signedin: false, currentLanguagePair: null, currentPackProgress: [] },
	action
) {
	switch (action.type) {
		case SIGNUP_USER:
			return action.payload || { userid: null, signedin: false, currentLanguagePair: null };
		case SIGNIN_USER:
			return action.payload || { userid: null, signedin: false, currentLanguagePair: null };
		case FETCH_USER:
			return action.payload || { userid: null, signedin: false, currentLanguagePair: null };
		case SIGNOUT_USER:
			return action.payload || { userid: null, signedin: false, currentLanguagePair: null };
		case GOOGLE_SIGNIN:
			return action.payload || { userid: null, signedin: false, currentLanguagePair: null };
		case SET_LANGUAGE_PAIR:
			return { ...state, ...action.payload };
		case UPDATE_PACKS:
			return { ...state, ...action.payload };
		case GET_PACK_PROGRESS:
			return { ...state, currentPackProgress: action.payload };
		case UPDATE_USER_STATS:
			return { ...state, ...action.payload };
		default:
			return state;
	}
}
