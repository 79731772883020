import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { AnimatePresence } from "framer-motion";

import {
  Img,
  SettingsPrompt,
  Spacer,
  ChangeLangBody,
  ChangeLangButton,
  ChangeLangNew,
  ChangeLangButtonSave,
  ChangeLangBase,
  ChangeLangTarget,
  EmptyContainer,
} from "./styles";

import { changeLanguagePair, displayChangeLanguage } from "../actions";

// import girlHoldEarth from '../images/misc/girl_hold_earth-01.svg';

const ChangeLanguage = ({
  user,
  display,
  changeLanguagePair,
  displayChangeLanguage,
  images,
}) => {
  const { userid, currentLanguagePair } = user;
  const { changeLanguageDisplay } = display;

  const displayPromptVariants = {
    initial: {
      y: "100vh",
      originX: "10.5rem",
      x: "50vw",
    },
    visible: {
      y: "10vh",
      transition: { type: "spring", stiffness: 80, mass: 0.5 },
    },
    exit: {
      y: "100vh",
      transition: { type: "spring", stiffness: 80, mass: 0.5 },
    },
  };

  // state for newLanguage and targetLanguageButtons
  const [newLanguage, setNewLanguage] = useState(null);
  const [saved, setSaved] = useState(false);
  const [applyButton, setApplyButton] = useState(false);

  const popUpVariants = {
    initial: {
      scale: 0,
    },
    visible: {
      scale: 1,
      transition: { type: "spring", stiffness: 150, mass: 0.5 },
    },
    exit: {
      scale: 0,
      transition: { type: "tween", duration: 0.2 },
    },
  };

  // split currentLanguagePair
  const languages = currentLanguagePair.split(/(?=[A-Z])/);

  // new language pair
  let newLanguagePair;
  if (newLanguage) {
    newLanguagePair = languages[0] + newLanguage;
  }

  // title case
  const baseLanguage = languages[0][0].toUpperCase() + languages[0].slice(1);
  const targetLanguage = languages[1];

  // target languages - in future might be nice to have these loaded dynamically
  const targetLanguageList = [
    "French",
    "Spanish",
    "Italian",
    "German",
    "Greek",
  ];

  // function to save changes
  const saveChanges = (userid, newLanguagePair) => {
    if (!saved) {
      changeLanguagePair(userid, newLanguagePair);
      setSaved(true);
    }
  };

  // useEffect sets newLanguage to null when currentLanguagePair is updated
  useEffect(() => {
    setNewLanguage(null);
  }, [currentLanguagePair]);

  // useEffect to hide window when saved
  useEffect(() => {
    if (saved) {
      setTimeout(() => {
        displayChangeLanguage(null);
        setSaved(false);
        setApplyButton(false);
      }, 1000);
    }
  }, [currentLanguagePair, saved, displayChangeLanguage]);

  // useEffect updates targetLanguageButtons
  const targetLanguageButtons = () => {
    // array stores targetLanguageButtons
    let arr = [];

    const buttonFunction = (targetLanguage) => {
      if (!targetLanguage) {
        setNewLanguage(null);
        setApplyButton(false);
        return;
      }

      setNewLanguage(targetLanguage);
      setApplyButton(true);
    };

    for (let i = 0; i < targetLanguageList.length; i++) {
      // if no newLanguage is set
      if (!newLanguage) {
        if (targetLanguageList[i] !== targetLanguage) {
          arr.push(
            <ChangeLangButton
              variants={popUpVariants}
              initial="initial"
              animate="visible"
              exit="exit"
              onClick={() => buttonFunction(targetLanguageList[i])}
              key={targetLanguageList[i]}
            >
              {targetLanguageList[i]}
            </ChangeLangButton>
          );
        }
        // if a newLanguage is set
      } else {
        if (
          targetLanguageList[i] !== newLanguage &&
          targetLanguageList[i] !== targetLanguage
        ) {
          arr.push(
            <ChangeLangButton
              variants={popUpVariants}
              initial="initial"
              animate="visible"
              exit="exit"
              onClick={() => buttonFunction(targetLanguageList[i])}
              key={targetLanguageList[i]}
            >
              {targetLanguageList[i]}
            </ChangeLangButton>
          );
        }
        // if the language is the current target language then setNewLanguage null
        if (targetLanguageList[i] === targetLanguage) {
          arr.push(
            <ChangeLangButton
              variants={popUpVariants}
              initial="initial"
              animate="visible"
              exit="exit"
              onClick={() => buttonFunction(null)}
              key={targetLanguageList[i]}
            >
              {targetLanguageList[i]}
            </ChangeLangButton>
          );
        }
      }
    }

    // return arr to display
    return arr;
  };

  return (
    <AnimatePresence>
      {changeLanguageDisplay ? (
        <SettingsPrompt
          variants={displayPromptVariants}
          initial="initial"
          animate="visible"
          exit="exit"
        >
          <Spacer height="1.5rem" />
          <Img
            src={images.girlHoldEarth}
            alt="Girl holding the earth"
            width="13rem"
          />

          {/* text */}
          <EmptyContainer>
            {newLanguage ? (
              <ChangeLangBody>New language pair</ChangeLangBody>
            ) : (
              <ChangeLangBody>Current language pair</ChangeLangBody>
            )}
          </EmptyContainer>

          {/* base language */}
          <ChangeLangBase>{baseLanguage}</ChangeLangBase>

          {/* target language */}
          {newLanguage ? (
            <ChangeLangNew
              key={newLanguage}
              variants={popUpVariants}
              initial="initial"
              animate="visible"
              exit="exit"
            >
              {newLanguage}
            </ChangeLangNew>
          ) : (
            <ChangeLangTarget>{targetLanguage}</ChangeLangTarget>
          )}

          {/* save changes button  */}
          {applyButton ? (
            <React.Fragment>
              <Spacer height=".5rem" />
              <ChangeLangButtonSave
                variants={popUpVariants}
                initial="initial"
                animate="visible"
                exit="exit"
                onClick={
                  () => saveChanges(userid, newLanguagePair)
                  //console.log("change blocked!")
                }
              >
                {!saved ? "Apply changes" : "Applying changes..."}
              </ChangeLangButtonSave>
            </React.Fragment>
          ) : null}

          <Spacer />
          <EmptyContainer>
            <ChangeLangBody>Learn another language</ChangeLangBody>
          </EmptyContainer>
          {targetLanguageButtons()}
        </SettingsPrompt>
      ) : null}
    </AnimatePresence>
  );
};

const mapStateToProps = ({ user, display }) => {
  return { user, display };
};

export default connect(mapStateToProps, {
  changeLanguagePair,
  displayChangeLanguage,
})(ChangeLanguage);
