import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";

import { ImgThumb, ImgThumbCover } from "./styles";

const Thumbnail = ({ src, thumbBlank }) => {
  const [loaded, setLoaded] = useState(false);

  const screenDimVariants = {
    initial: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { type: "tween", duration: 1 },
    },
  };

  const img = new Image();
  img.src = src;
  img.onload = function () {
    setLoaded(true);
  };

  return (
    <React.Fragment>
      <ImgThumbCover key="background" src={thumbBlank} width="auto" />

      {loaded ? (
        <ImgThumb
          variants={screenDimVariants}
          initial="initial"
          animate="visible"
          transition={{ duration: 3 }}
          key="image"
          src={src}
          width="auto"
        />
      ) : null}

      {!loaded ? (
        <ImgThumb key="image-placeholder" src={thumbBlank} width="auto" />
      ) : null}
    </React.Fragment>
  );
};

export default Thumbnail;
