import React from "react";

import { SiteContainer, SigninContainer } from "./styles";
import UserAuthForm from "./UserAuthForm";

const Signin = () => {
  return (
    <SiteContainer top="-3rem">
      <UserAuthForm />
    </SiteContainer>
  );
};

export default Signin;
