import React from "react";

import {
  CompletionBarContainer,
  yellow_1,
  green_1,
  blue_1,
  blue_2,
  light_grey_1,
  light_grey_2,
  blue_3,
} from "./styles";

const CompletionBar = ({
  type,
  strokeWidth,
  background,
  learning,
  consolidating,
}) => {
  let colorLearning;
  let colorConsolidating;
  let backgroundColor;

  let strokeWidthLearning = strokeWidth;

  const amountLearning = learning > 50 ? learning - 3 : learning + 3;
  const amountConsolidating =
    consolidating > 50 ? consolidating - 3 : consolidating + 3;

  if (type === "speaking") {
    colorLearning = green_1;
    colorConsolidating = yellow_1;
  }

  if (type === "listening") {
    colorLearning = blue_1;
    colorConsolidating = yellow_1;
  }

  if (background === "light") {
    backgroundColor = light_grey_1;
  }

  if (background === "light-practice") {
    backgroundColor = light_grey_2;
  }

  if (background === "dark") {
    backgroundColor = blue_3;
  }

  if (type === "stats") {
    backgroundColor = yellow_1;
    colorConsolidating = green_1;
    colorLearning = blue_2;
    strokeWidthLearning = ".34rem";
  }

  if (type === "statsLevelSpeaking") {
    backgroundColor = yellow_1;
    colorConsolidating = green_1;
    colorLearning = blue_3;
    strokeWidthLearning = ".34rem";
  }

  if (type === "statsLevelListening") {
    backgroundColor = yellow_1;
    colorConsolidating = blue_1;
    colorLearning = blue_3;
    strokeWidthLearning = ".34rem";
  }

  if (type === "statsEvalSpeaking") {
    backgroundColor = yellow_1;
    colorConsolidating = green_1;
    colorLearning = blue_1;
    strokeWidthLearning = ".34rem";
  }

  //preloader for dashboard
  if (type === "loading" && background === "dashboard") {
    colorLearning = blue_3;
    backgroundColor = blue_2;
  }

  // preloader for practise screen
  if (type === "loading" && background === "speaking") {
    colorLearning = blue_1;
    backgroundColor = light_grey_2;
  }

  if (type === "loading" && background === "listening") {
    colorLearning = blue_1;
    backgroundColor = blue_3;
  }

  return (
    <CompletionBarContainer>
      <svg viewBox="0 0 100 5" xmlns="http://www.w3.org/2000/svg">
        {learning > 0 ? (
          <line
            x1="3"
            y1="2"
            x2="97"
            y2="2"
            stroke={backgroundColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
        ) : null}
        {learning > 0 ? (
          <line
            x1="3"
            y1="2"
            x2={amountLearning}
            y2="2"
            stroke={colorLearning}
            strokeWidth={strokeWidthLearning}
            strokeLinecap="round"
          />
        ) : null}

        {consolidating > 0 ? (
          <line
            x1="3"
            y1="2"
            x2={amountConsolidating}
            y2="2"
            stroke={colorConsolidating}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
        ) : null}
      </svg>
    </CompletionBarContainer>
  );
};

export default CompletionBar;

// Dashboard

// margin       ---->      1% 1.2rem 1% 1.2rem;

// <CompletionBar top="-1rem">
//                 <svg viewBox="0 0 100 5" xmlns="http://www.w3.org/2000/svg">
//                   <line
//                     x1="3"
//                     y1="2"
//                     x2="97"
//                     y2="2"
//                     stroke={barBackground}
//                     strokeWidth=".25rem"
//                     strokeLinecap="round"
//                   />
//                   <line
//                     x1="3"
//                     y1="2"
//                     x2={barAmount}
//                     y2="2"
//                     stroke={barColor}
//                     strokeWidth=".25rem"
//                     strokeLinecap="round"
//                   />
//                 </svg>
//               </CompletionBar>

// Pack details

// top     ------->       top: 0.2rem;       same for one below

// <CompletionBarPractiseDetail height="1rem" width="100%">
//               <svg viewBox="0 0 100 5" xmlns="http://www.w3.org/2000/svg">
//                 <line
//                   x1="2"
//                   y1="2"
//                   x2="97"
//                   y2="2"
//                   stroke={barBackground}
//                   strokeWidth=".16rem"
//                   strokeLinecap="round"
//                 />
//                 <line
//                   x1="2"
//                   y1="2"
//                   x2={barAmount}
//                   y2="2"
//                   stroke={barColor}
//                   strokeWidth=".16rem"
//                   strokeLinecap="round"
//                 />
//               </svg>
//             </CompletionBarPractiseDetail>

// Practise

// <CompletionBarPractiseDetail height="1rem" width="100%">
//           <svg viewBox="0 0 100 5" xmlns="http://www.w3.org/2000/svg">
//             <line
//               x1="2"
//               y1="2"
//               x2="97"
//               y2="2"
//               stroke={barBackground}
//               strokeWidth=".16rem"
//               strokeLinecap="round"
//             />
//             <line
//               x1="2"
//               y1="2"
//               x2={count}
//               y2="2"
//               stroke={barColor}
//               strokeWidth=".16rem"
//               strokeLinecap="round"
//             />
//           </svg>
//         </CompletionBarPractiseDetail>
