import React, { useState, useRef } from "react";
import EmailValidator from "email-validator";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AnimatePresence } from "framer-motion";
import axios from "axios";

import {
  Input,
  ButtonAuthSignUp,
  ButtonAuthSignIn,
  ButtonAuthBack,
  ButtonAuthLanguage,
  ButtonAuthLanguageSelected,
  ButtonAuthInactive,
  AuthText,
  AuthTextError,
  TextButton,
  Img,
  Spacer,
  HorzSpacer,
  SignInContainer,
  SignInSubContainer,
} from "./styles";
import {
  signupUser,
  signinUser,
  fetchUser,
  signoutUser,
  googleSignin,
} from "../actions/index";

import speaksyLogo from "../images/speaksy_alpha_nospace-01.svg";
import groupOfPeople from "../images/sessionFinish/group_of_people-01.svg";
import iconArrowForward from "../images/icons/icon_arrow_forward-01.svg";
import iconTick from "../images/icons/icon_tick-01.svg";
import iconKey from "../images/icons/icon_key-01.svg";

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

const UserAuthForm = ({
  signupUser,
  signinUser,
  fetchUser,
  signoutUser,
  googleSignin,
  history,
}) => {
  // state to keep track of sign in/sign up process
  const [formMode, setFormMode] = useState("home");
  const [formStep, setFormStep] = useState(0);
  const [exitSide, setExitSide] = useState("right");

  // state to hold input data
  const [inviteCode, setInviteCode] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [language, setLanguage] = useState(null);

  const [errorCheck, setErrorCheck] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  // set focus hook
  const [inputRef, setInputFocus] = useFocus();

  // need to use a ref so the values aren't stale
  const formStepRef = useRef();
  formStepRef.current = formStep;

  const exitSideRef = useRef();
  exitSideRef.current = exitSide;

  const slideInVariants = {
    initial: () => {
      if (exitSideRef.current === "left") {
        return {
          x: "-100vw",
          transition: { type: "tween", duration: 0.3 },
        };
      } else {
        return {
          x: "100vw",
          transition: { type: "tween", duration: 0.3 },
        };
      }
    },
    initialLeft: {
      x: "-100vw",
    },
    initialRight: {
      x: "100vw",
    },
    visible: {
      x: 0,
      transition: { type: "spring", stiffness: 100, mass: 0.5 },
    },
    exitLeft: {
      x: "-100vw",
      transition: { type: "tween", duration: 0.3 },
    },
    exitRight: {
      x: "100vw",
      transition: { type: "tween", duration: 0.3 },
    },
    exit: () => {
      if (exitSideRef.current === "left") {
        return {
          x: "100vw",
          transition: { type: "tween", duration: 0.3 },
        };
      } else {
        return {
          x: "-100vw",
          transition: { type: "tween", duration: 0.3 },
        };
      }
    },
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setPassword("");
  };

  const authContent = (mode) => {
    // check fields
    const checkField = (field) => {
      switch (field) {
        case "name": {
          if (name.length > 0) {
            setErrorCheck("email");
            return true;
          } else {
            setErrorMessage("Please enter your first name");
            setInputFocus();
          }
          break;
        }
        case "email": {
          if (EmailValidator.validate(email)) {
            // signup gets sent off to a function to check if email exists in database
            if (mode === "signup") {
              checkEmail(email);
              break;
            }

            // if it's signin then it can continue...
            setErrorCheck("password");
            setFormStep((s) => s + 1);
            setExitSide("right");
          } else {
            setErrorMessage("Please enter a valid email");
            setInputFocus();
          }
          break;
        }
        case "password": {
          if (password.length > 7) {
            return true;
          } else {
            setErrorMessage(
              "Please enter a password with at least 8 characters"
            );
            setInputFocus();
          }
          break;
        }
        default:
          return null;
      }
    };

    const selectButton = (name) => {
      if (name === language) {
        return (
          <ButtonAuthLanguageSelected onClick={() => setLanguage(name)}>
            {name}
          </ButtonAuthLanguageSelected>
        );
      } else {
        return (
          <ButtonAuthLanguage onClick={() => setLanguage(name)}>
            {name}
          </ButtonAuthLanguage>
        );
      }
    };

    const inactiveButton = (name) => {
      return <ButtonAuthInactive>{name}</ButtonAuthInactive>;
    };

    const exampleFunction = (param) => {
      console.log(param);
    };

    const submitButton = () => {
      const button = (text, func) => {
        return (
          <ButtonAuthSignIn onClick={() => func()}>{text}</ButtonAuthSignIn>
        );
      };

      switch (language) {
        case "French":
          return button("C'est Parti! (Let's go!)", createAccount);
        case "Italian":
          return button("Andiamo! (Let's go!)", createAccount);
        case "Spanish":
          return button("Vámonos! (Let's go!)", createAccount);
        case "German":
          return button("Los geht's! (Let's go!)", createAccount);
        case "Greek":
          return button("Πάμε! (Let's go!)", createAccount);
        default:
          return null;
      }
    };

    const backButton = (
      <>
        <ButtonAuthBack
          onClick={() => {
            setFormStep((s) => s - 1);
            setExitSide("left");
            setErrorMessage("");
            if (formStepRef.current === 1) {
              setFormMode("home");
            }
            if (errorCheck === "email") {
              setErrorCheck("name");
            }
            if (errorCheck === "password") {
              setErrorCheck("email");
            }
          }}
        >
          <Img
            top=".1rem"
            width="1.3rem"
            transform="rotate(180deg)"
            src={iconArrowForward}
          />
        </ButtonAuthBack>
        <HorzSpacer width=".7rem" />
      </>
    );

    const forwardFunction = () => {
      const validate = checkField(errorCheck);

      if (validate) {
        setFormStep((s) => s + 1);
        setExitSide("right");
      }
    };

    const forwardButton = (
      <ButtonAuthSignUp
        onClick={() => {
          forwardFunction();
        }}
      >
        <Img src={iconTick} width="1.5rem" top=".1rem" />
      </ButtonAuthSignUp>
    );

    const handleKeyPress = (event, type) => {
      if (event.key === "Enter") {
        if (formMode === "signin" && formStepRef.current === 2) {
          signInUser();
          return;
        }

        forwardFunction();
      }
    };

    const createAccount = async () => {
      const res = await signupUser(
        // forcing it to make a french account for now!
        { name, email, password, currentLanguagePair: "english" + language },
        history
      );

      if (res === false) {
        setErrorMessage("Sign up failed :(");
      }
    };

    const signInUser = async () => {
      const res = await signinUser({ email, password }, history);

      if (res === false) {
        setErrorMessage(
          "Sign in failed. Please check your email and password."
        );
      }
    };

    const signInButton = (
      <ButtonAuthSignIn
        onClick={() => {
          const validate = checkField(errorCheck);

          if (validate) {
            signInUser();
          }
        }}
      >
        Sign in
      </ButtonAuthSignIn>
    );

    const nameField = (
      <>
        <Input
          onKeyPress={(event) => {
            handleKeyPress(event);
          }}
          name="name"
          placeholder=""
          autoComplete="off"
          value={name}
          ref={inputRef}
          onChange={(e) => {
            setName(e.target.value);
            setErrorCheck("name");
            setErrorMessage("");
          }}
        />
      </>
    );

    const emailField = (
      <>
        <Input
          onKeyPress={(event) => {
            handleKeyPress(event);
          }}
          name="email"
          placeholder=""
          autoComplete="off"
          value={email}
          ref={inputRef}
          onChange={(e) => {
            setEmail(e.target.value);
            setErrorCheck("email");
            if (errorMessage !== "") {
              if (EmailValidator.validate(email)) {
                setErrorMessage("");
              }
            }
          }}
        />
      </>
    );

    const passwordField = (
      <>
        <Input
          onKeyPress={(event) => {
            handleKeyPress(event);
          }}
          name="password"
          type="password"
          placeholder=""
          autoComplete="off"
          value={password}
          ref={inputRef}
          onChange={(e) => {
            setPassword(e.target.value);
            if (errorMessage !== "") {
              if (password.length > 7) {
                setErrorMessage("");
              }
            }
          }}
        />
      </>
    );

    const signInProblem = (
      <SignInSubContainer height="auto">
        <Spacer height=".2rem" />
        <TextButton>Having trouble signing in?</TextButton>
      </SignInSubContainer>
    );

    const checkEmail = async (email) => {
      try {
        console.log("sending email: ", email);
        const res = await axios.post("/user/check-email", {
          email,
        });

        const user = res.data;

        // if user doesn't exist
        if (!user) {
          setErrorCheck("password");
          setFormStep((s) => s + 1);
          setExitSide("right");
        }
        //if user does exist
        else {
          setErrorMessage("This email is already in use");
          setInputFocus();
        }
      } catch {
        setErrorMessage("Cannot validate email, please try again");
        setInputFocus();
      }
    };

    const generateErrorMessage = (message) => {
      // yep
      if (message) {
        return (
          <AuthTextError>
            <Spacer height=".8rem" />
            {message}
          </AuthTextError>
        );
      } else {
        return null;
      }
    };

    if (mode === "signup") {
      switch (formStepRef.current) {
        case 1:
          return (
            <>
              <SignInSubContainer height="auto">
                <Img src={groupOfPeople} width="22rem" />
                <Spacer height="1.5rem" />
              </SignInSubContainer>
              <SignInSubContainer height="auto">
                <AuthText>
                  Let’s start with your first name or a nick name
                </AuthText>
                <Spacer height=".9rem" />
              </SignInSubContainer>
              <SignInSubContainer>{nameField}</SignInSubContainer>
              <SignInSubContainer height="auto">
                {generateErrorMessage(errorMessage)}
              </SignInSubContainer>
              <Spacer />
              <SignInSubContainer height="auto">
                {backButton}
                {forwardButton}
              </SignInSubContainer>
            </>
          );
        case 2:
          return (
            <>
              <SignInSubContainer height="auto">
                <Img src={groupOfPeople} width="22rem" />
                <Spacer height="1.5rem" />
              </SignInSubContainer>
              <SignInSubContainer height="auto">
                <AuthText>Hi {name}, what’s your email address?</AuthText>
                <Spacer height=".9rem" />
              </SignInSubContainer>
              <SignInSubContainer>{emailField}</SignInSubContainer>
              <SignInSubContainer height="auto">
                {generateErrorMessage(errorMessage)}
              </SignInSubContainer>
              <Spacer />
              <SignInSubContainer height="auto">
                {backButton}
                {forwardButton}
              </SignInSubContainer>
            </>
          );
        case 3:
          return (
            <>
              <SignInSubContainer height="auto">
                <Img src={groupOfPeople} width="22rem" />
                <Spacer height="1.5rem" />
              </SignInSubContainer>
              <SignInSubContainer height="auto">
                <AuthText>Create a password (at least 8 characters)</AuthText>
                <Spacer height=".9rem" />
              </SignInSubContainer>
              <SignInSubContainer>{passwordField}</SignInSubContainer>
              <SignInSubContainer height="auto">
                {generateErrorMessage(errorMessage)}
              </SignInSubContainer>
              <Spacer />
              <SignInSubContainer height="auto">
                {backButton}
                {forwardButton}
              </SignInSubContainer>
            </>
          );
        case 4:
          return (
            <>
              <SignInSubContainer height="auto">
                <Img src={groupOfPeople} width="22rem" />
                <Spacer height="1.5rem" />
              </SignInSubContainer>
              <SignInSubContainer height="auto">
                <AuthText>
                  Which language would you like to start speaking first?
                </AuthText>
                <Spacer height="1.4rem" />
              </SignInSubContainer>
              <SignInSubContainer height="auto">
                {selectButton("French")}
                <HorzSpacer width=".6rem" />
                {inactiveButton("Italian")}
                <HorzSpacer width=".6rem" />
                {selectButton("Spanish")}
                <HorzSpacer width=".6rem" />
                {inactiveButton("German")}
                <HorzSpacer width=".6rem" />
                {selectButton("Greek")}

                <Spacer />
              </SignInSubContainer>
              <SignInSubContainer height="auto">
                {backButton} {submitButton()}
              </SignInSubContainer>
            </>
          );
        default:
          return "this is a catch all";
      }
    }
    if (mode === "signin") {
      switch (formStepRef.current) {
        case 1:
          return (
            <>
              <SignInSubContainer height="auto">
                <Img src={groupOfPeople} width="22rem" />
                <Spacer height="1.5rem" />
              </SignInSubContainer>
              <SignInSubContainer height="auto">
                <AuthText>
                  Welcome back, please enter your email address
                </AuthText>
                <Spacer height=".9rem" />
              </SignInSubContainer>
              <SignInSubContainer>{emailField}</SignInSubContainer>
              <SignInSubContainer height="auto">
                {generateErrorMessage(errorMessage)}
              </SignInSubContainer>
              <Spacer />
              <SignInSubContainer height="auto">
                {backButton}
                {forwardButton}
              </SignInSubContainer>
              {signInProblem}
            </>
          );
        case 2:
          return (
            <>
              <SignInSubContainer height="auto">
                <Img src={groupOfPeople} width="22rem" />
                <Spacer height="1.5rem" />
              </SignInSubContainer>
              <SignInSubContainer height="auto">
                <AuthText>And now your password</AuthText>
                <Spacer height=".9rem" />
              </SignInSubContainer>
              <SignInSubContainer>{passwordField}</SignInSubContainer>
              <SignInSubContainer height="auto">
                {generateErrorMessage(errorMessage)}
              </SignInSubContainer>
              <Spacer />
              <SignInSubContainer height="auto">
                {backButton}
                {signInButton}
              </SignInSubContainer>
              {signInProblem}
            </>
          );
        default:
          return "this is a catch all";
      }
    }
  };

  return (
    <React.Fragment>
      {/* SWITCHING CONTENT WITH KEY */}

      <AnimatePresence>
        <SignInContainer
          variants={slideInVariants}
          initial="initial"
          animate="visible"
          exit="exit"
          key={formStepRef.current}
          onAnimationComplete={() => {
            setInputFocus();
          }}
        >
          {formMode === "signup" || formMode === "signin"
            ? authContent(formMode)
            : null}

          {formStep === 0 ? (
            <React.Fragment>
              <SignInSubContainer height="auto">
                <Img width="14rem" left="1rem" src={speaksyLogo} />
                <Spacer height="2rem" />
              </SignInSubContainer>
              <SignInSubContainer height="auto">
                <ButtonAuthSignUp
                  onClick={() => {
                    setInviteCode(true);

                    setTimeout(() => {
                      setInputFocus();
                    }, 200);
                  }}
                >
                  Create account
                </ButtonAuthSignUp>
                <HorzSpacer width=".7rem" />
                <ButtonAuthSignIn
                  onClick={() => {
                    setFormStep((s) => s + 1);
                    setExitSide("right");
                    setFormMode("signin");
                    setErrorCheck("email");
                    resetForm();
                  }}
                >
                  Sign in
                </ButtonAuthSignIn>
              </SignInSubContainer>

              {inviteCode ? (
                <SignInSubContainer>
                  <AuthTextError>
                    <Spacer height="1.5rem" />
                    Speaksy isn’t open to everyone just yet. <br />
                    Enter your invite code to create an account.
                  </AuthTextError>
                  <Spacer height="1rem" />
                  <Input
                    name="invite-code"
                    placeholder=""
                    autoComplete="off"
                    width="200px"
                    ref={inputRef}
                    onChange={(e) => {
                      setExitSide("right");

                      if (
                        e.target.value === "desirepath" ||
                        e.target.value === "Desirepath" ||
                        e.target.value === "omegamma" ||
                        e.target.value === "Omegamma"
                      ) {
                        setTimeout(() => {
                          setFormStep((s) => s + 1);
                          setFormMode("signup");
                          setErrorCheck("name");
                          resetForm();
                          setInviteCode(false);
                        }, 300);
                      }
                    }}
                  />
                  <HorzSpacer width=".7rem" />
                  <Img src={iconKey} width="3rem" top="-.3rem" />
                </SignInSubContainer>
              ) : null}
            </React.Fragment>
          ) : null}
        </SignInContainer>
      </AnimatePresence>

      <Spacer height="3rem" />

      {/* <ButtonGoogle type="button" onClick={() => googleSignin()}>
        with Google
      </ButtonGoogle> */}
    </React.Fragment>
  );
};

export default connect(null, {
  signupUser,
  signinUser,
  fetchUser,
  signoutUser,
  googleSignin,
})(withRouter(UserAuthForm));
