import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { connect } from "react-redux";

import Signin from "./Signin";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "./Dashboard";
import Loading from "./Loading";
import LanguageSelect from "./LanguageSelect";
import Practise from "./Practise";

function App({ user }) {
  const { signedin } = user;

  return (
    <BrowserRouter>
      <style jsx="true">{`
        body {
          margin: 0px;
          background: #489cef;
          overflow-y: hidden;
        }
      `}</style>

      <Route path="/" exact component={Loading} />
      <Route path="/signin" exact component={Signin} />
      <PrivateRoute
        path="/languageselect"
        exact
        signedin={signedin}
        component={LanguageSelect}
      />
      <PrivateRoute
        path="/dashboard"
        exact
        signedin={signedin}
        component={Dashboard}
      />
      <PrivateRoute
        path="/practise"
        exact
        signedin={signedin}
        component={Practise}
      />
    </BrowserRouter>
  );
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(App);
