import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { fetchUser } from "../actions/index";

import { SiteContainer, PackDetailStatsTextCenter } from "./styles";

class Loading extends Component {
  componentDidMount() {
    this.props.fetchUser(this.props.history);
  }

  render() {
    return (
      <SiteContainer>
        <PackDetailStatsTextCenter>Authenticating</PackDetailStatsTextCenter>
      </SiteContainer>
    );
  }
}

export default connect(null, { fetchUser })(withRouter(Loading));
