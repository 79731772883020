import React, { useState, useRef, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { isMobile } from "react-device-detect";

import {
  SiteContainer,
  PractiseSiteContainer,
  PractiseContainer,
  PractiseTopContainer,
  PractiseGutterContainer,
  PractiseMainContainer,
  PractiseReviewContainer,
  PractiseItemReviewContainer,
  PractiseItemReviewTextContainer,
  ButtonEmpty,
  ButtonEmptyPractice,
  Img,
  Spacer,
  HorzSpacer,
  CompletionBarContainer,
  SpeechButtonMain,
  SpeechButtonPlayHead,
  SpeechButtonPlayHeadMask,
  SpeechButtonSecondaryPlayHead,
  SpeechButtonSecondary,
  SpeechButtonIcon,
  LearningButton,
  ConsolidationButton,
  FinishSessionButton,
  WidthContainer,
  DisplayDim,
  ExitPractisePrompt,
  ExitSessionButton,
  ReportSessionButton,
  ExitSessionText,
  CustomContainer,
  DisplayEvaluationContainer,
  EvaluationContainer,
  EvaluationCenterContainer,
  EvaluationTextSmall,
  EvaluationTextStats,
  EvaluationText,
  EvaluationLine,
  EvalStatsLevelText,
  EvalStatsTextBold,
  BlueHighlight,
  PractiseAgainButton,
  // EmptyContainer,
  yellow_1,
  blue_1,
  blue_3,
  // green_1,
  light_grey_1,
  light_grey_2,
  light_grey_3,
  PackDetailsItemsReadyContainer,
  PackItemsReadyDetailDisplay,
  PackDetailsStatsLevelContainer,
  PackDetailsStatsSection,
  PackDetailsStatsLevelText,
  PackDetailsStatsSplitContainer,
  PackDetailStatsText,
  PackDetailsStatsTextBold,
} from "./styles";
import { evaluatePractiseSession, createPracticeSession } from "../actions";
import { formatTime } from "./formatTime";
import CompletionBar from "../components/CompletionBar";

// images
import iconExitSession from "../images/icons/icon_exit_session-01.svg";
import iconExitSessionBlue from "../images/icons/icon_exit_session_blue-01.svg";
import iconFlag from "../images/icons/icon_flag-01.svg";
import iconFlagBlue from "../images/icons/icon_flag_blue-01.svg";
import iconPlay from "../images/icons/icon_play-01.svg";
import iconPlayBlue from "../images/icons/icon_play_blue-01.svg";
import iconLearning from "../images/icons/icon_learning-01.svg";
import iconLearningBlue from "../images/icons/icon_learning_blue-01.svg";
import iconConsolidating from "../images/icons/icon_consolidating-01.png";
import iconConsolidatingBlue from "../images/icons/icon_consolidating_blue-01.svg";
// import iconDash from '../images/icons/icon_dash_white-01.svg';
import iconTick from "../images/icons/icon_tick-01.svg";
import iconTickBlack from "../images/icons/icon_tick_black-01.svg";
import iconTickYellow from "../images/icons/icon_tick_yellow-01.svg";
import iconArrowForward from "../images/icons/icon_arrow_forward-01.svg";
import iconArrowForwardGreen from "../images/icons/icon_arrow_forward_green-01.svg";
import ladyBurst from "../images/sessionFinish/lady_burst-01.svg";
import practiseDots from "../images/icons/icon_practise_dots-01.svg";
import practiseDotsBlue from "../images/icons/icon_practise_dots_blue-01.svg";
import maskLightGrey from "../images/misc/mask_light_grey-01.svg";
import maskBlue from "../images/misc/mask_blue-01.svg";

import iconTickYellowSmall from "../images/icons/icon_tick_yellow_small-01.svg";
import iconTickBlueSmall from "../images/icons/icon_tick_blue_small-01.svg";

const Practise = ({
  practise,
  user,
  display,
  content,
  evaluatePractiseSession,
  createPracticeSession,
  history,
}) => {
  const {
    practiseProgressItems,
    practiseContentItems,
    type,
    practiseLength,
    levels,
    currentLevel,
    levelType,
    levelUp,
    packCompleted,
  } = practise;
  const {
    currentPackProgress,
    progressId,
    currentLanguagePair,
    activePacks,
    userid,
    timePracticed,
    lastPracticed,
    currentStreak,
    learningPoints,
    consolidationPoints,
    weeklyStats,
    name,
  } = user;
  const { packDetailsDisplay } = display;
  const { id } = packDetailsDisplay;
  const { loadedPacks, packs } = content;

  const [dailyStatsTotals, setDailyStatsTotals] = useState({});

  const [itemsReadyState, setItemsReadyState] = useState(0);

  // state to force level up
  const [forceLevelUp, setForceLevelUp] = useState(false);

  // preview level up
  const levelUpFake = true;

  // ! these need to sit above variants otherwise error
  const [baseDuration, setBaseDuration] = useState(null);
  const [targetDuration, setTargetDuration] = useState(null);

  // state to keep track of load progress
  const [loaded, setLoaded] = useState({ images: false, audio: false });

  const [filesLoaded, setFilesLoaded] = useState(0);
  const [filesLoading, setFilesLoading] = useState(0);

  // calculate if page is loaded
  const pageLoaded = Math.round((filesLoaded / filesLoading) * 100);

  // monitor size changes
  // need to use useCallback otherwise we can't remove the event listeners
  const playBaseRef = useRef(null);
  const playTargetRef = useRef(null);

  const updateSize = useCallback(() => {
    if (playBaseRef.current) {
      setPlayButtonPos(playBaseRef.current.offsetWidth);
    }
  }, []);

  // even though I'm not using the state it's triggering a re-render
  const [playButtonPos, setPlayButtonPos] = useState(0);

  const [addEventListeners, setAddEventListeners] = useState(false);

  if (!addEventListeners) {
    window.addEventListener("resize", updateSize);
    window.addEventListener("load", updateSize);
    window.addEventListener("DOMContentLoaded", updateSize);

    setAddEventListeners(true);
  }

  const removeListeners = () => {
    window.removeEventListener("resize", updateSize);
    window.removeEventListener("load", updateSize);
    window.removeEventListener("DOMContentLoaded", updateSize);
  };

  //console.log(pageLoaded);

  //console.log("level up is currently: " + levelUp);

  // if (filesLoaded !== 0 && filesLoaded === filesLoading) {
  //   console.log("PAGE LOADED.......");
  // }

  if (!loaded.images) {
    const imagesObject = {
      iconExitSession,
      iconExitSessionBlue,
      iconFlag,
      iconFlagBlue,
      iconPlay,
      iconPlayBlue,
      iconLearning,
      iconLearningBlue,
      iconConsolidating,
      iconConsolidatingBlue,
      iconTick,
      iconTickBlack,
      iconTickYellow,
      iconArrowForward,
      iconArrowForwardGreen,
      ladyBurst,
      practiseDots,
      practiseDotsBlue,
      maskLightGrey,
      maskBlue,
      iconTickYellowSmall,
      iconTickBlueSmall,
    };

    const filesLoading = [];

    for (const [key, value] of Object.entries(imagesObject)) {
      const img = new Image();
      img.src = value;
      window[key] = img;

      filesLoading.push(key);

      img.onload = () => {
        // add to files loaded state array
        setFilesLoaded((s) => s + 1);
      };
    }

    // add to files loading state array
    setFilesLoading((s) => s + filesLoading.length);

    setLoaded({ ...loaded, images: true });
  }

  let learningType;
  // let consolidationType;
  let tsType;
  // let suffix;

  // set background color based on practise type
  let bgColor;
  let progressBgColor;
  let playheadBg;
  let playheadSecondaryBg;
  let learningBtnBg;
  let consolidatingBtnBg;
  let reviewTextColor;
  let finishBgHover;

  // icons
  let icon_exit;
  let icon_flag;
  let icon_learning;
  let icon_consolidating;
  let icon_tick;
  let icon_arrow;

  if (type === "speaking") {
    learningType = "learning_S";
    // consolidationType = "consolidation_S";

    // suffix = "_S";
    tsType = "ts_S";
    bgColor = "#f1f1f1";
    progressBgColor = "#D9D9D9";
    playheadBg = "#1D87CE";
    playheadSecondaryBg = "#D9D9D9";
    learningBtnBg = "#39EA5F";
    consolidatingBtnBg = "#E7DB03";
    reviewTextColor = light_grey_3;
    finishBgHover = "#1D87CE";

    icon_exit = iconExitSession;
    icon_flag = iconFlag;
    icon_learning = iconLearning;
    icon_consolidating = iconConsolidating;
    icon_tick = iconTick;
    icon_arrow = iconArrowForward;
  }

  if (type === "listening") {
    learningType = "learning_L";
    // consolidationType = "consolidation_L";

    // suffix = "_L";
    tsType = "ts_L";
    bgColor = "#1D87CE";
    progressBgColor = "#1277AF";
    playheadBg = "#1277AF";
    playheadSecondaryBg = "#D9D9D9";
    learningBtnBg = "#1277AF";
    consolidatingBtnBg = "#1277AF";
    reviewTextColor = "#F1F1F1";
    finishBgHover = "#1277AF";

    icon_exit = iconExitSessionBlue;
    icon_flag = iconFlagBlue;
    icon_learning = iconLearningBlue;
    icon_consolidating = iconConsolidatingBlue;
    icon_tick = iconTickYellow;
    icon_arrow = iconArrowForwardGreen;
  }

  // framer motion variants
  const practiseButtonVariants = {
    initial: {
      scale: 0,
    },
    visible: {
      scale: 1,
      transition: { type: "spring", stiffness: 150, mass: 0.5 },
    },
    exit: {
      scale: 0,
      transition: { type: "tween", duration: 0.1 },
    },
    hover: {
      scale: 1.05,
      transition: { type: "tween", duration: 0, ease: "linear" },
    },
  };

  const topBarVariants = {
    initial: {
      scale: 0,
    },
    visible: {
      scale: 1,
      transition: { type: "spring", stiffness: 150, mass: 0.5 },
    },
    exit: {
      scale: 0,
      transition: { type: "tween", duration: 0.1 },
    },
  };

  // usually 1.5 delay on visible
  const evalScreenVariants = {
    initial: {
      x: "100vw",
    },
    visible: {
      x: "-50%",
      transition: { type: "spring", stiffness: 60, mass: 0.5, delay: 1.5 },
    },
    exit: {
      x: "100vw",
      transition: { type: "spring", stiffness: 60, mass: 0.5 },
    },
  };

  const displayPromptVariants = {
    initial: {
      y: "100vw",
    },
    visible: {
      y: 0,
      transition: { type: "spring", stiffness: 80, mass: 0.5 },
    },
    exit: {
      y: "100vw",
      transition: { type: "spring", stiffness: 80, mass: 0.5 },
    },
  };

  const screenDimVariants = {
    initial: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { type: "tween", duration: 0.2 },
    },
    exit: {
      opacity: 0,
      transition: { type: "tween", duration: 0.2 },
    },
  };

  const playHead = {
    initial: {
      x: "-100%",
    },
    visible: {
      x: 0,
      transition: { type: "tween", duration: baseDuration, ease: "linear" },
    },
    exit: {
      opacity: 0,
      transition: { type: "tween", duration: 0.2 },
    },
  };

  const playHeadSecondary = {
    initial: {
      x: "-100%",
    },
    visible: {
      x: 0,
      transition: { type: "tween", duration: targetDuration, ease: "linear" },
    },
    exit: {
      opacity: 0,
      transition: { type: "tween", duration: 0.2 },
    },
  };

  // state to control showing buttons
  const [showPractiseElements, setShowPractiseElements] = useState({
    audioBaseButton: true,
    audioTargetButton: false,
    evalButtons: null,
    evalResult: null,
    practiseTopBar: true,
    practiseScreen: true,
    buttonPosition: null,
  });

  // set up a ref for stale places
  const practiseElementsRef = useRef(showPractiseElements);
  practiseElementsRef.current = showPractiseElements;

  // lets us restart practise
  const [startPractise, setStartPractise] = useState(null);

  // this holds the users answers to questions
  const [practiseItem, setPractiseItem] = useState([]);

  // hold current pack
  const [currentPack, setCurrentPack] = useState(null);

  // state to keep track of whether we've played the base audio at session start
  const [sessionStart, setSessionStart] = useState(false);

  // get start time!
  //const startTime = useRef(Date.now());
  const [startTime, setStartTime] = useState(null);

  // state to hold evaluate info for single item
  const [evaluate, setEvaluate] = useState(null);

  // allows us to control prompts
  const [promptDisplay, setPromptDisplay] = useState(null);

  let completionBarAmount;
  practiseItem.length < practiseContentItems.length
    ? (completionBarAmount =
        (practiseItem.length / practiseContentItems.length) * 100 + 3)
    : (completionBarAmount =
        (practiseItem.length / practiseContentItems.length) * 100 - 3);

  // load up the audio files for practise session
  const [audioFilesBase, setAudioFilesBase] = useState(null);
  const [audioFilesTarget, setAudioFilesTarget] = useState(null);

  // useEffect to make a new practise session
  useEffect(() => {
    if (startPractise) {
      console.log("restarting practise");

      // startTime.current = Date.now();
      //console.log("session start time is: " + practiseElementsRef.current);
      //console.log("time now is: " + Date.now());

      setAudioFilesBase(null);
      setAudioFilesTarget(null);
      setPractiseItem([]);
      setShowPractiseElements({
        audioBaseButton: true,
        audioTargetButton: false,
        evalButtons: null,
        evalResult: null,
        practiseTopBar: true,
        practiseScreen: true,
      });

      createPracticeSession(
        id,
        loadedPacks,
        currentPackProgress,
        history,
        type,
        progressId,
        currentLanguagePair,
        packs,
        activePacks
      );

      setStartPractise(false);
    }
  }, [
    startPractise,
    id,
    loadedPacks,
    currentPackProgress,
    history,
    type,
    progressId,
    currentLanguagePair,
    createPracticeSession,
    packs,
    activePacks,
    setShowPractiseElements,
    setAudioFilesBase,
    setAudioFilesTarget,
  ]);

  // useEffect to get current pack
  useEffect(() => {
    for (let i = 0; i < activePacks.length; i++) {
      if (activePacks[i]._id === id) {
        setCurrentPack(activePacks[i]);

        const currentPack = activePacks[i];
        const currentTime = Date.now();
        let itemsReady = 0;

        if (type === "speaking") {
          if (currentPack.timestamps_S) {
            for (let i = 0; i < currentPack.timestamps_S.length; i++) {
              if (currentPack.timestamps_S[i] < currentTime) {
                itemsReady++;
              }
            }
          }
        }

        if (type === "listening") {
          if (currentPack.timestamps_L) {
            for (let i = 0; i < currentPack.timestamps_L.length; i++) {
              if (currentPack.timestamps_L[i] < currentTime) {
                itemsReady++;
              }
            }
          }
        }

        setItemsReadyState(itemsReady);
      }
    }
  }, [activePacks, id, setCurrentPack, type]);

  // useEffect to update weeklyStats info
  useEffect(() => {
    // THERE MIGHT BE A BETTER WAY TO UPDATE THESE STATS
    if (weeklyStats) {
      if (weeklyStats.t) {
        console.log("updating weekly stats...");
        // calculate totals for week
        let t = weeklyStats.t;
        let lp = weeklyStats.lp;
        let cp = weeklyStats.cp;

        // incase values aren't defined
        if (!t) {
          t = [];
        }
        if (!lp) {
          lp = [];
        }
        if (!cp) {
          cp = [];
        }
        // const reducer = (accumulator, currentValue) => accumulator + currentValue;

        let sessionLearningPoints = 0;
        let sessionConsolidationPoints = 0;

        const currentDate = new Date();
        const currentTime = Date.now();
        const currentDay = currentDate.getDay();

        for (let i = 0; i < practiseItem.length; i++) {
          if (practiseItem[i] === "learning") {
            sessionLearningPoints++;
          } else {
            sessionConsolidationPoints++;
          }
        }

        // calculate session time practiced
        const sessionLength = currentTime - startTime;
        const todayLength = t[currentDay];

        let lpToday = lp[currentDay];
        let cpToday = cp[currentDay];

        // if there aren't any stats recorded yet for today set them to 0
        if (isNaN(lpToday)) {
          lpToday = 0;
        }

        if (isNaN(cpToday)) {
          cpToday = 0;
        }

        setDailyStatsTotals({
          sessionLearningPoints,
          sessionConsolidationPoints,
          lpTotal: lpToday,
          cpTotal: cpToday,
          tTotal: formatTime(todayLength, true),
          sessionLength: formatTime(sessionLength, true),
        });
      } else {
        console.log("time stat does not exist...");
      }
    } else {
      console.log("no stats available...");
    }
  }, [weeklyStats, startTime, practiseItem, setDailyStatsTotals]);

  // function to process timestamps
  const itemsReady = (type, context, currentPack) => {
    const itemsTextColor = type === "speaking" ? light_grey_3 : light_grey_1;
    const itemsBg = type === "speaking" ? yellow_1 : blue_1;
    const itemsColor = type === "speaking" ? "black" : "white";

    let circleSize;
    let itemsReadyTextSize;
    let textSize;
    let tick;

    if (context === "evaluation") {
      circleSize = "3.2rem";
      itemsReadyTextSize = "1.3rem";
      textSize = "1.1rem";
    }

    const currentTime = Date.now();
    let itemsReady = 0;
    let learning;

    if (type === "speaking") {
      tick = iconTickBlack;

      if (currentPack.timestamps_S) {
        for (let i = 0; i < currentPack.timestamps_S.length; i++) {
          if (currentPack.timestamps_S[i] < currentTime) {
            itemsReady++;
          }
        }
      }

      if (currentPack.learning_S) {
        learning = currentPack.learning_S;
      }
    }

    if (type === "listening") {
      tick = iconTick;

      if (currentPack.timestamps_L) {
        for (let i = 0; i < currentPack.timestamps_L.length; i++) {
          if (currentPack.timestamps_L[i] < currentTime) {
            itemsReady++;
          }
        }
      }

      if (currentPack.learning_L) {
        learning = currentPack.learning_L;
      }
    }

    // if there are no items to practice
    if (itemsReady === 0 && learning === 100) {
      //
      return (
        <PackDetailsItemsReadyContainer
          fontSize={textSize}
          color={itemsTextColor}
        >
          <PackItemsReadyDetailDisplay
            fontSize={itemsReadyTextSize}
            width={circleSize}
            height={circleSize}
            background={itemsBg}
            color={itemsColor}
          >
            <Img width="2rem" src={tick} />
          </PackItemsReadyDetailDisplay>
          <HorzSpacer />
          No items ready to be practiced
        </PackDetailsItemsReadyContainer>
      );
    }

    return (
      <PackDetailsItemsReadyContainer
        fontSize={textSize}
        color={itemsTextColor}
      >
        <PackItemsReadyDetailDisplay
          fontSize={itemsReadyTextSize}
          width={circleSize}
          height={circleSize}
          background={itemsBg}
          color={itemsColor}
        >
          {itemsReady}
        </PackItemsReadyDetailDisplay>
        <HorzSpacer />
        {itemsReady === 1
          ? "item ready to be revised"
          : "items ready to be revised"}
      </PackDetailsItemsReadyContainer>
    );
  };

  const progressBar = (type, currentPack) => {
    let learningAmount = 0;
    let consolidationAmount = 0;
    // let itemsReady = 0;
    let background = "light-practice";

    if (type === "speaking") {
      learningAmount = currentPack.learning_S;
      consolidationAmount = currentPack.consolidation_S;
      background = "light-practice";
    }

    if (type === "listening") {
      learningAmount = currentPack.learning_L;
      consolidationAmount = currentPack.consolidation_L;
      background = "dark";
    }

    // return progress bar
    return (
      <React.Fragment>
        <div style={{ height: "1rem", width: "100%" }}>
          <CompletionBar
            type={type}
            strokeWidth=".16rem"
            background={background}
            learning={learningAmount}
            consolidating={consolidationAmount}
          />
        </div>
        <Spacer />
      </React.Fragment>
    );
  };

  // play function
  const playSound = useCallback(
    (buttonType, itemNumber) => {
      // prevent running function if we're at the end of session
      if (practiseItem.length > practiseLength - 1) {
        return;
      }

      // base audio
      if (buttonType === "main" && !baseDuration) {
        // assign audio depending on practise type
        let file;
        if (type === "speaking") {
          file = audioFilesBase[itemNumber];
        }
        if (type === "listening") {
          file = audioFilesTarget[itemNumber];
        }

        // show audioTarget button
        setTimeout(() => {
          setShowPractiseElements({
            ...practiseElementsRef.current,
            audioTargetButton: true,
          });
        }, file.duration * 1000 + 500);

        file.play();
        setBaseDuration(file.duration);

        setTimeout(() => {
          setBaseDuration(null);
        }, file.duration * 1000);
      }

      // target audio
      if (buttonType === "secondary" && !targetDuration) {
        // show eval buttons
        let evalType;
        let buttonPosition;

        // assign audio depending on practise type
        let file;
        if (type === "speaking") {
          file = audioFilesTarget[itemNumber];
        }
        if (type === "listening") {
          file = audioFilesBase[itemNumber];
        }

        if (!showPractiseElements.evalButtons) {
          // practicing item for first time
          if (!practiseProgressItems[itemNumber][tsType]) {
            evalType = "learn";
            buttonPosition = "learn";
          }

          // already practiced item
          if (practiseProgressItems[itemNumber][tsType]) {
            evalType = "both";
            buttonPosition = "both";
          }

          setTimeout(() => {
            setShowPractiseElements({
              ...showPractiseElements,
              evalButtons: evalType,
              buttonPosition,
            });
          }, file.duration * 1000);
        }

        file.play();
        setTargetDuration(file.duration);
        setTimeout(() => {
          setTargetDuration(null);
        }, file.duration * 1000);
      }
    },
    [
      audioFilesBase,
      audioFilesTarget,
      baseDuration,
      practiseItem.length,
      practiseProgressItems,
      practiseLength,
      showPractiseElements,
      targetDuration,
      tsType,
      type,
    ]
  );

  // play base audio on session start
  // this is deactivated atm because it creates an error in Safari
  if (sessionStart) {
    //playSound('main', practiseItem.length);
    // set sessionStart to false so it doesn't play again
    console.log("SETTING START TIME");
    const currentTime = Date.now();
    setStartTime(currentTime);

    // preload audiofiles!!! CAN I DO THIS?
    // console.log("audio base files " + audioFilesBase);

    // for (let i = 0; i < audioFilesBase.length; i++){
    //   audio
    // }
    //   const img = new Image();
    //   img.src = image;
    //   img.onload = () => console.log(`${image} has loaded.`);
    // });

    setSessionStart(false);
  }

  // initialise audioFilesBase
  if (!audioFilesBase) {
    console.log("loading base files");
    let arr = [];

    const baseFilesLoading = [];
    // const baseFilesLoaded = [];

    for (let i = 0; i < practiseContentItems.length; i++) {
      const audioIndex = Math.floor(
        Math.random() * practiseContentItems[i].audioBase.length
      );

      let audioFile;
      // little fix for the new files as objects
      if (typeof practiseContentItems[i].audioBase[audioIndex] === "object") {
        audioFile = new Audio(
          practiseContentItems[i].audioBase[audioIndex].url
        );
      } else {
        audioFile = new Audio(practiseContentItems[i].audioBase[audioIndex]);
      }

      baseFilesLoading.push(i);

      // had to remove the event listener so it didn't trigger a second time and crash the app
      const setLoaded = () => {
        setFilesLoaded((s) => s + 1);
        audioFile.removeEventListener("canplaythrough", setLoaded);
      };

      audioFile.load();
      audioFile.addEventListener("canplaythrough", setLoaded);

      arr.push(audioFile);
    }

    // add to files loading state array
    setFilesLoading((s) => s + baseFilesLoading.length);
    setAudioFilesBase(arr);

    // update start so base audio plays
    setTimeout(() => {
      setSessionStart(true);
    }, 500);
  }

  // initialise audioFilesTarget
  if (!audioFilesTarget) {
    console.log("loading target files");
    let arr = [];

    const targetFilesLoading = [];

    for (let i = 0; i < practiseContentItems.length; i++) {
      const audioIndex = Math.floor(
        Math.random() * practiseContentItems[i].audioTarget.length
      );

      let audioFile;
      // little fix for the new files as objects
      if (typeof practiseContentItems[i].audioTarget[audioIndex] === "object") {
        audioFile = new Audio(
          practiseContentItems[i].audioTarget[audioIndex].url
        );
      } else {
        audioFile = new Audio(practiseContentItems[i].audioTarget[audioIndex]);
      }

      targetFilesLoading.push(i);

      // had to remove the event listener so it didn't trigger a second time and crash the app
      const setLoaded = () => {
        setFilesLoaded((s) => s + 1);
        audioFile.removeEventListener("canplaythrough", setLoaded);
      };

      audioFile.load();
      audioFile.addEventListener("canplaythrough", setLoaded);

      arr.push(audioFile);
    }

    // add to files loading state array
    setFilesLoading((s) => s + targetFilesLoading.length);
    setAudioFilesTarget(arr);
  }

  // useEffect to replace evaluateItem function
  useEffect(() => {
    if (evaluate !== null) {
      const obj = {
        ...showPractiseElements,
        audioBaseButton: false,
        audioTargetButton: false,
        evalButtons: null,
        evalResult: evaluate,
      };

      // hide everything, show the evalResult type
      setShowPractiseElements({
        ...obj,
      });

      // using a timeout here so the eval buttons don't jump position
      setTimeout(() => {
        setShowPractiseElements({
          ...obj,
          buttonPosition: null,
        });
      }, 500);

      // add type to practiseItem state
      setPractiseItem([...practiseItem, evaluate]);

      // if length is 9 that means that this is the 10th call, so we need to evaluate
      if (practiseItem.length === practiseLength - 1) {
        evaluatePractiseSession(
          practiseProgressItems,
          [...practiseItem, evaluate],
          packDetailsDisplay.id,
          currentPackProgress,
          progressId,
          currentLanguagePair,
          activePacks,
          userid,
          startTime,
          timePracticed,
          lastPracticed,
          currentStreak,
          learningPoints,
          consolidationPoints,
          type,
          weeklyStats,
          levels,
          currentLevel,
          levelType,
          packs,
          loadedPacks,
          forceLevelUp
        );
      }

      // setTimeouts to reset buttons or remove everything if it's the end of the session
      if (practiseItem.length < practiseLength - 1) {
        setEvaluate(null);
        setTimeout(
          () =>
            setShowPractiseElements({
              ...showPractiseElements,
              audioBaseButton: true,
              audioTargetButton: false,
              evalButtons: null,
              evalResult: null,
            }),
          1200
        );

        // Autoplay if it's not mobile...
        if (!isMobile) {
          setTimeout(() => {
            // need to plus one because practiseItem isn't updated yet
            playSound("main", practiseItem.length + 1);
          }, 1500);
        }
      } else {
        setEvaluate(null);
        setTimeout(
          () =>
            setShowPractiseElements({
              audioBaseButton: false,
              audioTargetButton: false,
              evalButtons: null,
              evalResult: null,
              practiseTopBar: false,
              practiseScreen: false,
            }),
          1200
        );
      }
    }
  }, [
    evaluate,
    setEvaluate,
    setShowPractiseElements,
    evaluatePractiseSession,
    setPractiseItem,
    practiseProgressItems,
    practiseItem,
    packDetailsDisplay.id,
    currentPackProgress,
    progressId,
    currentLanguagePair,
    activePacks,
    userid,
    startTime,
    timePracticed,
    practiseLength,
    lastPracticed,
    currentStreak,
    learningPoints,
    consolidationPoints,
    type,
    weeklyStats,
    levels,
    currentLevel,
    levelType,
    packs,
    loadedPacks,
    playSound,
    showPractiseElements,
    forceLevelUp,
  ]);

  // LEGACY CODE
  // learning + consolidating functions
  // const evaluateItem = (evaluate) => {
  //   // hide everything, show the evalResult type
  //   setShowPractiseElements({
  //     ...showPractiseElements,
  //     audioBaseButton: false,
  //     audioTargetButton: false,
  //     evalButtons: null,
  //     evalResult: evaluate,
  //   });

  //   // add type to practiseItem state
  //   setPractiseItem([...practiseItem, evaluate]);

  //   // if length is 9 that means that this is the 10th call, so we need to evaluate
  //   if (practiseItem.length === 9) {
  //     evaluatePractiseSession(
  //       practiseProgressItems,
  //       [...practiseItem, evaluate],
  //       packDetailsDisplay.id,
  //       currentPackProgress,
  //       progressId,
  //       currentLanguagePair,
  //       activePacks,
  //       userid,
  //       startTime.current,
  //       timePracticed,
  //       lastPracticed,
  //       currentStreak,
  //       learningPoints,
  //       consolidationPoints,
  //       type,
  //       weeklyStats
  //     );
  //   }

  //   // setTimeouts to reset buttons or remove everything if it's the end of the session
  //   if (practiseItem.length < 9) {
  //     setTimeout(
  //       () =>
  //         setShowPractiseElements({
  //           ...showPractiseElements,
  //           audioBaseButton: true,
  //           audioTargetButton: false,
  //           evalButtons: null,
  //           evalResult: null,
  //         }),
  //       1200
  //     );
  //     setTimeout(() => {
  //       // need to plus one because practiseItem isn't updated yet
  //       playSound("main", practiseItem.length + 1);
  //     }, 1500);
  //   } else {
  //     setTimeout(
  //       () =>
  //         setShowPractiseElements({
  //           audioBaseButton: false,
  //           audioTargetButton: false,
  //           evalButtons: null,
  //           evalResult: null,
  //           practiseTopBar: false,
  //           practiseScreen: false,
  //         }),
  //       1200
  //     );
  //   }
  // };

  // function to display level stats if level up
  const levelStats = () => {
    let pack;
    // should be -1 but I'm doing -2 to preview it
    const index = currentLevel - 1;

    for (let i = 0; i < activePacks.length; i++) {
      if (activePacks[i]._id === id) {
        pack = activePacks[i];
        break;
      }
    }

    // function to get percentage
    const getPercentage = (mainValue, otherValue) => {
      const pointsTotal = mainValue + otherValue;
      return Math.round((mainValue / pointsTotal) * 100);
    };

    let lp;
    let cp;
    let t;

    let borderColor;
    let textColor;
    let tick;
    let title;
    let barType;
    let backgroundColor;

    if (type === "speaking") {
      lp = pack.learningPointsLevels_S[index];
      cp = pack.consolidationPointsLevels_S[index];
      t = pack.timePracticedLevels_S[index];

      borderColor = "none";
      tick = iconTickYellowSmall;
      title = "Speaking";
      barType = "statsEvalSpeaking";
      backgroundColor = blue_1;
    }

    if (type === "listening") {
      lp = pack.learningPointsLevels_L[index];
      cp = pack.consolidationPointsLevels_L[index];
      t = pack.timePracticedLevels_L[index];

      borderColor = "none";
      textColor = "white";
      tick = iconTickBlueSmall;
      title = "Listening";
      barType = "statsLevelListening";
    }

    const learningPercentage = getPercentage(lp, cp);
    const consolidationPercentage = getPercentage(cp, lp);

    return (
      <PackDetailsStatsLevelContainer
        key={"stats" + type + index}
        borderColor={borderColor}
        background={backgroundColor}
      >
        <PackDetailsStatsSection justifyContent="center">
          <Spacer height=".2rem" />

          <EvalStatsLevelText color={textColor}>
            Level {index + 1} {title}
          </EvalStatsLevelText>
          <Img src={tick} width="1.2rem" top="0rem" left=".4rem" />
        </PackDetailsStatsSection>

        <Spacer />

        <PackDetailsStatsSection>
          <PackDetailsStatsSplitContainer>
            <PackDetailsStatsSection justifyContent="center">
              <PackDetailStatsText>Learning</PackDetailStatsText>
            </PackDetailsStatsSection>
            <PackDetailsStatsSection justifyContent="center">
              <PackDetailStatsText>
                <EvalStatsTextBold>{learningPercentage}%</EvalStatsTextBold>
              </PackDetailStatsText>
            </PackDetailsStatsSection>
          </PackDetailsStatsSplitContainer>
          <PackDetailsStatsSplitContainer>
            <PackDetailsStatsSection justifyContent="center">
              <PackDetailStatsText>Consolidation</PackDetailStatsText>
            </PackDetailsStatsSection>
            <PackDetailsStatsSection justifyContent="center">
              <PackDetailStatsText>
                <EvalStatsTextBold>
                  {consolidationPercentage}%
                </EvalStatsTextBold>
              </PackDetailStatsText>
            </PackDetailsStatsSection>
          </PackDetailsStatsSplitContainer>
        </PackDetailsStatsSection>

        <Spacer height=".5rem" />

        <PackDetailsStatsSection justifyContent="center">
          <div style={{ height: "1rem", width: "100%" }}>
            <CompletionBar
              type={barType}
              strokeWidth=".16rem"
              background="dark"
              learning={learningPercentage}
              consolidating={learningPercentage}
            />
          </div>
        </PackDetailsStatsSection>

        <Spacer height=".8rem" />

        <PackDetailsStatsSection>
          <PackDetailStatsText>
            Learning points
            <PackDetailsStatsTextBold> {lp}</PackDetailsStatsTextBold>
          </PackDetailStatsText>

          <PackDetailStatsText>
            Consolidation points
            <PackDetailsStatsTextBold> {cp}</PackDetailsStatsTextBold>
          </PackDetailStatsText>

          <PackDetailStatsText>
            Time practiced{" "}
            <PackDetailsStatsTextBold>{formatTime(t)}</PackDetailsStatsTextBold>
          </PackDetailStatsText>

          <PackDetailStatsText>
            Practise sessions
            <PackDetailsStatsTextBold>
              {" "}
              {Math.floor((lp + cp) / 10)}
            </PackDetailsStatsTextBold>
          </PackDetailStatsText>
        </PackDetailsStatsSection>
        <Spacer height=".5rem" />
      </PackDetailsStatsLevelContainer>
    );
  };

  // generate items to review at the end

  const reviewItems = () => {
    let items = [];

    for (let i = 0; i < practiseItem.length; i++) {
      items.push(
        <PractiseItemReviewContainer key={"item_" + i}>
          {practiseItem[i] === "learning" ? (
            <Img src={icon_learning} width="12%" minWidth="1.3rem" />
          ) : (
            <Img src={icon_consolidating} width="12%" minWidth="1.3rem" />
          )}
          <PractiseItemReviewTextContainer color={reviewTextColor}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => audioFilesBase[i].play()}
            >
              {practise.practiseContentItems[i].textBase}
            </div>
            <Spacer height=".2rem" />
            <div
              style={{ cursor: "pointer" }}
              onClick={() => audioFilesTarget[i].play()}
            >
              {practise.practiseContentItems[i].textTarget}
            </div>
            <Spacer height=".6rem" />
          </PractiseItemReviewTextContainer>
        </PractiseItemReviewContainer>
      );
    }
    return items;
  };

  return (
    <React.Fragment>
      {pageLoaded === 100 ? (
        <PractiseSiteContainer>
          {/* Dev buttons */}
          {/* <div style={{ position: "absolute", top: 0, left: 0 }}>
            <button onClick={() => setEvaluate("learning")}>✗</button>
            <br />
            <button onClick={() => setEvaluate("consolidating")}>✓</button>
            <br />
            {forceLevelUp ? (
              <button onClick={() => setForceLevelUp(false)}>
                force level up
              </button>
            ) : (
              <button onClick={() => setForceLevelUp(true)}>
                don't force level up
              </button>
            )}
          </div> */}

          {/* Consolidating pop-up */}
          <AnimatePresence>
            {showPractiseElements.evalResult === "consolidating" ? (
              <DisplayEvaluationContainer
                variants={practiseButtonVariants}
                initial="initial"
                animate="visible"
                exit="exit"
              >
                <Img src={icon_consolidating} width="20rem" />
              </DisplayEvaluationContainer>
            ) : null}
          </AnimatePresence>

          {/* Learning pop-up */}
          <AnimatePresence>
            {showPractiseElements.evalResult === "learning" ? (
              <DisplayEvaluationContainer
                variants={practiseButtonVariants}
                initial="initial"
                animate="visible"
                exit="exit"
              >
                <Img src={icon_learning} width="20rem" />
              </DisplayEvaluationContainer>
            ) : null}
          </AnimatePresence>

          {/* set background color based on practise type */}
          <style jsx="true">{`
            body {
              margin: 0px;
              background: ${bgColor};
            }
          `}</style>

          {/* Screen dim */}
          <AnimatePresence>
            {promptDisplay === "exit" || promptDisplay === "flag" ? (
              <DisplayDim
                variants={screenDimVariants}
                initial="initial"
                animate="visible"
                exit="exit"
                onClick={() => setPromptDisplay(null)}
              />
            ) : null}
          </AnimatePresence>

          {/* Exit prompt */}
          <AnimatePresence>
            {promptDisplay === "exit" ? (
              <ExitPractisePrompt
                variants={displayPromptVariants}
                initial="initial"
                animate="visible"
                exit="exit"
              >
                <ExitSessionText>Are you sure?</ExitSessionText>
                <ExitSessionText>
                  Any progress made in this session won't be saved.
                </ExitSessionText>
                <Spacer height="1rem" />
                <ExitSessionButton
                  onClick={() => {
                    removeListeners();
                    history.push("/dashboard");
                  }}
                >
                  Exit session
                </ExitSessionButton>
              </ExitPractisePrompt>
            ) : null}
          </AnimatePresence>

          {/* Flag sentence prompt */}
          <AnimatePresence>
            {promptDisplay === "flag" ? (
              <ExitPractisePrompt
                variants={displayPromptVariants}
                initial="initial"
                animate="visible"
                exit="exit"
              >
                <ExitSessionText>Report a problem:</ExitSessionText>
                <Spacer height="1rem" />
                <ReportSessionButton
                  onClick={() => console.log("sentence flagged!")}
                >
                  The translation doesn't match.
                </ReportSessionButton>
                <Spacer height=".6rem" />
                <ReportSessionButton
                  onClick={() => console.log("sentence flagged!")}
                >
                  The audio is low quality.
                </ReportSessionButton>
                <Spacer height=".6rem" />
                <ReportSessionButton
                  onClick={() => console.log("sentence flagged!")}
                >
                  The audio isn't playing.
                </ReportSessionButton>
                <Spacer height=".6rem" />
                <ReportSessionButton
                  onClick={() => console.log("sentence flagged!")}
                >
                  The translation should be...
                </ReportSessionButton>
                <Spacer height="1.4rem" />
                <ExitSessionButton
                  onClick={() => console.log("sentence flagged!")}
                >
                  Submit
                </ExitSessionButton>
              </ExitPractisePrompt>
            ) : null}
          </AnimatePresence>

          {/* Evaluation screen */}
          <AnimatePresence>
            {practiseItem.length === practiseLength ? (
              <EvaluationContainer
                key="evaluation"
                variants={evalScreenVariants}
                initial="initial"
                animate="visible"
                exit="exit"
              >
                <Spacer height="3rem" />
                <PractiseTopContainer justifyContent="center">
                  <Img src={ladyBurst} width="100%" maxWidth="400px" />
                </PractiseTopContainer>
                <Spacer />

                {/* if the user has levelled up then display level stats */}
                {levelUp ? (
                  <EvaluationCenterContainer>
                    <Spacer height="1rem" />
                    <EvaluationText
                      color={type === "speaking" ? blue_1 : yellow_1}
                    >
                      Nice work {name}!<br /> You just levelled up!
                    </EvaluationText>
                    <Spacer height="1.4rem" />
                    {levelStats()}
                    <Spacer height="2rem" />
                  </EvaluationCenterContainer>
                ) : null}

                {/* pack completion - no levels */}
                {packCompleted && levels === 0 ? (
                  <EvaluationCenterContainer>
                    <Spacer height="1rem" />
                    <EvaluationText
                      color={type === "speaking" ? blue_1 : yellow_1}
                    >
                      Great work {name}!<br /> You've completed the{" "}
                      {type === "speaking" ? "speaking" : "listening"} part of
                      this pack.
                    </EvaluationText>
                    <Spacer height="1.4rem" />
                  </EvaluationCenterContainer>
                ) : null}

                {/* pack completion - with levels */}
                {packCompleted && levels > 0 ? (
                  <EvaluationCenterContainer>
                    <Spacer height="1rem" />
                    <EvaluationText
                      color={type === "speaking" ? blue_1 : yellow_1}
                    >
                      Awesome {name}!<br /> You've completed all the{" "}
                      {type === "speaking" ? "speaking" : "listening"} levels.
                    </EvaluationText>
                    <Spacer height="1.4rem" />
                    {levelStats()}
                    <Spacer height="2rem" />
                  </EvaluationCenterContainer>
                ) : null}

                <PractiseTopContainer style={{ justifyContent: "flex-end" }}>
                  <EvaluationTextSmall
                    color={type === "speaking" ? light_grey_3 : light_grey_1}
                  >
                    session / today
                  </EvaluationTextSmall>
                  <Spacer height=".7rem" />
                </PractiseTopContainer>

                <PractiseTopContainer
                  style={{ justifyContent: "space-between" }}
                >
                  <EvaluationTextStats
                    color={type === "speaking" ? light_grey_3 : light_grey_1}
                  >
                    Learning points
                  </EvaluationTextStats>{" "}
                  <EvaluationTextStats
                    color={type === "speaking" ? light_grey_3 : light_grey_1}
                  >
                    <BlueHighlight
                      color={type === "speaking" ? blue_1 : yellow_1}
                    >
                      {dailyStatsTotals.sessionLearningPoints}
                    </BlueHighlight>{" "}
                    / {dailyStatsTotals.lpTotal}{" "}
                  </EvaluationTextStats>
                </PractiseTopContainer>
                <EvaluationLine
                  color={type === "speaking" ? light_grey_2 : blue_3}
                />
                <PractiseTopContainer
                  style={{ justifyContent: "space-between" }}
                >
                  <EvaluationTextStats
                    color={type === "speaking" ? light_grey_3 : light_grey_1}
                  >
                    Consolidation points
                  </EvaluationTextStats>
                  <EvaluationTextStats
                    color={type === "speaking" ? light_grey_3 : light_grey_1}
                  >
                    <BlueHighlight
                      color={type === "speaking" ? blue_1 : yellow_1}
                    >
                      {dailyStatsTotals.sessionConsolidationPoints}
                    </BlueHighlight>{" "}
                    / {dailyStatsTotals.cpTotal} <br />
                  </EvaluationTextStats>
                </PractiseTopContainer>
                <EvaluationLine
                  color={type === "speaking" ? light_grey_2 : blue_3}
                />
                <PractiseTopContainer
                  style={{ justifyContent: "space-between" }}
                >
                  <EvaluationTextStats
                    color={type === "speaking" ? light_grey_3 : light_grey_1}
                  >
                    Time practiced
                  </EvaluationTextStats>
                  <EvaluationTextStats
                    color={type === "speaking" ? light_grey_3 : light_grey_1}
                  >
                    <BlueHighlight
                      color={type === "speaking" ? blue_1 : yellow_1}
                    >
                      {dailyStatsTotals.sessionLength}
                    </BlueHighlight>{" "}
                    / {dailyStatsTotals.tTotal}
                  </EvaluationTextStats>
                </PractiseTopContainer>
                <Spacer height="3rem" />

                <PractiseTopContainer justifyContent="center">
                  <FinishSessionButton
                    bgHover={finishBgHover}
                    onClick={() => history.push("/dashboard")}
                  >
                    Finish Session
                  </FinishSessionButton>
                </PractiseTopContainer>

                {/* Hide the Practise Again button if there's nothing to practise */}
                {currentPack[learningType] === 100 &&
                itemsReadyState === 0 ? null : (
                  <>
                    <Spacer />
                    <PractiseTopContainer justifyContent="center">
                      <PractiseAgainButton
                        onClick={() => setStartPractise(type)}
                      >
                        Practise Again
                      </PractiseAgainButton>
                    </PractiseTopContainer>
                  </>
                )}

                <Spacer />
                <HorzSpacer width=".5rem" />
                {itemsReady(type, "evaluation", currentPack)}

                <Spacer height=".8rem" />

                {progressBar(type, currentPack)}

                <Spacer height="2rem" />

                <PractiseReviewContainer>
                  {reviewItems()}
                </PractiseReviewContainer>
                <Spacer height="6rem" />
              </EvaluationContainer>
            ) : null}
          </AnimatePresence>

          {/* Practise screen */}
          {showPractiseElements.practiseScreen ? (
            <PractiseContainer>
              {/* Practise screen top bar */}
              <AnimatePresence
                onExitComplete={() =>
                  setShowPractiseElements({
                    ...showPractiseElements,
                    practiseScreen: false,
                  })
                }
              >
                {showPractiseElements.practiseTopBar ? (
                  <PractiseTopContainer
                    key="top-bar"
                    height="20%"
                    variants={topBarVariants}
                    initial="initial"
                    animate="visible"
                    exit="exit"
                  >
                    <PractiseGutterContainer>
                      {/* Exit practise button */}
                      <ButtonEmptyPractice
                        onClick={() => {
                          setPromptDisplay("exit");
                        }}
                      >
                        <Img width="3rem" src={icon_exit} />
                      </ButtonEmptyPractice>
                    </PractiseGutterContainer>
                    <PractiseMainContainer>
                      <CompletionBarContainer
                        top=".3rem"
                        height="2rem"
                        width="100%"
                      >
                        <svg
                          viewBox="0 0 100 5"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <line
                            x1="3"
                            y1="2"
                            x2="97"
                            y2="2"
                            stroke={progressBgColor}
                            strokeWidth=".17rem"
                            strokeLinecap="round"
                          />
                          hey
                          <line
                            x1="3"
                            y1="2"
                            x2={completionBarAmount}
                            y2="2"
                            stroke="#489CEF"
                            strokeWidth=".17rem"
                            strokeLinecap="round"
                          />
                        </svg>
                      </CompletionBarContainer>
                    </PractiseMainContainer>
                    <PractiseGutterContainer>
                      {/* Flag item button */}
                      <ButtonEmpty onClick={() => setPromptDisplay("flag")}>
                        <Img width="1.5rem" src={icon_flag} />
                      </ButtonEmpty>
                    </PractiseGutterContainer>
                  </PractiseTopContainer>
                ) : null}
              </AnimatePresence>

              {/* Practise screen play buttons */}
              <PractiseTopContainer height="60%" margin="2rem 0 0 0">
                {/* <PractiseGutterContainer /> */}
                <PractiseMainContainer alignItems="flex-start">
                  <WidthContainer width="65%" justifyContent="flex-end">
                    <CustomContainer
                      ref={playBaseRef}
                      width="100%"
                      height="100%"
                    >
                      {/* Audio base button */}
                      <AnimatePresence>
                        {showPractiseElements.audioBaseButton ? (
                          <SpeechButtonMain
                            key="speech-button"
                            variants={practiseButtonVariants}
                            initial="initial"
                            animate="visible"
                            exit="exit"
                            onClick={() =>
                              playSound("main", practiseItem.length)
                            }
                          >
                            <div
                              style={{
                                position: "relative",
                                top: playBaseRef.current
                                  ? playBaseRef.current.offsetWidth / 2
                                  : "27vw",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",

                                height: "100%",
                                width: "100%",
                              }}
                            >
                              <AnimatePresence exitBeforeEnter>
                                {!baseDuration ? (
                                  <SpeechButtonIcon
                                    key="play-icon"
                                    variants={practiseButtonVariants}
                                    initial="initial"
                                    animate="visible"
                                    exit="exit"
                                    width="13vw"
                                    maxWidth="7rem"
                                    src={iconPlay}
                                  />
                                ) : (
                                  <SpeechButtonIcon
                                    key="dots-icon"
                                    variants={practiseButtonVariants}
                                    initial="initial"
                                    animate="visible"
                                    exit="exit"
                                    width="26vw"
                                    maxWidth="14rem"
                                    src={practiseDots}
                                  />
                                )}
                              </AnimatePresence>
                            </div>

                            {/* mask for safari */}
                            <SpeechButtonPlayHeadMask>
                              {type === "speaking" ? (
                                <Img src={maskLightGrey} width="100%" />
                              ) : null}
                              {type === "listening" ? (
                                <Img src={maskBlue} width="100%" />
                              ) : null}
                            </SpeechButtonPlayHeadMask>

                            <AnimatePresence>
                              {baseDuration ? (
                                <SpeechButtonPlayHead
                                  variants={playHead}
                                  initial="initial"
                                  animate="visible"
                                  exit="exit"
                                  background={playheadBg}
                                />
                              ) : null}
                            </AnimatePresence>
                          </SpeechButtonMain>
                        ) : null}
                      </AnimatePresence>
                    </CustomContainer>
                  </WidthContainer>

                  <WidthContainer width="35%">
                    <CustomContainer
                      ref={playTargetRef}
                      width="100%"
                      height="100%"
                    >
                      {/* Audio target button */}
                      <AnimatePresence>
                        {showPractiseElements.audioTargetButton ? (
                          <SpeechButtonSecondary
                            variants={practiseButtonVariants}
                            initial="initial"
                            animate="visible"
                            exit="exit"
                            onClick={() =>
                              playSound("secondary", practiseItem.length)
                            }
                          >
                            <div
                              style={{
                                position: "relative",
                                top: playTargetRef.current
                                  ? playTargetRef.current.offsetWidth / 2
                                  : "27vw",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",

                                height: "100%",
                                width: "100%",
                              }}
                            >
                              <AnimatePresence exitBeforeEnter>
                                {!targetDuration ? (
                                  <SpeechButtonIcon
                                    key="play-icon"
                                    variants={practiseButtonVariants}
                                    initial="initial"
                                    animate="visible"
                                    exit="exit"
                                    width="6.5vw"
                                    maxWidth="3.5rem"
                                    src={iconPlayBlue}
                                  />
                                ) : (
                                  <SpeechButtonIcon
                                    key="dots-icon"
                                    variants={practiseButtonVariants}
                                    initial="initial"
                                    animate="visible"
                                    exit="exit"
                                    width="13vw"
                                    maxWidth="7rem"
                                    src={practiseDotsBlue}
                                    background={playheadSecondaryBg}
                                  />
                                )}
                              </AnimatePresence>
                            </div>

                            {/* mask for safari */}
                            <SpeechButtonPlayHeadMask zIndex="101">
                              {type === "speaking" ? (
                                <Img src={maskLightGrey} width="100%" />
                              ) : null}
                              {type === "listening" ? (
                                <Img src={maskBlue} width="100%" />
                              ) : null}
                            </SpeechButtonPlayHeadMask>

                            <AnimatePresence>
                              {targetDuration ? (
                                <SpeechButtonSecondaryPlayHead
                                  variants={playHeadSecondary}
                                  initial="initial"
                                  animate="visible"
                                  exit="exit"
                                />
                              ) : null}
                            </AnimatePresence>
                          </SpeechButtonSecondary>
                        ) : null}
                      </AnimatePresence>
                    </CustomContainer>
                  </WidthContainer>
                </PractiseMainContainer>
                {/* <PractiseGutterContainer /> */}
              </PractiseTopContainer>

              {/* Evaluation buttons */}
              <PractiseTopContainer height="20%" margin="3rem 0 0 0">
                {/* <PractiseGutterContainer /> */}
                <PractiseMainContainer
                  justifyContent={
                    showPractiseElements.buttonPosition === "both"
                      ? "space-between"
                      : "center"
                  }
                >
                  {/* learning button only */}
                  <AnimatePresence>
                    {showPractiseElements.evalButtons === "learn" ? (
                      <LearningButton
                        key="button-learn-only"
                        variants={practiseButtonVariants}
                        initial="initial"
                        animate="visible"
                        exit="exit"
                        width="13rem"
                        height="6rem"
                        background={learningBtnBg}
                        onClick={() => setEvaluate("learning")}
                      >
                        <Img width="3rem" src={icon_arrow} />
                      </LearningButton>
                    ) : null}
                  </AnimatePresence>

                  {/* consolidating button */}
                  <AnimatePresence>
                    {showPractiseElements.evalButtons === "both" ? (
                      <ConsolidationButton
                        key="button-consolidate"
                        variants={practiseButtonVariants}
                        initial="initial"
                        animate="visible"
                        exit="exit"
                        width="6rem"
                        height="6rem"
                        background={consolidatingBtnBg}
                        onClick={() => setEvaluate("consolidating")}
                      >
                        <Img width="3rem" src={icon_tick} />
                      </ConsolidationButton>
                    ) : null}

                    {/* {showPractiseElements.evalButtons === "both" ? (
                      <HorzSpacer key="spacer" width="auto" />
                    ) : null} */}

                    {/* learning button */}
                    <AnimatePresence>
                      {showPractiseElements.evalButtons === "both" ? (
                        <LearningButton
                          key="button-learn"
                          variants={practiseButtonVariants}
                          initial="initial"
                          animate="visible"
                          exit="exit"
                          width="6rem"
                          height="6rem"
                          background={learningBtnBg}
                          onClick={() => setEvaluate("learning")}
                        >
                          <Img width="3rem" src={icon_arrow} />
                        </LearningButton>
                      ) : null}
                    </AnimatePresence>
                  </AnimatePresence>
                </PractiseMainContainer>
                {/* <PractiseGutterContainer /> */}
              </PractiseTopContainer>
            </PractiseContainer>
          ) : null}
        </PractiseSiteContainer>
      ) : (
        <SiteContainer>
          {/* set background color based on practise type */}
          <style jsx="true">{`
            body {
              margin: 0px;
              background: ${bgColor};
            }
          `}</style>
          <div
            style={{
              margin: "0 7% 0 7%",
              position: "relative",
              top: "-1rem",
              width: "100%",
              height: "2rem",
            }}
          >
            <CompletionBar
              type="loading"
              strokeWidth=".1rem"
              background={type}
              learning={pageLoaded}
              consolidating="0"
            />
          </div>
        </SiteContainer>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ practise, user, display, content }) => {
  return { practise, user, display, content };
};

export default connect(mapStateToProps, {
  evaluatePractiseSession,
  createPracticeSession,
})(withRouter(Practise));
