import { GET_PACKS, GET_ITEMS, CLEAR_ITEMS } from '../actions/types';

export default function(state = { packs: null, loadedPacks: [] }, action) {
	switch (action.type) {
		case GET_PACKS:
			return { ...state, packs: action.payload } || { ...state, packs: null };
		case GET_ITEMS:
			return { ...state, loadedPacks: [ ...state.loadedPacks, action.payload ] };
		case CLEAR_ITEMS:
			return { ...state, loadedPacks: [] };
		default:
			return state;
	}
}
