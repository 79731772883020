import {
	DISPLAY_PACK_MANAGER,
	DISPLAY_PACK_DETAILS,
	DISPLAY_SETTINGS,
	DISPLAY_CHANGE_LANGUAGE
} from '../actions/types';

export default function(
	state = {
		packManagerDisplay: false,
		packDetailsDisplay: { display: false, loaded: false },
		settingsDisplay: false,
		languagePairDisplay: null,
		changeLanguageDisplay: false
	},
	action
) {
	switch (action.type) {
		case DISPLAY_PACK_MANAGER:
			return { ...state, packManagerDisplay: action.payload };
		case DISPLAY_PACK_DETAILS:
			return { ...state, packDetailsDisplay: { ...state.packDetailsDisplay, ...action.payload } };
		case DISPLAY_SETTINGS:
			return { ...state, settingsDisplay: action.payload };
		case DISPLAY_CHANGE_LANGUAGE:
			return { ...state, changeLanguageDisplay: action.payload };
		default:
			return state;
	}
}
