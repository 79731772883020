import {
  CREATE_PRACTISE_SESSION,
  ADD_LEVELS,
  LEVEL_UP,
  PACK_COMPLETED,
} from "../actions/types";

export default function (
  state = {
    practiseProgressItems: null,
    practiceContentItems: null,
    levels: null,
    currentLevel: null,
    levelType: null,
    levelUp: false,
    packCompleted: false,
  },
  action
) {
  switch (action.type) {
    case CREATE_PRACTISE_SESSION:
      return { ...action.payload };
    case ADD_LEVELS:
      return { ...action.payload };
    case LEVEL_UP:
      return { ...state, ...action.payload };
    case PACK_COMPLETED:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
