import React, { useState, useEffect, useRef, ReactDOM } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import {
  PackDetailPrompt,
  Img,
  GradientContainer,
  PackDetailsContentContainer,
  PackDetailPractiseButton,
  PackDetailPractiseListeningButton,
  PackItemsReadyDetailDisplay,
  PackDetailsItemsReadyContainer,
  PackDetailTitle,
  PackDetailBody,
  PackDetailsStatsContainer,
  PackDetailsStatsSplitContainer,
  PackDetailsStatsSection,
  PackDetailPercentage,
  PackDetailStatsText,
  PackDetailStatsTextCenter,
  PackDetailsStatsTextBold,
  PackDetailsStatsLevelContainer,
  PackDetailsStatsLevelText,
  PracticeDetailLine,
  CustomContainer,
  ScreenLock,
  Spacer,
  HorzSpacer,
  LevelDisplay,
  green_1,
  yellow_1,
  blue_1,
} from "./styles";

import CompletionBar from "../components/CompletionBar";
import { createPracticeSession, displayPackItems } from "../actions";

// images
// import iconTickBlack from "../images/icons/icon_tick_black-01.svg";
// import iconTickWhite from "../images/icons/icon_tick-01.svg";
// import iconTickYellowSmall from "../images/icons/icon_tick_yellow_small-01.svg";
// import iconTickBlueSmall from "../images/icons/icon_tick_blue_small-01.svg";
// import statsTeam from "../images/misc/stats_team-01.svg";

const PackDetails = ({
  display,
  content,
  user,
  createPracticeSession,
  displayPackItems,
  history,
  images,
}) => {
  const { packDetailsDisplay } = display;

  const { id, name, mainImage, description } = packDetailsDisplay;

  const { packs, loadedPacks } = content;
  const { currentPackProgress, activePacks, progressId, currentLanguagePair } =
    user;

  // if pack details isn't being displayed then reset the scroll
  if (!packDetailsDisplay) {
    ReactDOM.findDOMNode(this).scrollLeft = 0;
  }

  const displayPromptVariants = {
    initial: {
      y: "100vh",
      originX: "10.5rem",
      x: "50vw",
    },
    visible: {
      y: "5vh",
      transition: { type: "spring", stiffness: 80, mass: 0.5 },
    },
    exit: {
      y: "100vh",
      transition: { type: "spring", stiffness: 80, mass: 0.5 },
    },
  };

  const imageFadeVariants = {
    initial: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { type: "tween", duration: 0.2 },
    },
  };

  // const imagesArray = [
  //   "../images/icons/icon_tick_black-01.svg",
  //   "../images/icons/icon_tick-01.svg",
  //   "../images/icons/icon_tick_yellow_small-01.svg",
  //   "../images/icons/icon_tick_blue_small-01.svg",
  //   "../images/misc/stats_team-01.svg",
  //   "../images/icons/jagged_divider.svg",
  // ];

  // // preload images
  // useEffect(() => {
  //   imagesArray.forEach((image) => {
  //     new Image().src = image;
  //   });
  // }, [imagesArray]);

  // state holds all of our info
  const [startPractise, setStartPractise] = useState(null);
  const [lockScreen, setLockScreen] = useState(false);

  const [practiseButtonContent, setPractiseButtonContent] = useState();
  const [statsContent, setStatsContent] = useState();
  const [packData, setPackData] = useState({});

  const [mainImageLoaded, setMainImageLoaded] = useState(false);

  const handleImageLoaded = () => {
    setMainImageLoaded(true);
  };

  // function to set up practise buttons
  const practiseButton = (type, pack, pressed) => {
    // console.log("...create practise button");
    // return practise button, completion bar and items ready content
    let text;
    let itemsBg;
    let itemsColor;
    let itemsTextColor;

    let learningAmount = 0;
    let consolidationAmount = 0;
    let itemsReady = 0;

    let completedType;
    let currentLevelType;
    let tick;

    if (type === "speaking") {
      if (pack.learning_S) {
        learningAmount = pack.learning_S;
      }

      consolidationAmount = pack.consolidation_S;

      if (pack.timestamps_S) {
        for (let j = 0; j < pack.timestamps_S.length; j++) {
          if (pack.timestamps_S[j] < Date.now()) {
            itemsReady++;
          }
        }
      }

      tick = images.iconTickBlack;
      completedType = "completed_S";
      currentLevelType = "currentLevel_S";
      text = "Practise speaking";
      itemsBg = "#E7DB03";
      itemsColor = "black";
      itemsTextColor = "#E7DB03";
    }

    if (type === "listening") {
      if (pack.learning_L) {
        learningAmount = pack.learning_L;
      }

      consolidationAmount = pack.consolidation_L;

      if (pack.timestamps_L) {
        for (let j = 0; j < pack.timestamps_L.length; j++) {
          if (pack.timestamps_L[j] < Date.now()) {
            itemsReady++;
          }
        }
      }

      tick = images.iconTickWhite;
      completedType = "completed_L";
      currentLevelType = "currentLevel_L";
      text = "Practise listening";
      itemsBg = "#489CEF";
      itemsColor = "white";
      itemsTextColor = "white";
    }

    if (pressed) {
      text = "Loading session";
    }

    const button = (
      <React.Fragment key={"button-" + type}>
        {/* speaking practise button */}
        {type === "speaking" ? (
          itemsReady > 0 || learningAmount < 100 ? (
            <PackDetailPractiseButton
              onClick={() => {
                setStartPractise(type);
                setPractiseButtonContent((s) => [
                  <PackDetailPractiseButton key="practise-speaking-loading">
                    Loading...
                  </PackDetailPractiseButton>,
                  [s[0][1]],
                  s[1],
                ]);
                // NEED TO FIX THIS UP HERE FOR LOADING A SESSION
                // THIS WORKS BUT I NEED TO HOLD ON TO THE PROGRESS BAR AND ITEMS TO PRACTICE
              }}
            >
              {text}
            </PackDetailPractiseButton>
          ) : null
        ) : null}

        {/* listening practise button */}
        {type === "listening" ? (
          itemsReady > 0 || learningAmount < 100 ? (
            <PackDetailPractiseListeningButton
              onClick={() => {
                setStartPractise(type);
                setPractiseButtonContent((s) => [
                  s[0],
                  <PackDetailPractiseListeningButton key="practise-listening-loading">
                    Loading...
                  </PackDetailPractiseListeningButton>,
                  [s[1][1]],
                ]);
                // NEED TO FIX THIS UP HERE FOR LOADING A SESSION
              }}
            >
              {text}
            </PackDetailPractiseListeningButton>
          ) : null
        ) : null}
      </React.Fragment>
    );

    const bar = (
      <React.Fragment key={"bar-" + type}>
        <Spacer height=".7rem" />
        {itemsReady ? (
          <PackDetailsItemsReadyContainer color={itemsTextColor}>
            <PackItemsReadyDetailDisplay
              background={itemsBg}
              color={itemsColor}
            >
              {itemsReady}
            </PackItemsReadyDetailDisplay>
            <HorzSpacer width=".8rem" />
            {itemsReady === 1
              ? "item ready to be revised"
              : "items ready to be revised"}
          </PackDetailsItemsReadyContainer>
        ) : learningAmount === 100 ? (
          <PackDetailsItemsReadyContainer color={itemsTextColor}>
            <PackItemsReadyDetailDisplay
              background={itemsBg}
              color={itemsColor}
            >
              <Img src={tick} width="1.4rem" />
            </PackItemsReadyDetailDisplay>
            <HorzSpacer width=".8rem" />
            No items ready to be practiced
          </PackDetailsItemsReadyContainer>
        ) : null}
        <Spacer height=".5rem" />
        {learningAmount > 0 ? (
          <React.Fragment>
            <div style={{ height: "1rem", width: "100%" }}>
              <CompletionBar
                type={type}
                strokeWidth=".16rem"
                background="dark"
                learning={learningAmount}
                consolidating={consolidationAmount}
              />
            </div>
            {levelsDisplay(
              pack.levels,
              pack[currentLevelType],
              pack[completedType],
              type
            )}
            <Spacer />
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );

    return [button, bar];

    //setPractiseButtonContent(button);
    //console.log(button);
  };

  const levelsDisplay = (levels, currentLevel, completed, type) => {
    // only displays if pack has more than 0 levels
    if (levels > 0) {
      let levelsCircles = [];

      // set tick color based on type
      // let tick;
      // if (type === "speaking") {
      //   tick = iconTickBlack;
      // }
      // if (type === "listening") {
      //   tick = iconTickWhite;
      // }

      levelsCircles.push(<Spacer key="spacer-start" height=".3rem" />);

      for (let i = 0; i < levels; i++) {
        let width;
        let borderColor;
        let textColor;

        // if it's the currentLevel
        if (i + 1 === currentLevel) {
          width = 2;
          borderColor = green_1;
        }

        // if the level has been completed
        if (i + 1 < currentLevel) {
          width = 2;
          borderColor = yellow_1;
        }

        // if the level hasn't been started
        if (i + 1 > currentLevel) {
          textColor = "rgba(255, 255, 255, 0.35)";
        }

        // if pack is completed
        if (completed && levels > 0) {
          width = 2;
          borderColor = yellow_1;
          textColor = "rgba(255, 255, 255, 0.35)";
        }

        levelsCircles.push(
          <LevelDisplay
            borderWidth={width}
            borderColor={borderColor}
            color={textColor}
            key={i}
          >
            L{i + 1}
          </LevelDisplay>
        );
      }

      if (completed && levels > 0) {
        levelsCircles.push(
          <PackItemsReadyDetailDisplay key="pack-finished">
            <Img src={images.iconTickBlack} width="1.4rem" />
          </PackItemsReadyDetailDisplay>
        );
      }

      levelsCircles.push(<Spacer key="spacer-end" height=".3rem" />);

      return levelsCircles;
    }
  };

  const createStats = (pack) => {
    let lp_S = 0;
    let cp_S = 0;
    let lp_L = 0;
    let cp_L = 0;

    let tp_S = 0;
    let tp_L = 0;

    if (pack.learningPoints_S) {
      lp_S = pack.learningPoints_S;
    }
    if (pack.consolidationPoints_S) {
      cp_S = pack.consolidationPoints_S;
    }
    if (pack.learningPoints_L) {
      lp_L = pack.learningPoints_L;
    }
    if (pack.consolidationPoints_L) {
      cp_L = pack.consolidationPoints_L;
    }

    if (pack.timePracticed_S) {
      tp_S = pack.timePracticed_S;
    }
    if (pack.timePracticed_L) {
      tp_L = pack.timePracticed_L;
    }

    const lpTotal = parseInt(lp_S) + parseInt(lp_L);
    const cpTotal = parseInt(cp_S) + parseInt(cp_L);
    const pointsTotal = lpTotal + cpTotal;

    let timeTotal = parseInt(tp_S) + parseInt(tp_L);

    // function to format time from milliseconds
    const formatTime = (time) => {
      const portions = [];
      const msInHour = 1000 * 60 * 60;
      const hours = Math.trunc(time / msInHour);

      if (hours === 1) {
        portions.push(hours + " hour");
        time = time - hours * msInHour;
      }
      if (hours > 1) {
        portions.push(hours + " hours");
        time = time - hours * msInHour;
      }

      const msInMinute = 1000 * 60;
      const minutes = Math.trunc(time / msInMinute);

      if (minutes === 1) {
        portions.push(minutes + " minute");
      }
      if (minutes > 1) {
        portions.push(minutes + " minutes");
      }

      return portions.join(" ");
    };

    // function to get percentage
    const getPercentage = (mainValue, otherValue) => {
      const pointsTotal = mainValue + otherValue;
      return Math.round((mainValue / pointsTotal) * 100);
    };

    const learningPercentage = getPercentage(lpTotal, cpTotal);
    const consolidationPercentage = getPercentage(cpTotal, lpTotal);

    // function to create level stats
    const createLevelStats = (pack, index, type) => {
      let lp;
      let cp;
      let t;

      let borderColor;
      let textColor;
      let tick;
      let title;
      let barType;

      if (type === "speaking") {
        lp = pack.learningPointsLevels_S[index];
        cp = pack.consolidationPointsLevels_S[index];
        t = pack.timePracticedLevels_S[index];

        tick = images.iconTickYellowSmall;
        title = "Speaking";
        barType = "statsLevelSpeaking";
      }

      if (type === "listening") {
        lp = pack.learningPointsLevels_L[index];
        cp = pack.consolidationPointsLevels_L[index];
        t = pack.timePracticedLevels_L[index];

        borderColor = blue_1;
        textColor = "white";
        tick = images.iconTickBlueSmall;
        title = "Listening";
        barType = "statsLevelListening";
      }

      const learningPercentage = getPercentage(lp, cp);
      const consolidationPercentage = getPercentage(cp, lp);

      return (
        <PackDetailsStatsLevelContainer
          key={"stats" + type + index}
          borderColor={borderColor}
        >
          <PackDetailsStatsSection>
            <PackDetailsStatsLevelText color={textColor}>
              Level {index + 1} {title}
            </PackDetailsStatsLevelText>
            <Img src={tick} width="1.2rem" top="0rem" left=".4rem" />
          </PackDetailsStatsSection>

          <Spacer />

          <PackDetailsStatsSection>
            <PackDetailsStatsSplitContainer>
              <PackDetailsStatsSection justifyContent="center">
                <PackDetailStatsText>
                  <PackDetailsStatsTextBold>
                    {learningPercentage}%
                  </PackDetailsStatsTextBold>
                </PackDetailStatsText>
              </PackDetailsStatsSection>
              <PackDetailsStatsSection justifyContent="center">
                <PackDetailStatsText>Learning</PackDetailStatsText>
              </PackDetailsStatsSection>
            </PackDetailsStatsSplitContainer>
            <PackDetailsStatsSplitContainer>
              <PackDetailsStatsSection justifyContent="center">
                <PackDetailStatsText>
                  <PackDetailsStatsTextBold>
                    {consolidationPercentage}%
                  </PackDetailsStatsTextBold>
                </PackDetailStatsText>
              </PackDetailsStatsSection>
              <PackDetailsStatsSection justifyContent="center">
                <PackDetailStatsText>Consolidation</PackDetailStatsText>
              </PackDetailsStatsSection>
            </PackDetailsStatsSplitContainer>
          </PackDetailsStatsSection>

          <PackDetailsStatsSection justifyContent="center">
            <div style={{ height: "1rem", width: "100%" }}>
              <CompletionBar
                type={barType}
                strokeWidth=".16rem"
                background="dark"
                learning={learningPercentage}
                consolidating={learningPercentage}
              />
            </div>
          </PackDetailsStatsSection>

          <Spacer />

          <PackDetailsStatsSection>
            <PackDetailStatsText>
              Learning points
              <PackDetailsStatsTextBold> {lp}</PackDetailsStatsTextBold>
            </PackDetailStatsText>

            <PackDetailStatsText>
              Consolidation points
              <PackDetailsStatsTextBold> {cp}</PackDetailsStatsTextBold>
            </PackDetailStatsText>

            <PackDetailStatsText>
              Time practiced{" "}
              <PackDetailsStatsTextBold>
                {formatTime(t)}
              </PackDetailsStatsTextBold>
            </PackDetailStatsText>

            <PackDetailStatsText>
              Practise sessions
              <PackDetailsStatsTextBold>
                {" "}
                {Math.floor((lp + cp) / 10)}
              </PackDetailsStatsTextBold>
            </PackDetailStatsText>
          </PackDetailsStatsSection>
        </PackDetailsStatsLevelContainer>
      );
    };

    // function to generate each level that exists
    const generateLevelStats = (pack, type) => {
      let levelType;

      if (type === "speaking") {
        levelType = pack.learningPointsLevels_S;
      }
      if (type === "listening") {
        levelType = pack.learningPointsLevels_L;
      }

      let arr = [];
      for (let i = 0; i < levelType.length; i++) {
        arr.push(createLevelStats(pack, i, type));
        arr.push(<Spacer key={"spacer_" + i} />);
      }
      return arr;
    };

    // return the stats
    if (pointsTotal) {
      return (
        <PackDetailsStatsContainer>
          <PackDetailsStatsSection>
            <PackDetailsStatsSplitContainer>
              <PackDetailsStatsSection justifyContent="center">
                <PackDetailPercentage>
                  {learningPercentage}%
                </PackDetailPercentage>
              </PackDetailsStatsSection>
              <PackDetailsStatsSection justifyContent="center">
                <PackDetailStatsText>Learning</PackDetailStatsText>
              </PackDetailsStatsSection>
            </PackDetailsStatsSplitContainer>
            <PackDetailsStatsSplitContainer>
              <PackDetailsStatsSection justifyContent="center">
                <PackDetailPercentage>
                  {consolidationPercentage}%
                </PackDetailPercentage>
              </PackDetailsStatsSection>
              <PackDetailsStatsSection justifyContent="center">
                <PackDetailStatsText>Consolidation</PackDetailStatsText>
              </PackDetailsStatsSection>
            </PackDetailsStatsSplitContainer>

            <Spacer height=".5rem" />

            <PackDetailsStatsSection justifyContent="center">
              <PackDetailsStatsSection justifyContent="center">
                <div style={{ height: "1rem", width: "100%" }}>
                  <CompletionBar
                    type={"stats"}
                    strokeWidth=".16rem"
                    background="dark"
                    learning={learningPercentage}
                    consolidating={learningPercentage}
                  />
                </div>
              </PackDetailsStatsSection>
              <PackDetailsStatsSection justifyContent="center">
                <PackDetailPercentage>
                  {formatTime(timeTotal)}
                </PackDetailPercentage>
              </PackDetailsStatsSection>
              <PackDetailsStatsSection justifyContent="center">
                <PackDetailStatsText>Total time practiced</PackDetailStatsText>
              </PackDetailsStatsSection>
            </PackDetailsStatsSection>

            <Spacer />

            <PackDetailsStatsSection>
              <PackDetailStatsText>
                Learning points
                <PackDetailsStatsTextBold> {lpTotal}</PackDetailsStatsTextBold>
              </PackDetailStatsText>

              <PackDetailStatsText>
                Consolidation points
                <PackDetailsStatsTextBold> {cpTotal}</PackDetailsStatsTextBold>
              </PackDetailStatsText>
              <PackDetailStatsText>
                Practice sessions
                <PackDetailsStatsTextBold>
                  {" "}
                  {Math.floor(pointsTotal / 10)}
                </PackDetailsStatsTextBold>
              </PackDetailStatsText>
            </PackDetailsStatsSection>

            <Spacer height="2rem" />

            {pack.learningPointsLevels_S
              ? generateLevelStats(pack, "speaking")
              : null}

            <Spacer height="2rem" />

            {pack.learningPointsLevels_L
              ? generateLevelStats(pack, "listening")
              : null}
          </PackDetailsStatsSection>
        </PackDetailsStatsContainer>
      );
    } else {
      return (
        <PackDetailsStatsSection>
          <Spacer />
          <PackDetailStatsTextCenter>
            Track your progress here! <br />
            Complete a practise session to see statistics for this pack.
          </PackDetailStatsTextCenter>
        </PackDetailsStatsSection>
      );
    }
  };

  // IIFE to set the packData on load
  (() => {
    let currentPack;

    for (let i = 0; i < activePacks.length; i++) {
      if (activePacks[i]._id === id) {
        currentPack = activePacks[i];
        break;
      }
    }

    if (packData._id) {
      if (packData._id !== id) {
        console.log(
          "// changing pack: " + packData._id + " >> " + currentPack._id
        );
        setPackData(currentPack);
        const speakingBtn = practiseButton("speaking", currentPack);
        const listeningBtn = practiseButton("listening", currentPack);
        setPractiseButtonContent([speakingBtn, listeningBtn]);
        setStatsContent(createStats(currentPack));
      } else {
        //console.log("...");
      }
    } else {
      console.log("// loading pack: " + currentPack._id);
      setPackData(currentPack);
      const speakingBtn = practiseButton("speaking", currentPack);
      const listeningBtn = practiseButton("listening", currentPack);
      setPractiseButtonContent([speakingBtn, listeningBtn]);
      setStatsContent(createStats(currentPack));
    }
  })();

  // IIFE starts practise
  (() => {
    if (startPractise) {
      console.log("starting practice session with id: " + progressId);

      setLockScreen(true);

      createPracticeSession(
        id,
        loadedPacks,
        currentPackProgress,
        history,
        startPractise,
        progressId,
        currentLanguagePair,
        packs,
        activePacks
      );
      setStartPractise(null);
    }
  })();

  return (
    <React.Fragment>
      {lockScreen ? <ScreenLock /> : null}

      <AnimatePresence>
        {packDetailsDisplay.display ? (
          <PackDetailPrompt
            variants={displayPromptVariants}
            initial="initial"
            animate="visible"
            exit="exit"
          >
            <CustomContainer width="100%" height="18rem">
              {/* <div style={{ position: "absolute", top: 0, left: 0 }}>
                <Img src={images.imagePlaceholder} width="100%" top="0px" />
              </div> */}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {/* <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                  }}
                > */}
                <Img
                  key="mainImage"
                  src={mainImage}
                  onLoad={handleImageLoaded}
                  width="100%"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 3 }}
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                />
                <GradientContainer />
                {/* </div> */}
              </div>
            </CustomContainer>
            <PackDetailsContentContainer>
              {/* dev button to show all items in pack ! */}
              {/* <button
              onClick={() =>
                displayPackItems(
                  id,
                  loadedPacks,
                  currentPackProgress,
                  progressId,
                  packs,
                  activePacks,
                  currentLanguagePair
                )
              }
            >
              Show items
            </button> */}

              <PackDetailTitle color="white">{name}</PackDetailTitle>
              <Spacer />

              {practiseButtonContent}
              <PackDetailBody>{description}</PackDetailBody>

              <Spacer height="3rem" />

              {/* <PracticeDetailLine /> */}
              <Img src={images.jaggedDivider} width="100%" />

              <Img src={images.statsTeam} width="100%" />

              <Spacer height=".5rem" />

              {statsContent}
            </PackDetailsContentContainer>
          </PackDetailPrompt>
        ) : null}
      </AnimatePresence>
    </React.Fragment>
  );
};

const mapStateToProps = ({ display, content, user }) => {
  return { display, content, user };
};

export default connect(mapStateToProps, {
  createPracticeSession,
  displayPackItems,
})(withRouter(PackDetails));
