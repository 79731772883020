export const SIGNIN_USER = "signin_user";
export const SIGNUP_USER = "signup_user";
export const SIGNOUT_USER = "signout_user";
export const FETCH_USER = "fetch_user";
export const GOOGLE_SIGNIN = "google_signin";
export const GET_PACKS = "get_packs";
export const SET_LANGUAGE_PAIR = "set_language_pair";
export const DISPLAY_PACK_MANAGER = "display_pack_manager";
export const DISPLAY_SETTINGS = "display_settings";
export const DISPLAY_PACK_DETAILS = "display_pack_details";
export const HIDE_PACK_DETAILS = "hide_pack_details";
export const UPDATE_PACKS = "update_packs";
export const GET_ITEMS = "get_items";
export const CLEAR_ITEMS = "clear_items";
export const GET_PACK_PROGRESS = "get_pack_progress";
export const CREATE_PRACTISE_SESSION = "create_practise_session";
export const UPDATE_USER_STATS = "update_user_stats";
export const DISPLAY_CHANGE_LANGUAGE = "display_change_language";
export const ADD_LEVELS = "add_levels";
export const LEVEL_UP = "level_up";
export const PACK_COMPLETED = "pack_completed";
